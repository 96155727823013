import * as React from 'react'
import { Card, Breadcrumb,Row, Col, Button, Collapse } from 'react-bootstrap';
import IESRegistradoraDados from './../../Publica/IESRegistradoraDados'

class RegistroNovoIESRegistradora extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: '',
        }
    }
    render() {
        // if (this.props.currentStep !== 4) {
        //     return null
        // }
        return (
            <>
                <Button
                onClick={() => this.setState({ open: (this.state.open == 'open') ? '' : 'open' })}
                aria-controls="example-fade-text"
                aria-expanded={this.state.open}
                >
                Ver Dados IES Registradora
                </Button>
                <div>
                <Collapse in={this.state.open} dimension="width">
                    <div id="example-collapse-text">         
                        <IESRegistradoraDados
                            ies={this.props.ies}
                            endereco={this.props.ies.endereco}
                            credenciamento={this.props.ies.credenciamento}
                            recredenciamento={this.props.ies.recredenciamento}
                            mantenedora={this.props.ies.mantenedora}
                            enderecoMantenedora={this.props.ies.mantenedora.endereco}
                            mostrarReduzido={false}
                        />
                    </div>
                </Collapse>
                </div>
                {/* 
            <Card>
                <Card.Header>
                    <h3>{this.props.currentStep} - Dados IES Registradora</h3>
                </Card.Header>

                <IESRegistradoraDados
                    ies={this.props.ies}
                    endereco={this.props.ies.endereco}
                    credenciamento={this.props.ies.credenciamento}
                    recredenciamento={this.props.ies.recredenciamento}
                    mantenedora={this.props.ies.mantenedora}
                    enderecoMantenedora={this.props.ies.mantenedora.endereco}
                    mostrarReduzido={false}
                />
            </Card> */}
            </>
        )
    }
}

export default RegistroNovoIESRegistradora

export default function (str) {
    if (str == undefined || str == null) {
        return str;
    }

    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
        if (
            splitStr[i].length >= 3 &&
            splitStr[i] !== 'dos' &&
            splitStr[i] !== 'das'
        ) {
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
    }

    return splitStr.join(' ')
}

import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {Col, Form, Card, Button, Row} from 'react-bootstrap'
import { loadCampus, loadAnos, loadDatasColacao } from './../../../store/thunks'
import apiURLs from './../../../api/apiURLs'
import apiAxios from './../../../api/apiAxios'
import Swal from 'sweetalert2'

const OptionSelectDatasColacao = (props) => {
    switch (props.option.sigaTipoColacao) {
        case 'E':
            return (
                <option key={props.option.sigaIdDataColacao} value={props.option.sigaIdDataColacao}>
                    {props.option.sigaDataColacao} - Especial
                </option>
            )
        case 'P':
            return (
                <option key={props.option.sigaIdDataColacao} value={props.option.sigaIdDataColacao}>
                    {props.option.sigaDataColacao} - Extemporânea
                </option>
            )
        default:
            return (<option key={props.option.sigaIdDataColacao} value={props.option.sigaIdDataColacao}>{props.option.sigaDataColacao}</option>)
    }
}

class ConsultaFormandosFiltroForm extends React.Component {
    static displayName = ConsultaFormandosFiltroForm.name
    constructor(props) {
        super(props)
        this.state = {
            ano: !this.props.anoURL
                ? new Date().getFullYear()
                : this.props.anoURL,
            campusId: !this.props.campusIdURL ? 3 : this.props.campusIdURL,
            dataColacaoId: !this.props.dataColacaoIdURL
                ? 0
                : this.props.dataColacaoIdURL,
            campus: [],
            datasColacao: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.setFirstDataColacaoId = this.setFirstDataColacaoId.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(loadAnos())
        this.setCampus()
    }
    setCampus() {
        const url = apiURLs.GET_SIGA_TODOS_CAMPUS
        apiAxios.get(url)
            .then((result) => {
                this.setState({ campus: result.data })
                this.setDatasColacao(!this.props.campusIdURL ? result.data[0].campusID : this.props.campusIdURL, !this.props.anoURL ? this.props.anos[0] : this.props.anoURL)
            })
            .catch((ex) => { console.log(ex) })
    }

    setDatasColacao(campus, ano) {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_SIGA_DATAS_COLACAO + campus + '/' + ano
        apiAxios.get(url)
            .then((result) => {
                const datas = (result.data.length > 0) ? (result.data) : [{
                    sigaIdDataColacao: 0,
                    sigaDataColacao: "Nenhuma data encontrada"
                }];
                this.setState({
                    datasColacao: datas
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                this.props.history.push('/')
            })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({ [name]: value })

        if (name === 'campusId') {
            this.setDatasColacao(value, this.state.ano)
            //this.props.dispatch(loadDatasColacao(value, this.state.ano))
            this.setFirstDataColacaoId() //TODO: colocar await para nao ir para o prox antes de pegar
        }
        if (name === 'ano') {
            this.setDatasColacao(this.state.campusId, value)
            //this.props.dispatch(loadDatasColacao(this.state.campusId, value))
            this.setFirstDataColacaoId()
        }
    }

    setFirstDataColacaoId() {
        this.setState({ dataColacaoId: document.getElementById('dataColacaoId').value })
    }

    handleSubmit(event) {
        if (this.state.dataColacaoId !== 0) {
            this.props.history.push(`/consulta-formandos/:${this.state.dataColacaoId}`)
        }
        event.preventDefault()
    }

    render() {
        const { campus, datasColacao } = this.state
        const { anos } = this.props
        return (
            <Card className="filterArea my-3">
                <Card.Body>
                    <Form
                        id="filterForm"
                        name="filterForm"
                        onSubmit={this.handleSubmit}>
                        <Row className="align-items-center">
                            <div className="col-auto">
                                <Form.Label>Campus</Form.Label>
                                <Form.Control
                                    as="select" className="form-select"
                                    id="campusId"
                                    name="campusId"
                                    onChange={this.handleInputChange}
                                    value={this.state.campusId}>
                                    {campus.map((option, index) => (<option key={option.campusID} value={option.campusID}>{option.nome}</option>))}
                                </Form.Control>
                            </div>
                            <div className="col-auto">
                                <Form.Label>Ano</Form.Label>
                                <Form.Control
                                    as="select" className="form-select"
                                    id="ano"
                                    name="ano"
                                    onChange={this.handleInputChange}
                                    value={this.state.ano}>
                                    {anos.map((item) => (<option key={item} value={item}>{item}</option>))}
                                </Form.Control>
                            </div>
                            <div className="col-auto">
                                <Form.Label>Data da Colação</Form.Label>
                                <Form.Control
                                    as="select" className="form-select"
                                    id="dataColacaoId"
                                    name="dataColacaoId"
                                    onChange={this.handleInputChange}
                                    value={this.state.dataColacaoId}>
                                    {datasColacao.map((option, index) => (
                                        <OptionSelectDatasColacao
                                            key={index}
                                        option={option}
                                        />
                                    ))}
                                </Form.Control>
                            </div>

                            <Col lg={3}>
                                <Form.Group className="btn-filtro-consulta-formandos">
                                    <Button type="submit" className="btn btn-primary">
                                        <i className="fas fa-search"></i>{' '}Consultar
                                    </Button>{' '}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>                            
                                <Link className="btn btn-outline-primary mt-3" size="sm" to="/consulta-individual-formandos">
                                    Consulta Individual de Formandos
                                </Link>{' '}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    estudantes: state.registros.estudantes,
    datasColacao: state.registros.datasColacao,
    campus: state.registros.campus,
    anos: state.registros.anos,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(ConsultaFormandosFiltroForm)
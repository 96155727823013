import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Form, Button, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addNovaDataPublicacaoEmLoteRequest } from '../../../store/thunks'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

const GravarPublicacaoEmLoteForm = ({
    registrosSelecionados,
    onCreatePressed,
}) => {
    const history = useHistory()
    const [inputValue, setInputValue] = React.useState('')

    const handleRedirect = () => {
        history.push('/registros')
    }
    return (
        <Form name="novaDataPublicacaoEmLoteForm">
            <Card>
                <Card.Body>
                    <Card.Title> Registros Selecionados </Card.Title>
            {registrosSelecionados.map((registro, index) => (
                <Card key={registro.registroID}>
                    <Card.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridNome">
                                <Form.Label>Registro: </Form.Label>{' '}{registro.registroID}       
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridNome">
                                <Form.Label>RGA: </Form.Label>{' '}{registro.id}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridNome">
                                <Form.Label>Data de Publicação atual: </Form.Label>{' '}{registro.registroDOUData}
                            </Form.Group>
                        </Form.Row>                        
                    </Card.Body>
                </Card>
            ))}                     
            </Card.Body>
        </Card>
           
            <Form.Row>
                <Form.Group as={Col} sm={12} md={6} lg={2}>
                    <Form.Label>Nova data de Publicação</Form.Label>
                    <input
                        type="date"
                        title="data"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="form-control"
                        required
                    />
                </Form.Group>
            </Form.Row>
            <Link className="btn btn-primary" to="/registros">
                Voltar
            </Link>{' '}
            <Button
                variant="success"
                type="button"
                onClick={() => {
                    if (inputValue) {
                        onCreatePressed(inputValue, registrosSelecionados)
                        setInputValue('')
                        handleRedirect()
                    }else{                          
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao salvar data de publicação em lote!',
                            html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
                        })
                    }
                }}
            >
                Publicar
            </Button>
        </Form>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    onCreatePressed: (data, registrosSelecionados) =>
        dispatch(addNovaDataPublicacaoEmLoteRequest(data, registrosSelecionados)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GravarPublicacaoEmLoteForm)

import * as React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaAtosReconhecimentos from '../../components/Area/CAE/TabelaAtosReconhecimentos'
import { loadAtosRegulatoriosComVinculosCursosReconhecimentos, loadReconhecimentos } from './../../store/thunks'
import { IrPortal } from "../../util/funcoes";

class ReconhecimentoConsulta extends React.Component {
    static displayName = ReconhecimentoConsulta.name
    constructor(props) {
        super(props)
        this.state = { reconhecimentos: [] }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        this.props.dispatch(loadAtosRegulatoriosComVinculosCursosReconhecimentos())
    }

    render() {
        const { reconhecimentos } = this.props
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./cursos">
                                Gerenciar Cursos
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Consulta de Atos Regulatórios de Reconhecimento de Curso(s)
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Link
                            to="/reconhecimentos/novo"
                            className="btn btn-primary">
                            <i className="fas fa-plus-circle"></i> Novo
                        </Link>

                        <br></br>
                        { <TabelaAtosReconhecimentos
                            reconhecimentos={reconhecimentos}
                        /> }
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reconhecimentos: state.reconhecimentos.reconhecimentos,
    loading: state.reconhecimentos.loading,
    error: state.reconhecimentos.error,
})

export default connect(mapStateToProps)(ReconhecimentoConsulta)

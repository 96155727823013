import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaDiplomaListaAnulados from './../../components/Area/CAE/TabelaDiplomaListaAnulados'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadLivros, removeLivroRequest } from './../../store/thunks'
import { IrPortal } from "../../util/funcoes";
import Swal from 'sweetalert2'
import apiURLs from '../../api/apiURLs'
import apiAxios from "../../api/apiAxios";
import { Button } from 'react-bootstrap'
import axios from "axios";

class DiplomaListaAnulados extends React.Component {
    static displayName = DiplomaListaAnulados.name
    constructor(props) {
        super(props)
        this.state = { diplomasAnulados: [], listaAtualDiplomasAnulados:{} }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        this.getListas();
    }

    getListas(){
        
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando listas de diplomas anulados...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_LISTAS_DIPLOMAS_ANULADOS
        //const url = apiURLs.GET_LISTA_DIPLOMAS_ANULADOS
        apiAxios
            .get(url)
            .then((result) => {
                console.log(result.data);
                // var diplomasAnulados = new Array();
                // diplomasAnulados.push(result.data);
                this.setState({
                     diplomasAnulados: result.data,
                    // listaAtualDiplomasAnulados: result.data,
                    // diplomasAnulados: diplomasAnulados
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }
    
    gerarNovaLista() {
        
        Swal.fire({
            title: 'Deseja gerar nova lista de diplomas anulados?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    showCancelButton: false,
                    showConfirmButton: false,
                    title: 'Por favor aguarde!',
                    html: 'Gerando nova lista de diplomas anulados...',
                    allowOutsideClick: false,
                    willOpen: () => {
                        Swal.showLoading()
                    },
                })
                const url = apiURLs.GERAR_LISTA_DIPLOMAS_ANULADOS
                //const url = apiURLs.GET_LISTA_DIPLOMAS_ANULADOS
                apiAxios
                    .get(url)
                    .then((result) => {
                        
                        Swal.close()

                        Swal.fire({
                            icon: 'success',
                            title: 'Nova lista de diplomas anulados gerada com sucesso!',
                            showConfirmButton: false,
                            timer: 4000,
                        }).then(this.getListas())
                        
                    })
                    .catch((ex) => {
                        console.log(ex)
                        Swal.close()
                        // Swal.fire({
                        //     icon: 'error',
                        //     title: 'Erro ao processar ação!',
                        //     html:
                        //         'Tente novamente. ' +
                        //         'Se erro persistir entre em contato com o administrador do sistema.',
                        // })
                    })
                } else if (result.isDenied) {
                    //Swal.fire('Changes are not saved', '', 'info')
                }
            })
    }

    onDownloadXMLPressed = (id, numeroDeSequencia) => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados da lista...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        try {
            
            //const url = apiURLs.GET_XML_LISTA_DIPLOMAS_ANULADOS
            const url = apiURLs.GET_XML_LISTA_DIPLOMAS_ANULADOS_POR_ID + id;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                Swal.close();
                console.log(response)
                let fileNameServidor;
                try {
                    fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
                } catch (ex) {
                    console.log(ex);
                    fileNameServidor = undefined;
                }
                const fileName = `ListaDiplomasAnulados${numeroDeSequencia}.xml`;
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            }).catch(ex => {
                Swal.close();
                Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
            });
        } catch (ex) {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        }
    }

    render() {
       // const { diplomasAnulados } = this.props //error, loading,
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="/digital/cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Consulta de Listas de Diplomas Anulados
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Button
                            variant="primary"
                            onClick={() => this.gerarNovaLista()}
                        >
                            <i className="fas fa-plus-circle"></i> Gerar Nova Lista de Diplomas Anulados
                        </Button>

                        <br></br>
                        <TabelaDiplomaListaAnulados
                            diplomasAnulados={this.state.diplomasAnulados}
                            onDownloadXMLPressed={this.onDownloadXMLPressed}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.livros.loading,
    error: state.livros.error,
})

export default connect(mapStateToProps)(DiplomaListaAnulados)

import * as React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import * as api from './../../../api/api'
import Swal from 'sweetalert2'

export class LocalizarDiploma extends React.Component {
    constructor(props) {
        super(props)
        this.state = { codigoValidacao: null }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.recaptchaRef = React.createRef()
    }
    componentDidMount() {
        const { codigoValidacao } = this.props.match.params
        this.setState({ codigoValidacao: codigoValidacao })
    }

    handleSubmit(event) {
        const recaptchaValue = this.recaptchaRef.current.getValue()
        let isCaptchaValid = false

        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Validando o captcha...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        api.checkRecaptcha(recaptchaValue)
            .then((result) => {
                isCaptchaValid = result;//result.success
                if (isCaptchaValid) {
                    localStorage.setItem("isCaptchaValid", isCaptchaValid);

                   /* this.props.history.push(
                        `/ConsultarDiploma/${this.state.codigoValidacao}`
                    )*/
                    
                    Swal.close()
                    Swal.fire({
                        icon: 'success',
                        title:
                            'Validado com sucesso! Carregando dados do diplomado...',
                        showConfirmButton: false,
                        timer: 2000,
                    }).then(this.RedirectToConsultarDiploma())
                }
            })
            .catch((ex) =>
                console.log(`O seguinte erro foi gerado: ` + ex, 'error')
            ) //Swal.fire('Oops', )

        event.preventDefault()
    }

    RedirectToConsultarDiploma = () => {
        window.location.href = `/digital/ConsultarDiploma/${this.state.codigoValidacao}`
    }
    
    RedirectToConsultaPublica = () => {
        window.location.href = `/digital/ConsultaPublica`
    }

    render() {
        return (
            <Card className="text-center">
                <Card.Body>
                    <Card.Title>
                        <h2>
                            Localizar Diploma - {this.state.codigoValidacao}
                        </h2>
                    </Card.Title>
                    <form
                        id="captchaForm"
                        name="captchaForm"
                        onSubmit={this.handleSubmit}
                    >
                        <Row>
                            <Col>
                                Valide o CAPTCHA abaixo para prosseguir com a
                                visualização do diploma. <br />
                                Código de Validação do diploma buscado:{' '}
                                {this.state.codigoValidacao}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg={12} className="text-center">
                                <ReCAPTCHA
                                    className="g-recaptcha"
                                    ref={this.recaptchaRef}
                                    sitekey="6LcRklQfAAAAAJq7nRYC4qoUDPSdrjXKkUaOF6yZ"
                                    //sitekey="6LffeVQfAAAAAGXLnOqy6BcK3W-6ldBTT_i3CuZh"
                                    //sitekey="6LfML6gZAAAAADpgZVMjw72Z292zAK30P7BPTukm"
                                />
                            </Col>
                        </Row><br></br>
                        <Row>
                            <Col>
                                <button type="submit" className="btn btn-success">
                                    Continuar
                                </button>
                            </Col>
                        </Row><br></br>
                        <Row>
                            <Col>
                                <Link className="btn btn-info" to="/ConsultaPublica">
                                Ir para Consulta Pública de Registro de Diplomas
                                </Link>{' '}
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        )
    }
}

export default LocalizarDiploma

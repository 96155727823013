import * as React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Curso ou Código"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

export const BasicTable = ({ cursos = [] }) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = cursos.filter((item) =>
        item.cursoMec.nome.toLowerCase().includes(filterText.toLowerCase()) ||
        item.codigoInterno == filterText.trim()
    )

    const handleButtonClick = (cursoID) => {
        window.location.href = '/digital/gerenciar-curso/' + cursoID
    }

    const columns = [
        {
            name: 'Código',
            selector: 'codigoInterno',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Curso',
            selector: 'cursoMec.nome',
            sortable: true,
        },
        {
            name: 'Grau',
            selector: 'cursoMec.grau.descricao',
            sortable: true,
        },
        {
            name: 'Campus',
            selector: 'cursoMec.campus.sigla',
            sortable: true,
            maxWidth: '10%',
        },
        /* {
            name: 'Oferta',
            selector: 'cursoMec.oferta.nome',
            sortable: true,
        }, 
        {
            name: 'Turno',
            selector: 'turno.nome',
            sortable: true,
        },
        {
            name: 'Periodicidade',
            selector: 'periodicidade.nome',
            sortable: true,
        },
        {
            name: 'Regime',
            selector: 'regime.nome',
            sortable: true,
        },*/
        {
            cell: (row) => (
                <Button
                    variant="info"
                    onClick={() => handleButtonClick(row.cursoID)}
                >
                    <i className="fas fa-eye"></i>
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Cursos"
            columns={columns}
            keyField="text"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhum curso encontrado."
            paginationComponentOptions={{
                rowsPerPageText: 'Cursos por página:',
            }}
            //onRowClicked={handleButtonClick}
            persistTableHead
            Clicked
        />
    )
}

export default BasicTable

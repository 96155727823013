import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import GravarPublicacaoForm from '../../components/Area/CAE/GravarPublicacaoForm'
import { loadRegistro } from './../../store/thunks'
import { connect } from 'react-redux'
import { IrPortal } from "../../util/funcoes";

class GravarPublicacao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registroId: this.props.match.params.registroId,
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }
    componentDidMount() {
        this.props.dispatch(loadRegistro(this.state.registroId))
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <Breadcrumb.Item href="./cae">
                        Página Inicial - CAE
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="./registros">
                        Consulta de Registros
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Gravar Data de Publicação
                    </Breadcrumb.Item>
                </Breadcrumb>

                <h2>Data de Publicação de Registro</h2>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <small>Informe a data de publicação no DOU.</small>
                        </Card.Title>
                        <GravarPublicacaoForm
                            registroDOUData={
                                this.props.registro.registroDOUData
                            }
                            registroId={this.state.registroId}
                        />
                    </Card.Body>
                </Card>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(GravarPublicacao)

import * as React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import apiAxios from "./../../../api/apiAxios";
import apiURLs from './../../../api/apiURLs'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Curso"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

export const BasicTable = ({ reconhecimentosCursos = [] }) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = reconhecimentosCursos;    //.filter((item) =>item.nome.toLowerCase().includes(filterText.toLowerCase()))
  
    const columns = [
        {
            name: 'Código Interno',
            selector: 'curso.codigoInterno',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Curso',
            selector: 'curso.cursoMec.nome',
            sortable: true,
        },
        {
            name: 'Grau',
            selector: 'curso.cursoMec.grau.descricao',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Periodicidade',
            selector: 'curso.periodicidade.nome',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Regime',
            selector: 'curso.regime.nome',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Turno',
            selector: 'curso.turno.nome',
            sortable: true,
            maxWidth: '15%',
        },
        {
            name: 'Processo Reconhecimento',
            selector: 'processoReconhecimento',
            sortable: true,
            maxWidth: '15%',
        }
    ]
    
    return (
        <DataTable
            title="Cursos Vinculados"
            columns={columns}
            keyField="text"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            noDataComponent="Nenhum curso vinculado."
            paginationComponentOptions={{
                rowsPerPageText: 'Cursos vinculados por página:',
            }}
            persistTableHead
            Clicked
            className="titulacao-table-head"
        />
    )
}

export default BasicTable

import * as React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import apiAxios from "./../../../api/apiAxios";
import apiURLs from './../../../api/apiURLs'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Curso"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

export const BasicTable = ({ reconhecimentos = [] }) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = reconhecimentos;    //.filter((item) =>item.nome.toLowerCase().includes(filterText.toLowerCase()))
  
    const columns = [
        {
            name: 'Tipo',
            selector: 'atoRegulatorio.tipo',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Número',
            selector: 'atoRegulatorio.numero',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Data',
            cell:(props) =>  (props.atoRegulatorio.data != null && props.atoRegulatorio.data != undefined) ?  <FormatDate
                data={props.atoRegulatorio.data}
            /> : '',
            sortable: true,
            maxWidth: '20%',
        },
        {
            name: 'Veiculo de Publicação',
            selector:  'atoRegulatorio.veiculoPublicacao',
            sortable: true,
            maxWidth: '15%',
        },
        {
            name: 'Seção',
            selector: 'atoRegulatorio.secaoPublicacao',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Página',
            selector: 'atoRegulatorio.paginaPublicacao',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Data de Publicação',
            cell:(props) =>  (props.atoRegulatorio.dataPublicacao != null && props.atoRegulatorio.dataPublicacao != undefined) ?  <FormatDate
                data={props.atoRegulatorio.dataPublicacao}
            /> : '',
            sortable: true,
            maxWidth: '15%',
        },
        {
            name: 'Número Publicação',
            selector: 'atoRegulatorio.numeroPublicacao',
            sortable: true,
            maxWidth: '15%',
        }
    ]
    function FormatDate(props) {
        if (props.data != null) {
            let dateTimeArray = props.data.split('T')
            let dataArray = dateTimeArray[0].split('-')
            console.log(dataArray)
            return (
                <span>
                    {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
                </span>
            )
        } else {
            return <span></span>
        }
    }
    return (
        <DataTable
            title="Reconhecimentos"
            columns={columns}
            keyField="text"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            noDataComponent="Nenhum reconhecimento encontrado."
            paginationComponentOptions={{
                rowsPerPageText: 'Reconhecimentos por página:',
            }}
            persistTableHead
            Clicked
            className="titulacao-table-head"
        />
    )
}

export default BasicTable

import React from 'react';

export default class RegistroLocalizarAntigo extends React.Component {
    constructor(props) {
        super(props);
        const currentURL = window.location.href;
        const urlParameters = currentURL.split("=")
        const localizador = urlParameters[1];

        window.location.href = `/antigo/Registro/Localizar?localizador=${localizador}`;
    }
}
import React from "react";
import styled from "styled-components";

const Stpes = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap
`

const Step = styled.div`
    & {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 1rem
    }
    @media (min-width:768px) {
        &:not(:last-child):after {
            content: "";
            display: block;
            border-top: .25rem dotted #3b82f6;
            width: 3.46rem;
            position: absolute;
            left: 7.5rem;
            top: .3125rem
        }
        &:not(:first-child):before {
            content: "";
            display: block;
            border-top: .25rem dotted #3b82f6;
            width: 3.8125rem;
            position: absolute;
            right: 7.5rem;
            top: .3125rem
        }
    }
`

const StepContent = styled.div`
    width: 10rem;
    text-align: center
`

const InnerCircle = styled.div`
    & {
        border-radius: 1.5rem;
        height: 1rem;
        width: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #3b82f6;
    }
    &:before {
        content: "";
        background-color: #3b82f6;
        display: inline-block;
        height: 3rem;
        width: 3rem;
        min-width: 3rem;
        border-radius: 6.25rem;
        opacity: .5
    }
`

const TimeLine = (props) => {
    return (
        <div className="row">
            <div className="col">
                <Stpes>
                    {props.itens != null && props.itens.length > 0 && props.itens.map((i, index) =>
                        <Step key={index}>
                            <StepContent>
                                <InnerCircle />
                                <p className="h6 mt-3 mb-1">{i.texto}</p>
                            </StepContent>
                        </Step>)}
                </Stpes>
            </div> 
        </div>)
}

export default TimeLine;
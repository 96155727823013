import * as React from 'react'
import { Row, Col,ListGroup } from 'react-bootstrap'

export class EnderecoDados extends React.Component {
    render() {
        return (
            <>
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                <div className="fw-bold"><h5>{this.props.tipo}</h5></div>
                    <div className='fonte-cinza'>
                        {this.props.dados.logradouro}, {this.props.dados.numero}
                        . {this.props.dados.complemento}
                    </div>
                </div>
            </ListGroup.Item>
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                    <div className='fonte-cinza'>
                        {this.props.dados.bairro}
                    </div>
                </div>
            </ListGroup.Item>
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                    <div className='fonte-cinza'>
                        {this.props.dados?.codigoMunicipio ?? this.props.dados?.municipio?.codigoMunicipio ?? ""} - {' '}
                        {this.props.dados?.nomeMunicipio ?? this.props.dados?.municipio?.nomeMunicipio ?? "" } /
                        {this.props.dados?.uf ??  this.props.dados?.municipio?.uf ?? "" }
                    </div>
                </div>
            </ListGroup.Item>
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                    <div className='fonte-cinza'>
                        CEP: {this.props.dados.cep}
                    </div>
                </div>
            </ListGroup.Item>
            </>
        )
    }
}

export default EnderecoDados

import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import AtoRegulatorioForm from '../CAE/AtoRegulatorioForm'
import apiAxios from './../../api/apiAxios';
import apiURLs from './../../api/apiURLs';
import { IrPortal } from "../../util/funcoes";

export default class AutorizacaoCursoNovo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            autorizacoes: [],
            tiposAtoRegulatorio: [],
            cursos: [],
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        this.setTiposAtoRegulatorio()
        this.setCursos()
    }

    setTiposAtoRegulatorio() {
        //const url = 'https://localhost:5001/api/atoregulatorio/tipos'; 
        const url = apiURLs.GET_ATO_REGULATORIO_TIPOS;
        apiAxios
        .get(url) 
        .then((result) => {
            console.log('tipos')
            console.log(result)
            this.setState({ tiposAtoRegulatorio: result.data })
        })
        .catch((ex) => {
            console.log(ex)
        }) 
    }

    setCursos() {
        const url = apiURLs.GET_CURSOS_ATIVOS
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ cursos: result.data })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./autorizacaoCurso">
                                Consulta de Atos Regulatórios de Autorização de Curso(s)
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Novo Ato Regulatório de Autorização de Curso(s)
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Novo Ato Regulatório de Autorização de Curso(s)</h2>
                        </Card.Title>
                        <AtoRegulatorioForm 
                            tiposAtoRegulatorio={this.state.tiposAtoRegulatorio}
                            cursos={this.state.cursos}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeDate={this.handleInputChangeDate}
                            /> 
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

import * as React from 'react'
import { Card, Breadcrumb, Alert } from 'react-bootstrap'
import apiAxios from './../../api/apiAxios';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import TabelaAutorizacaoCursosVisualizacao from './../../components/Area/CAE/TabelaAutorizacaoCursosVisualizacao'
import AtoRegulatorioDetalhe from './../../components/Area/CAE/AtoRegulatorioDetalhe'
import apiURLs from './../../api/apiURLs';
import { IrPortal } from "../../util/funcoes";

export default class AutorizacaoCursoVerDetalhe extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            atoRegulatorio: {} ,
            AutorizacaoCursos: [],
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        if(this.props.match.params.id != undefined){
            this.setAtoRegulatorio(); 
            this.setAutorizacaoCursos();
        }
    }
    
    setAtoRegulatorio() {
        //const url = `https://localhost:5001/api/autorizacao/ato-regulatorio/dados/${this.props.match.params.id}`;        
       const url = apiURLs.GET_ATO_REGULATORIO + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ 
                    atoRegulatorio: result.data
                })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }    
    setAutorizacaoCursos() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando cursos vinculados...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })

        //const url = `https://localhost:5001/api/autorizacao/cursos-ato-regulatorio/${this.props.match.params.id}`;
        const url = apiURLs.GET_CURSO_VINCULADOS_AUTORIZACAO_CURSOS + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    AutorizacaoCursos: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            }) 
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./autorizacaoCurso">
                                Consulta de Atos Regulatórios de Autorização de Curso(s)
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Detalhes de Ato Regulatório de Autorização de Curso(s)
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
            <Card>
                <Card.Body>
                    <Card.Title>
                        <h2>Detalhes de Ato Regulatório de Autorização de Curso(s)</h2>
                    </Card.Title>
                    <br></br>
                    {(this.state.atoRegulatorio.cancelado) ? <Alert variant="danger">
                    Ato Regulatório de Autorização de Curso(s) Cancelado
  </Alert> : <></>} 
                <AtoRegulatorioDetalhe
                            atoRegulatorio={this.state.atoRegulatorio}
                        />

<Card>
                                    <Card.Body>
                                        <TabelaAutorizacaoCursosVisualizacao
                                            autorizacaoCursos={this.state.AutorizacaoCursos}
                                        />
                                    </Card.Body>
                                </Card>

                        <br></br>
                        <Link className="btn btn-primary" to="/autorizacaoCurso">
                            Voltar
                        </Link>{' '}
                        </Card.Body>
                    </Card>
            </div>
        )
    }
}

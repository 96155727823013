import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaCurso from '../../components/Area/CAE/TabelaCurso'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import apiURLs from '../../api/apiURLs'
import apiAxios from "../../api/apiAxios";
import { IrPortal } from "../../util/funcoes";
import { Link } from 'react-router-dom'

class CursoConsulta extends React.Component {
    static displayName = CursoConsulta.name
    constructor(props) {
        super(props)
        this.state = { cursos: [] }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando cursos...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_CURSOS_ATIVOS
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    cursos: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Gerenciar Cursos
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        
                    <Link
                        to="/reconhecimentos"
                        className="btn btn-primary">
                        <i className="fas fa-file-signature"></i>
                         Reconhecimentos
                    </Link>{" "}
                    <Link
                        to="/autorizacaoCurso"
                        className="btn btn-primary">
                        <i className="fas fa-file-signature"></i> Autorizações
                    </Link>

                        <TabelaCurso cursos={this.state.cursos} />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default connect((state) => ({}))(CursoConsulta)
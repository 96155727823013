import * as React from 'react'
import { Col, Form, Card, Button } from 'react-bootstrap'
import RegistroNovoCamposIESEmissora from './RegistroNovoCamposIESEmissora'
import RegistroNovoCamposIESRegistradora from './RegistroNovoCamposIESRegistradora'

class RegistroNovoCamposAdicionais extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        if (this.props.currentStep !== 7) {
            return null
        }
        return (
            <Card>
                <Card.Header>
                    <h3>{this.props.currentStep} - Informações Adicionais</h3>
                </Card.Header>
                <Card.Body>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={12} lg={12} className="mb-3">
                            <Form.Label>Observação:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                title="Observação"
                                name="Observacao"
                                id="Observacao"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${
                                    this.props.touched.Observacao &&
                                    this.props.errors.Observacao
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                value={this.props.Observacao}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={12} lg={12}>                        
                            <RegistroNovoCamposIESEmissora
                                ies={this.props.iesEmissora}
                                currentStep={this.props.currentStep}
                            /><br></br>
                            <RegistroNovoCamposIESRegistradora
                                ies={this.props.iesRegistradora}
                                currentStep={this.props.currentStep}
                            />
                        </Form.Group>
                    </Form.Row>
                </Card.Body>
            </Card>
        )
    }
}

export default RegistroNovoCamposAdicionais
import ActionTypes from './actionTypes'

const initialState = {
    autorizacoes: [],
    loading: false,
    error: null,
}

export default function autorizacaoReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {       
        case ActionTypes.LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_SUCCESS: {
            return {
                ...state,
                loading: false,
                autorizacoes: payload.autorizacoes,
            }
        }
        case ActionTypes.LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                autorizacoes: [],
            }
        }
        default:
            return state
    }
}

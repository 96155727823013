import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import LivroRegistroForm from '../../components/Area/CAE/LivroRegistroForm'
import { IrPortal } from "../../util/funcoes";

export default class LivroRegistroConsulta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            livros: [],
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }
    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./livros">
                                Consulta de Livros de Registro
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Novo Livro de Registro
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Novo Livro de Registro</h2>
                        </Card.Title>
                        <LivroRegistroForm />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

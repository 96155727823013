import React, { Component } from 'react';
import Layout from '@ufmt/layout.padrao.react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ConsultaPublica from './components/Area/Publica/ConsultaPublica'
import RegistroPublicoDetalhe from './pages/Publico/RegistroPublicoDetalhe'
import RegistroPublicoAnuladoDetalhe from './components/Area/Publica/RegistroPublicoAnuladoDetalhe'
import LocalizarDiploma from './components/Area/Publica/LocalizarDiploma'
import ConsultarDiploma from './components/Area/Publica/ConsultarDiploma'
import Home from './pages/CAE/Home'
import LivroRegistroConsulta from './pages/CAE/LivroRegistroConsulta'
import LivroRegistroNovo from './pages/CAE/LivroRegistroNovo'
import LivroRegistroExclui from './pages/CAE/LivroRegistroExclui'
import ReconhecimentoConsulta from './pages/CAE/ReconhecimentoConsulta'
import ReconhecimentoNovo from './pages/CAE/ReconhecimentoNovo'
import ReconhecimentoEdita from './pages/CAE/ReconhecimentoEdita'
import ReconhecimentoExclui from './pages/CAE/ReconhecimentoExclui'
import ReconhecimentoVinculaCursos from './pages/CAE/ReconhecimentoVinculaCursos'
import ReconhecimentoVerDetalhe from './pages/CAE/ReconhecimentoVerDetalhe'
import RegistroConsulta from './pages/CAE/RegistroConsulta'
import GravarPublicacao from './pages/CAE/GravarPublicacao'
import CancelarRegistro from './pages/CAE/CancelarRegistro'
import ReativarRegistro from './pages/CAE/ReativarRegistro'
import RevogaCancelamentoRegistro from './pages/CAE/RevogaCancelamentoRegistro'
import ConsultarFormandos from './pages/CAE/ConsultarFormandos'
import ConsultaIndividualFormandos from './pages/CAE/ConsultaIndividualFormandos'
import CursoConsulta from './pages/CAE/CursoConsulta'
import CursoFormacaoConsulta from './pages/CAE/CursoFormacaoConsulta'
import RegistroConsultarAntigo from './pages/RegistroConsultarAntigo'
import RegistroLocalizarAntigo from './pages/RegistroLocalizarAntigo'
import GerenciarCurso from './pages/CAE/GerenciarCurso'
import RegistroNovo from './pages/CAE/RegistroNovo'
import './custom.css'
import history from './store/history';
import CadastrarDocumento from "./pages/Documento/CadastrarDocumento";
import EnviarConector from "./pages/Conector/EnviarConector";
import DetalheConector from "./pages/Conector/DetalheConector";
import ValidarDiploma from "./pages/Conector/ValidarDiploma";
import AutorizacaoCursoNovo from './pages/CAE/AutorizacaoCursoNovo';
import AutorizacaoCursoConsulta from './pages/CAE/AutorizacaoCursoConsulta';
import AutorizacaoCursoEdita from './pages/CAE/AutorizacaoCursoEdita';
import AutorizacaoCursoVerDetalhe from './pages/CAE/AutorizacaoCursoVerDetalhe';
import AutorizacaoCursoExclui from './pages/CAE/AutorizacaoCursoExclui';
import AutorizacaoVinculaCursos from './pages/CAE/AutorizacaoVinculaCursos';
import GravarPublicacaoEmLote from './pages/CAE/GravarPublicacaoEmLote';
import VerCancelamentoRegistro from './pages/CAE/VerCancelamentoRegistro';
import DiplomaListaAnulados from './pages/CAE/DiplomaListaAnulados';

const App = () => {
    const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
    return (
        <Layout
            nomeSistema={process.env.REACT_APP_NOME_SISTEMA}
            usuarioLogado={(usuario == null || usuario == undefined || usuario == "99999999999") ? false : true}
            urlSair=""
            urlPerfil="">
            <main className="container-fluid mb-3">
                <Router basename="/digital" history={history}>
                    <Switch>
                        <Route path="/Diploma/Lista-Anulados" component={DiplomaListaAnulados} />
                        <Route path="/autorizacaoCurso/vincula-cursos/:id" component={AutorizacaoVinculaCursos} />
                        <Route path="/autorizacaoCurso/exclui/:id" component={AutorizacaoCursoExclui} />
                        <Route path="/autorizacaoCurso/detalhe/:id" component={AutorizacaoCursoVerDetalhe} />
                        <Route path="/autorizacaoCurso/edita/:id" component={AutorizacaoCursoEdita} />
                        <Route path="/autorizacaoCurso/novo" component={AutorizacaoCursoNovo} />
                        <Route path="/autorizacaoCurso" component={AutorizacaoCursoConsulta} />
                        <Route path="/Conector/Detalhes/:registroId" component={DetalheConector} />
                        <Route path="/Conector/Enviar" component={EnviarConector} />
                        <Route path="/Documentacao/Cadastrar/:rga?" component={CadastrarDocumento} />
                        <Route path="/livros/excluir/:livroId" component={LivroRegistroExclui} />
                        <Route path="/livros/novo" component={LivroRegistroNovo} />
                        <Route path="/livros" component={LivroRegistroConsulta} />
                        <Route path="/reconhecimentos/vincula-cursos/:id" component={ReconhecimentoVinculaCursos} />
                        <Route path="/reconhecimentos/exclui/:id" component={ReconhecimentoExclui} />
                        <Route path="/reconhecimentos/detalhe/:id" component={ReconhecimentoVerDetalhe} />
                        <Route path="/reconhecimentos/edita/:id" component={ReconhecimentoEdita} />
                        <Route path="/reconhecimentos/novo" component={ReconhecimentoNovo} />
                        <Route path="/reconhecimentos" component={ReconhecimentoConsulta} />
                        <Route path="/curso-formacoes" component={CursoFormacaoConsulta} />
                        <Route path="/gerenciar-curso/:cursoId" component={GerenciarCurso} />
                        <Route path="/cursos" component={CursoConsulta} />
                        <Route path="/gravar-nova-data-publicacao-em-lote" component={GravarPublicacaoEmLote} />
                        <Route path="/gravar-nova-data-publicacao/:registroId" component={GravarPublicacao} />                        
                        <Route path="/reativa-registro/:registroId" component={ReativarRegistro} />
                        <Route path="/cancela-registro/:registroId" component={CancelarRegistro} />
                        <Route path="/ver-cancelamento-registro/:registroId" component={VerCancelamentoRegistro} />
                        <Route path="/revoga-cancelamento-registro/:registroId" component={RevogaCancelamentoRegistro} />
                        <Route path="/consulta-individual-formandos" component={ConsultaIndividualFormandos} />
                        <Route path="/consulta-formandos/:dataColacaoId?" component={ConsultarFormandos} />
                        <Route path="/registros/:dataInicio?/:dataFim?" component={RegistroConsulta} />
                        <Route path="/novo-registro/:rga" component={RegistroNovo} />
                        <Route path="/cae/:token" component={Home} />
                        <Route path="/cae" component={Home} />
                        <Route path="/ConsultaPublica/:dataInicio?/:dataFim?" component={ConsultaPublica} />
                        <Route path="/RegistroPublicoDetalhe/:id" component={RegistroPublicoDetalhe} />
                        <Route path="/Localizar/:codigoValidacao" component={LocalizarDiploma} />
                        <Route path="/ConsultarDiploma/:codigoValidacao" component={ConsultarDiploma} />
                        <Route path="/RegistroPublicoAnuladoDetalhe" component={RegistroPublicoAnuladoDetalhe} />
                        <Route path="/diploma/:codigo?" component={ValidarDiploma} />
                        <Route path="/Registro/Antigo/Consultar" component={RegistroConsultarAntigo} />
                        {/* <Route path="/Registro/Consultar" component={ConsultaPublica} /> */}
                        <Route path="/Registro/Localizar?localizador=:codigo" component={RegistroLocalizarAntigo} />
                        <Route path="*" component={ConsultaPublica} />
                    </Switch>
                </Router>
            </main>
        </Layout>
    )
}
export default App;
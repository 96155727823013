import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import GravarPublicacaoEmLoteForm from '../../components/Area/CAE/GravarPublicacaoEmLoteForm'
import { loadRegistro, loadRegistrosSelecionados } from './../../store/thunks'
import { connect } from 'react-redux'
import { IrPortal } from "../../util/funcoes";

class GravarPublicacao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {   
            registrosSelecionados: []      
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }
    componentDidMount() {
       // this.props.dispatch(loadRegistrosSelecionados(this.props.registrosSelecionados));
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./registros">
                                Consulta de Registros
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Gravar Data de Publicação em Lote
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Data de Publicação de Registro</h2>
                            <small>Informe a data de publicação no DOU.</small>
                        </Card.Title>
                        <br></br>
                        <GravarPublicacaoEmLoteForm
                            registrosSelecionados={
                                this.props.registrosSelecionados
                            }                            
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    registrosSelecionados: state.registros.registrosSelecionados,
    registrosSelecionadosDados: state.registros.registrosSelecionadosDados,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(GravarPublicacao)

import ActionTypes from './actionTypes'
import Swal from 'sweetalert2'

const initialState = {
    registros: [],
    loading: false,
    error: null,
    registro: {},
    estudante: {},
    estudantes: [],
    estudantesFormandos: [],
    datasColacao: [],
    ufs: [],
    municipios: [],
    campus: [],
    anos: [],
    anotacoes: [],
    enade: {},
    registrosSelecionados: [],
    registrosSelecionadosDados: []
}

export default function registroReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.REVOGA_CANCELAMENTO_REGISTRO: {
            const registroId = payload
            let obj = state.registros.find((x) => x.registroID === registroId)
            let index = state.registros.indexOf(obj)
            state.registros.fill((obj.cancelado = false), index, index++)
            return {
                ...state,
                loading: false,
                registros: state.registros,
            }
        }
        case ActionTypes.CANCELA_REGISTRO: {
            const registroId = payload
            let obj = state.registros.find((x) => x.registroID === registroId)
            let index = state.registros.indexOf(obj)
            state.registros.fill((obj.cancelado = true), index, index++)
            return {
                ...state,
                loading: false,
                registros: state.registros,
            }
        }
        case ActionTypes.ADD_NOVA_DATA_PUBLICACAO_REGISTRO: {
            return {
                ...state,
                loading: false,
                registros: state.registros,
            }
        }
        case ActionTypes.ADD_REGISTRO: {
            const registro = payload
            const newState = state.registros.concat(registro)
            return {
                ...state,
                loading: false,
                registros: newState,
            }
        }
        case ActionTypes.REMOVE_REGISTRO: {
            const id = payload
            const newState = state.registros.filter(
                (registro) => registro.reconhecimentoID !== id
            )
            return {
                ...state,
                loading: false,
                registros: newState,
            }
        }

        case ActionTypes.CLEAR_ESTUDANTES_FORMANDOS: {
            return {
                ...state,
                loading: false,
                estudantesFormandos: [],
            }
        }

        case ActionTypes.CLEAR_ESTUDANTES: {
            return {
                ...state,
                loading: false,
                estudantes: [],
            }
        }
        case ActionTypes.LOAD_ESTUDANTES_FORMANDOS_IN_PROGRESS: {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Carregando...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            })
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ESTUDANTES_FORMANDOS_SUCCESS: {
            Swal.close()
            return {
                ...state,
                loading: false,
                estudantesFormandos: payload.estudantes,
            }
        }
        case ActionTypes.LOAD_ESTUDANTES_FORMANDOS_FAILURE: {
            Swal.close()
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                estudantesFormandosss: [],
            }
        }
        case ActionTypes.LOAD_ESTUDANTES_IN_PROGRESS: {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Carregando...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            })
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ESTUDANTES_SUCCESS: {
            Swal.close()
            return {
                ...state,
                loading: false,
                estudantes: payload.estudantes,
            }
        }
        case ActionTypes.LOAD_ESTUDANTES_FAILURE: {
            Swal.close()
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                estudantes: [],
            }
        }
        case ActionTypes.LOAD_ANOTACOES_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ANOTACOES_SUCCESS: {
            return {
                ...state,
                loading: false,
                anotacoes: payload.anotacoes,
            }
        }
        case ActionTypes.LOAD_ANOTACOES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                anotacoes: [],
            }
        }
        case ActionTypes.LOAD_REGISTROS_IN_PROGRESS: {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Carregando...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            })
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_REGISTROS_SUCCESS: {
            Swal.close()
            return {
                ...state,
                loading: false,
                registros: payload.registros,
            }
        }
        case ActionTypes.LOAD_REGISTROS_FAILURE: {
            Swal.close()
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                registros: [],
            }
        }
        case ActionTypes.LOAD_REGISTRO_POR_ID_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_REGISTRO_POR_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                registro: payload.registro,
            }
        }
        case ActionTypes.LOAD_REGISTRO_POR_ID_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                registro: {},
            }
        }

        case ActionTypes.LOAD_DADOS_FORMANDO_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_DADOS_FORMANDO_SUCCESS: {
            return {
                ...state,
                loading: false,
                estudante: payload.estudante,
            }
        }
        case ActionTypes.LOAD_DADOS_FORMANDO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                estudante: {},
            }
        }
        case ActionTypes.LOAD_CAMPUS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_CAMPUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                campus: payload.campus,
            }
        }
        case ActionTypes.LOAD_CAMPUS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                campus: [],
            }
        }
        case ActionTypes.ADD_NOVO_REGISTRO: {
            return {
                ...state,
                loading: false,
                error: false,
            }
        }
        case ActionTypes.ADD_NOVO_REGISTRO_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.ADD_NOVO_REGISTRO_SUCCESS: {
            return {
                ...state,
                loading: false,
            }
        }
        case ActionTypes.ADD_NOVO_REGISTRO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        }
        case ActionTypes.LOAD_ANOS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ANOS_SUCCESS: {
            return {
                ...state,
                loading: false,
                anos: payload.anos,
            }
        }
        case ActionTypes.LOAD_ANOS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                anos: [],
            }
        }
        case ActionTypes.LOAD_DATAS_COLACAO_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_DATAS_COLACAO_SUCCESS: {
            return {
                ...state,
                loading: false,
                datasColacao: payload.datasColacao,
            }
        }
        case ActionTypes.LOAD_DATAS_COLACAO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                datasColacao: [],
            }
        }
        case ActionTypes.LOAD_UFS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_UFS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ufs: payload.ufs,
            }
        }
        case ActionTypes.LOAD_UFS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                ufs: [],
            }
        }
        case ActionTypes.LOAD_MUNICIPIOS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_MUNICIPIOS_SUCCESS: {
            return {
                ...state,
                loading: false,
                municipios: payload.municipios,
            }
        }
        case ActionTypes.LOAD_MUNICIPIOS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                municipios: [],
            }
        }
        case ActionTypes.LOAD_ESTUDANTE_ENADE_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ESTUDANTE_ENADE_SUCCESS: {
            return {
                ...state,
                loading: false,
                enade: payload.enade,
            }
        }
        case ActionTypes.LOAD_ESTUDANTE_ENADE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                enade: {},
            }
        }
        case ActionTypes.SET_SELECTED_REGISTROS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.SET_SELECTED_REGISTROS_SUCCESS: {
            return {
                ...state,
                loading: false,
                registrosSelecionados: payload.registrosSelecionados,
            }
        }
        case ActionTypes.SET_SELECTED_REGISTROS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                registrosSelecionados: {},
            }
        }
        case ActionTypes.LOAD_REGISTROS_SELECIONADOS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_REGISTROS_SELECIONADOS_SUCCESS: {
            return {
                ...state,
                loading: false,
                registrosSelecionadosDados: payload.registrosSelecionadosDados,
            }
        }
        case ActionTypes.LOAD_REGISTROS_SELECIONADOS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                registrosSelecionadosDados: {},
            }
        }
        default:
            return state
    }
}

import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Button, Form, Table, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addNewReconhecimentoRequest } from '../../store/thunks'
import * as api from './../../api/api'
import Swal from 'sweetalert2'
import { Formik, Field, ErrorMessage } from 'formik'
import apiAxios from "./../../api/apiAxios";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { allowedNodeEnvironmentFlags } from 'process'
import TabelaReconhecimentosCursos from './../../components/Area/CAE/TabelaReconhecimentoCursos'
import NovoReconhecimentoCursoForm from './../../components/Area/CAE/NovoReconhecimentoCursoForm'
import apiURLs from './../../api/apiURLs';
import { IrPortal } from "../../util/funcoes";

const options = {
    headers: { 'content-type': 'application/json' },
}
class ReconhecimentoForm extends React.Component {
    static displayName = ReconhecimentoForm.name
    constructor(props) {
        super(props)
        this.state = {
            TipoAtoRegulatorioUid: '',
            Numero: '',
            Data: null,
            VeiculoPublicacao: '', 
            DataPublicacao: null,
            SecaoPublicacao: '',
            PaginaPublicacao: '',    
            NumeroPublicacao: '',      
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeDate = this.handleInputChangeDate.bind(this)
    }

    componentDidMount(){  
        if(this.props.atoRegulatorioUid != undefined){
            this.setAtoRegulatorio(); 
        }
    }
    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        let regexpOnlyNumber = /^[0-9\b]+$/ //só numero

        if (name === 'Numero' || name === 'NumeroPublicacao') {
            if (regexpOnlyNumber.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else {
            this.setState({
                [name]: value,
            })
        }
    }   
    handleInputChangeDate(date, name) {
        if(name === "Data"){
            if (date === null) {
                date = new Date()
            }
        }
        this.setState({
            [name]: date,
        })
    }

    RedirectToConsultaReconhecimentos = () => {
        window.location.href = '/digital/reconhecimentos'
    }

    setAtoRegulatorio() {
        // const url = `https://localhost:5001/api/reconhecimento/ato-regulatorio/dados/${this.props.atoRegulatorioUid}`;
         const url = apiURLs.GET_DADOS_ATO_REGULATORIO + this.props.atoRegulatorioUid;
        apiAxios
            .get(url)
            .then((result) => {
                console.log(result.data);
                this.setState({ 
                    TipoAtoRegulatorioUid: result.data.tipoAtoRegulatorioUid,
                    Numero: result.data.numero,
                    Data: new Date(result.data.data),
                    VeiculoPublicacao: result.data.veiculoPublicacao, 
                    DataPublicacao:  new Date(result.data.dataPublicacao),
                    SecaoPublicacao: result.data.secaoPublicacao,
                    PaginaPublicacao: result.data.paginaPublicacao,
                    NumeroPublicacao: result.data.numeroPublicacao  })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }

    salvarReconhecimento = () => {
        try {
            let errors = {}
            if (this.state.TipoAtoRegulatorioUid === '' || this.state.TipoAtoRegulatorioUid == '0') {
                errors.TipoAtoRegulatorioUid = 'Tipo de Ato Regulatório é obrigatório'
            }
            if (this.state.Data === '') {
                errors.Data = 'Data é obrigatória'
            }
            if (this.state.Numero === '') {
                errors.Numero = 'Número'
            }
            if (!this.isEmpty(errors)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text:
                        'Existem campos obrigatórios não preenchidos!',
                })
                return;
            }

            Swal.fire({
                showCancelButton: false,
                showConfirmButton: false,
                title: 'Por favor aguarde!',
                html: 'Ato Regulatório de Reconhecimento de Curso(s) está sendo salvo.',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            var obj = {
                TipoAtoRegulatorioUid: Number(this.state.TipoAtoRegulatorioUid),
                Numero: this.state.Numero,
                Data: this.state.Data,               
            };

            if(this.props.atoRegulatorioUid != "" && this.props.atoRegulatorioUid != undefined){
                obj.AtoRegulatorioUid = Number(this.props.atoRegulatorioUid);
            }

            if(this.state.NumeroPublicacao != ""){
                obj.NumeroPublicacao = this.state.NumeroPublicacao;
            }
            if(this.state.VeiculoPublicacao != ""){
                obj.VeiculoPublicacao = this.state.VeiculoPublicacao;
            }
            if(this.state.PaginaPublicacao != ""){
                obj.PaginaPublicacao = Number(this.state.PaginaPublicacao);
            }
            if(this.state.SecaoPublicacao != ""){
                obj.SecaoPublicacao = Number(this.state.SecaoPublicacao);
            }
            if(this.state.DataPublicacao != "" && this.state.DataPublicacao != null && this.state.DataPublicacao != undefined){
                obj.DataPublicacao = this.state.DataPublicacao;
            }

            obj.TipoCadastro = 2;
            const novoReconhecimento = JSON.stringify(obj);

            //let url = 'https://localhost:5001/api/reconhecimento/ato-regulatorio/novo'

            apiAxios
                .post(apiURLs.NOVO_ATO_REGULATORIO, novoReconhecimento, options)
                .then((resp) => {
                    Swal.close()

                    Swal.fire({
                        icon: 'success',
                        title:
                            'Ato Regulatório de Reconhecimento de Curso(s) cadastrado/alterado com sucesso! Redirecionando...',
                        showConfirmButton: false,
                        timer: 4000,
                    }).then(this.RedirectToConsultaReconhecimentos())
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status !== 200) {
                            Swal.close()
                            Swal.fire({
                                icon: 'error',
                                title: 'Erro ao cadastrar/editar Ato Regulatório de Reconhecimento de Curso(s)!',
                                html:
                                    'Verifique se todos os campos obrigatórios foram preenchidos e tente novamente. ' +
                                    'Se erro persistir entre em contato com o administrador do sistema.' +
                                    '<br></br>' +
                                    error //payload
                                        ? error//payload
                                        : '',
                            })
                        }
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                        console.log(error.request)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                        console.log('Error', error.message)
                    }
                }) 

        } catch (e) {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao processar ação!',
                html:
                    'Tente novamente. ' +
                    'Se erro persistir entre em contato com o administrador do sistema.',
            })
            console.log(e)
        }
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return JSON.stringify(obj) === JSON.stringify({})
    }

    render() {
        
        return (
            <Formik
                initialValues={{   
                    TipoAtoRegulatorioUid: this.state.TipoAtoRegulatorioUid,                 
                    Numero: this.state.Numero,
                    Data: this.state.Data,
                    VeiculoPublicacao: this.state.VeiculoPublicacao,
                    DataPublicacao: this.state.DataPublicacao,
                    SecaoPublicacao: this.state.SecaoPublicacao,
                    PaginaPublicacao: this.state.PaginaPublicacao,   
                    NumeroPublicacao: this.state.NumeroPublicacao,                
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.salvarReconhecimento()
                }}
            >
                {({ touched, errors, isSubmitting, handleSubmit }) => (
                    <Form
                        name="novoReconhecimentoForm"
                        id="novoReconhecimentoForm"
                        onSubmit={handleSubmit}
                    >
                        
                        <br />                            

                        <Form.Row className="d-flex">
                                                                
                        <div className="col-auto">
                            <Form.Label>
                                Tipo de Ato Regulatório{' '} <b style={{color: "red"}}>*</b>
                            </Form.Label>
                            <Field
                                as="select"
                                title="Tipo de Ato Regulatório"
                                id="TipoAtoRegulatorioUid"
                                name="TipoAtoRegulatorioUid"
                                className={`form-select ${
                                    touched.TipoAtoRegulatorioUid &&
                                    errors.TipoAtoRegulatorioUid
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                onChange={this.handleInputChange}
                                value={this.state.TipoAtoRegulatorioUid}
                                required
                            >
                                <option
                                value=""
                        >
                            Selecione...
                        </option>
                                {this.props.tiposAtoRegulatorio.map((option, index) => (
                                    <option
                                        key={option.tipoAtoId}
                                        value={option.tipoAtoId}
                                    >
                                        {option.nome}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage
                                component="div"
                                name="TipoAtoRegulatorioUid"
                                className="invalid-feedback"
                            />
                        </div>
                        </Form.Row>
                         
                        <Form.Row>  
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={2}
                                ld={2}
                                controlId="formGridNome"
                            >
                                <Form.Label>Número{' '} <b style={{color: "red"}}>*</b></Form.Label>
                                <Field
                                    type="text"
                                    placeholder="Ex.: 256"
                                    maxLength="10"
                                    title="Número"
                                    name="Numero"
                                    id="Numero"
                                    value={this.state.Numero}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${
                                        touched.Numero && errors.Numero
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    required
                                />
                                <ErrorMessage
                                    component="div"
                                    name="Numero"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                                                        
                            <Form.Group as={Col} sm={12} md={2} lg={2}>
                                <Form.Label>Data{' '} <b style={{color: "red"}}>*</b></Form.Label>{' '}
                                <br></br>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.Data}
                                    className={`form-control ${
                                        touched.Data &&
                                        errors.Data
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    onChange={(date) =>
                                        this.handleInputChangeDate(
                                            date,
                                            'Data'
                                        )
                                    }
                                />
                                <ErrorMessage
                                    component="div"
                                    name="Data"
                                    className="invalid-feedback"
                                />
                            </Form.Group>

                        </Form.Row>
                        
                        <Form.Row>  
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={4}
                                ld={4}
                                controlId="formGridNome"
                            >
                                <Form.Label>Veículo de Publicação</Form.Label>
                                <Field
                                    type="text"
                                    placeholder="Ex.: DOU"
                                    maxLength="50"
                                    title="Veículo de Publicação"
                                    name="VeiculoPublicacao"
                                    id="VeiculoPublicacao"
                                    value={this.state.VeiculoPublicacao}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${
                                        touched.VeiculoPublicacao && errors.VeiculoPublicacao
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="VeiculoPublicacao"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                            
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={2}
                                ld={2}
                                controlId="formGridNome"
                            >
                                <Form.Label>Número da Publicação{' '} </Form.Label>
                                <Field
                                    type="text"
                                    placeholder="Ex.: 256"
                                    maxLength="10"
                                    title="Número da Publicação"
                                    name="NumeroPublicacao"
                                    id="NumeroPublicacao"
                                    value={this.state.NumeroPublicacao}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${
                                        touched.NumeroPublicacao && errors.NumeroPublicacao
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="NumeroPublicacao"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
           
                        </Form.Row>

                        <Form.Row> 
                            
                        <Form.Group as={Col} sm={12} md={2} lg={2}>
                                <Form.Label>Data de Publicação</Form.Label>{' '}
                                <br></br>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.DataPublicacao}
                                    className={`form-control ${
                                        touched.DataPublicacao &&
                                        errors.DataPublicacao
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    onChange={(date) =>
                                        this.handleInputChangeDate(
                                            date,
                                            'DataPublicacao'
                                        )
                                    }
                                />
                                <ErrorMessage
                                    component="div"
                                    name="DataPublicacao"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={2}
                                ld={2}
                                controlId="formGridNome"
                            >
                                <Form.Label>Seção de Publicação</Form.Label>
                                <Field
                                    type="text"
                                    placeholder="Ex.: 234"
                                    maxLength="10"
                                    title="Seção de Publicação"
                                    name="SecaoPublicacao"
                                    id="SecaoPublicacao"
                                    value={this.state.SecaoPublicacao}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${
                                        touched.SecaoPublicacao && errors.SecaoPublicacao
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="SecaoPublicacao"
                                    className="invalid-feedback"
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                sm={12}
                                md={2}
                                ld={2}
                                controlId="formGridNome"
                            >
                                <Form.Label>Página de Publicação</Form.Label>
                                <Field
                                    type="text"
                                    placeholder="Ex.: 23"
                                    maxLength="10"
                                    title="Página de Publicação"
                                    name="PaginaPublicacao"
                                    id="PaginaPublicacao"
                                    value={this.state.PaginaPublicacao}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${
                                        touched.PaginaPublicacao && errors.PaginaPublicacao
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="PaginaPublicacao"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                        </Form.Row>
                        <div className="mt-3 d-flex gap-3">
                            <Link className="btn btn-primary" to="/reconhecimentos">
                                Voltar
                            </Link>
                            <Button variant="success" type="submit">
                                {(this.props.atoRegulatorioUid != "" && this.props.atoRegulatorioUid != undefined) ? "Editar" : 'Cadastrar' }
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default connect()(ReconhecimentoForm)

import * as React from 'react'
import { Col, Form, Card } from 'react-bootstrap'
import EnderecoDados from './../../Publica/EnderecoDados'
import AtoRegulatorioDados from './../../Publica/AtoRegulatorioDados'
import { format } from 'date-fns'
import { Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'

class RegistroNovoCamposDadosCurso extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        if (this.props.currentStep !== 2) {
            return null
        }
        return (
        <>
            <Card>
                <Card.Header>
                    <h3>{this.props.currentStep} - Dados do Curso</h3>
                </Card.Header>
                <Card.Body>
                    
                    <div className="row">
                        <div className="mb-3 col">
                            <Form.Label>Curso:</Form.Label>
                            <Field
                                type="text"
                                title="Curso"
                                name="NomeCurso"
                                id="NomeCurso"
                                placeholder="Ex.: Sistemas de Informação"
                                maxLength="255"
                                min="2"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${this.props.touched.NomeCurso && this.props.errors.NomeCurso ? 'is-invalid' : ''}`}
                                required
                                value={this.props.NomeCurso} />
                            <ErrorMessage
                                component="div"
                                name="NomeCurso"
                                className="invalid-feedback" />
                        </div>
                        
                        <div className="mb-3 col-auto">
                            <Form.Label>Modalidade:</Form.Label>
                            <Form.Control
                                as="select" className="form-select"
                                name="Modalidade"
                                id="Modalidade"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.Modalidade && this.props.errors.Modalidade ? 'is-invalid' : ''}`}
                                required
                                value={this.props.Modalidade} >
                                <option value="Presencial">Presencial</option>
                                <option value="EAD">EAD</option>
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="Modalidade"
                                className="invalid-feedback" />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>Curso EMEC:</Form.Label> <br></br>
                            <input className="form-control" type="text" readOnly disabled value={this.props.CodigoCursoEMEC} />
                            
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="mb-3 col-auto">
                            <Form.Label>Grau:</Form.Label>
                            <Form.Control
                                as="select"
                                name="Grau"
                                id="Grau"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.Grau && this.props.errors.Grau ? 'is-invalid' : ''}`}
                                value={this.props.Grau}
                                required >
                                {this.props.cursosGraus.map((option, index) => (
                                    <option key={option.grauID} value={option.descricao}>{option.descricao}</option>
                                ))}
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="Grau"
                                className="invalid-feedback" />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>Formação:</Form.Label>
                            <Form.Control
                                as="select"
                                name="CursoFormacao"
                                id="CursoFormacao"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.CursoFormacao && this.props.errors.CursoFormacao ? 'is-invalid' : ''}`}
                                value={this.props.CursoFormacao}
                                required>
                                {this.props.cursoFormacoes.length === 0 ? (<option key={0} value={0}>{' '}Não se aplica.</option>)
                                    : (this.props.cursoFormacoes.map((option, index) => (<option key={option.cursoFormacaoID} value={option.formacao}>{option.formacao}</option>)))}
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="CursoFormacao"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                    
                    <div className="row d-flex">
                            <div className="mb-3 col-auto">
                                <label htmlFor="TituloConferido" className="mb-0">
                                Título Conferido:<br />
                                <small className="mb-0">O sexo é exibido apenas para auxílio. Somente o título será exibido no diploma.</small>
                            </label>
                            <Form.Control
                                as="select"
                                name="TituloConferido"
                                id="TituloConferido"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.TituloConferido && this.props.errors.TituloConferido ? 'is-invalid' : ''}`}
                                value={this.props.TituloConferido}
                                required>
                                {this.props.cursoTitulacoes.map((option, index) => (
                                    <option
                                        key={option.cursoTitulacaoID}
                                        value={option.titulacao}>
                                        {option.sexo === 'F' ? 'Feminino - ' + option.titulacao : 'Masculino - ' + option.titulacao}
                                    </option>
                                ))}
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="TituloConferido"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="mb-3 col-auto">
                            <Form.Label>Reconhecimento:</Form.Label>
                           <Form.Control
                                as="select"
                                name="ReconhecimentoID"
                                id="ReconhecimentoID"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.ReconhecimentoID && this.props.errors.ReconhecimentoID ? 'is-invalid' : ''}`}
                                value={this.props.ReconhecimentoID}
                                required>
                                {this.props.cursoReconhecimentos.map(
                                    (option, index) => (
                                        <option key={option.reconhecimentoCursoUid} value={option.reconhecimentoCursoUid}>
                                            {option.atoRegulatorio.tipo} nº {option.atoRegulatorio.numero}, de{' '}
                                            {format(new Date(Date.parse(option.atoRegulatorio.data)), 'dd/MM/yyyy')}, 
                                            {option.atoRegulatorio.veiculoPublicacao} nº {option.atoRegulatorio.numeroPublicacao}, 
                                            Seção{' '}{option.atoRegulatorio.secaoPublicacao}, pág.{' '}{option.atoRegulatorio.paginaPublicacao}, de{' '}
                                            {   (option.atoRegulatorio.dataPublicacao != "0001-01-01T00:00:00") 
                                                 ? format(new Date(Date.parse(option.atoRegulatorio.dataPublicacao)), 'dd/MM/yyyy')
                                                 : "-" 
                                            }
                                            .{' '}{option.processoReconhecimento ? 'Processo nº ' + option.processoReconhecimento + '.' : ''}
                                        </option>
                                    )
                                )}
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="ReconhecimentoID"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="mb-0 col">
                            <Form.Label>Habilitação:</Form.Label>
                            <Field
                                type="text"
                                title="Habilitação"
                                name="Habilitacao"
                                id="Habilitacao"
                                placeholder="Reconhecimento sem habilitação."
                                maxLength="255"
                                min="2"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${
                                    this.props.touched.Habilitacao &&
                                    this.props.errors.Habilitacao
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                value={this.props.Habilitacao ?? ""}
                                required
                                readOnly
                            />
                            <ErrorMessage
                                component="div"
                                name="Habilitacao"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Card className="my-3">
                <Card.Body>
                    <Card.Title>Endereço de Curso</Card.Title>
                    <EnderecoDados dados={this.props.EnderecoCurso} />
                </Card.Body>
            </Card>
                    
            <Card>
                <Card.Body>
                    <Card.Title>Autorização de Curso</Card.Title>
                    {
                        (this.props.AutorizacaoCurso != null) 
                        ?
                        <AtoRegulatorioDados
                        dados={this.props.AutorizacaoCurso}
                        />
                        :                                
                        <Link
                        to="/autorizacaoCurso"
                        className="btn btn-primary">
                        <i className="fas fa-plus-circle"></i> Nova Autorização
                    </Link>
                    } 
                </Card.Body>
            </Card>
        </>
        )
    }
}

export default RegistroNovoCamposDadosCurso

import * as React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaAtosAutorizacao from '../../components/Area/CAE/TabelaAtosAutorizacao'
import { loadAtosRegulatoriosComVinculosCursosAutorizacao } from '../../store/thunks'
import { IrPortal } from "../../util/funcoes";

class AutorizacaoCursoConsulta extends React.Component {
    static displayName = AutorizacaoCursoConsulta.name
    constructor(props) {
        super(props)
        this.state = { autorizacoes: [] }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        this.props.dispatch(loadAtosRegulatoriosComVinculosCursosAutorizacao())
    }

    render() {
        const { autorizacoes } = this.props
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./cursos">
                                Gerenciar Cursos
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Consulta de Atos Regulatórios de Autorização de Curso(s)
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Link
                            to="/autorizacaoCurso/novo"
                            className="btn btn-primary">
                            <i className="fas fa-plus-circle"></i> Novo
                        </Link>

                        <br></br>
                        <TabelaAtosAutorizacao
                            autorizacoes={autorizacoes}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    autorizacoes: state.autorizacoes.autorizacoes,
    loading: state.autorizacoes.loading,
    error: state.autorizacoes.error,
})

export default connect(mapStateToProps)(AutorizacaoCursoConsulta)

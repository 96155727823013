import * as React from 'react'
import { Card, Row, Col,ListGroup } from 'react-bootstrap'
import { format } from 'date-fns'

export class SituacaoRegistro extends React.Component {
    render() {
        function DiplomaStatus(props) {
            const isAtivo = props.isAtivo
            if (isAtivo) {
                return <span>Ativo.</span>
            } else {
                return <span>Anulado.</span>
            }
        }

        function AnotacoesVazio(props) {
            return <>Nenhuma anotação/justificativa cadastrada.</>
        }

        function AnotacoesLista(props) {
            return (
                <div>
                    {props.anotacoes.map((anotacao) => (
                        <>
                        <div key={anotacao.registroAnotacaoID}>
                            {/*<Row>                                */}
                            {/*    <Col>*/}
                            {/*        <span>{anotacao.motivoAnulacao.nome}</span>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row>                                
                                <Col>
                                    <span>{anotacao.justificativa}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {format(
                                        new Date(
                                            Date.parse(anotacao.dataJustificativa)
                                        ),
                                        'dd/MM/yyyy HH:mm'
                                    )}
                                    <br />
                                    <small>
                                        {anotacao.responsavel}
                                        {' - '}
                                        {anotacao.responsavelDocumento}
                                    </small>
                                </Col>
                            </Row>
                            {(props.anotacoes.at(-1).registroAnotacaoID != anotacao.registroAnotacaoID) ? <hr></hr> : null}                            
                        </div>
                        </>
                    ))}
                </div>
            )
        }

        function Anotacoes(props) {
            if (!props.anotacoes) {
                return <AnotacoesVazio />
            }
            const hasSomething = props.anotacoes.length > 0
            if (hasSomething) {
                return <AnotacoesLista anotacoes={props.anotacoes} />
            } else {
                return <AnotacoesVazio />
            }
        }

        return (
            <Card id="situacaoRegistro">
                <Card.Body>
                    <Card.Title>
                        <h3>Situação do Diploma</h3>
                    </Card.Title>                    
                    <div>
                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start list-group-item-no-border"
                        >
                            <div className="ms-2 me-auto" >
                            <div className="fw-bold">Situação do Registro/Diploma:</div>
                                <div className='fonte-cinza'>
                                    <DiplomaStatus
                                        isAtivo={
                                            this.props.isCancelado === true
                                                ? false
                                                : true
                                        }
                                    />
                                </div>
                            </div>
                        </ListGroup.Item>
                        {(this.props.isCancelado === false) ?
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start list-group-item-no-border"
                            >
                                <div className="ms-2 me-auto">
                                <div className="fw-bold">XML Diploma Digital:</div>
                                    <div className='fonte-cinza'>
                                    O acesso ao XML do Diploma Digital e da Representação Visual do Diploma (RVDD) é feito pela área do Estudante no Portal Acadêmico.
                                    </div>
                                </div>
                            </ListGroup.Item> 
                            : null}                        
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start list-group-item-no-border"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Anotações/Justificativas:</div> <br></br>
                                <div className='fonte-cinza'>
                                <Anotacoes anotacoes={this.props.anotacoes} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>                        
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default SituacaoRegistro

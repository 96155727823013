import React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ListGroupItemText } from 'reactstrap'

const TextField = styled.input`
    height: 32px;
    width: 350px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear, onSelected }) => (
    <>
        <Link
            to="/consulta-formandos"
            className="btn btn-primary btn-novo-registro"
        >
            Novo Registro <i className="fas fa-plus-circle"></i>
        </Link>{' '}
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Nome do Diplomado, RGA ou CPF"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

const ButtonsRegistroCancelado = (props) => {
    return (
        <Link
            className="btn btn-danger"
            to={`/ver-cancelamento-registro/${props.registroID}`}
        >
            Ver Detalhes do Cancelamento
        </Link>
    )
}
const renderTooltip = (props) => (
    <Tooltip id="button-tooltip-publicar">
        {props}
    </Tooltip>
);

const ButtonsRegistroAtivo = (props) => {
    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Publicar')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/gravar-nova-data-publicacao/${props.registroID}`}>
                    <i className="fas fa-calendar-day"></i>
                </Link>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Documentação Acadêmica')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/Documentacao/Cadastrar/${props.rga}`}>
                    <i className="fas fa-archive"></i>
                </Link>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Gerenciar XMLs e RVDD')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/Conector/Detalhes/${props.registroID}`}>
                    <i className="fas fa-certificate"></i>
                </Link>
            </OverlayTrigger>
            {props.suspenso ? 
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Desfazer Suspensão')}>
                    <Link
                        className="btn btn-primary btn-list"
                        to={`/reativa-registro/${props.registroID}`}>
                        <i className="fas fa-file-contract"></i>
                    </Link>
                </OverlayTrigger> : <></>
            }
            {/* <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('XML Público')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/RegistroPublicoDetalhe/${props.registroID}`}>
                    <i className="far fa-file-code"></i>
                </Link>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('XML Privado')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/RegistroPublicoDetalhe/${props.registroID}`}>
                    <i className="fas fa-file-code"></i>
                </Link>
            </OverlayTrigger> */}
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Cancelar')}>
                <Link
                    className="btn btn-danger btn-list"
                    to={`/cancela-registro/${props.registroID}`}>
                    <i className="fas fa-ban"></i>
                </Link>
            </OverlayTrigger>
        </>
    )
}
const columns = [
    {
        name: 'Nº Registro',
        selector: 'registroID',
        sortable: true,
        maxWidth: '10%',
    },
    {
        name: 'Via',
        cell: (props) => (props.segundaVia === true ? '2ª' : '1ª'),
        sortable: true,
        maxWidth: '10%',
    },
    {
        name: 'Diplomado',
        selector: 'nomeDiplomado',
        sortable: true,
        maxWidth: '60%', //25% com nomes nos botoes
    },
    {
        cell: (props) =>
            props.cancelado === true ? (
                <ButtonsRegistroCancelado registroID={props.registroID} />
            ) : (
                <ButtonsRegistroAtivo registroID={props.registroID} rga={props.id} suspenso={props.suspenso} />
            ),
        ignoreRowClick: true,
        allowOverflow: true,
        maxWidth: '17.5rem', //80% com nomes nos botoes
    },
]

const BasicTable = (props) => {
    const [filterText, setFilterText] = React.useState('');
    const [selectedData, setSelectedData] = React.useState();
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
    let filteredItems = [];
    if (props.registros != null && props.registros != undefined) {
        try {
            filteredItems = props.registros.filter(
                (item) => (item.nomeDiplomado && item.nomeDiplomado.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.cpf && item.cpf.includes(filterText.toLowerCase())) ||
                    (item.id && item.id.includes(filterText.toLowerCase()))
            )
        } catch (ex) {
            console.log("erro BasicTable", ex);
            console.log("BasicTable registros", props.registros);
        }
    }
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                //onSelected={handleSelected}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])

    const handleChange = (state) => {
        setSelectedData(state.selectedRows);
    };

    const contextActions = React.useMemo(() => {
		
        const handleSelected = () => {
            props.handleSelectedRegistros(selectedData);
        }   

        const handleClearRows = () => {
            setToggleClearRows(!toggledClearRows);
          }

		return (
			<>
            <Button type="button" onClick={handleSelected}   size="sm" style={{marginRight:"5px"}}
            disabled={selectedData == null || selectedData == undefined || selectedData.length == 0}
            >
                Adicionar Data de Publicação DOU Selecionados
            </Button >
            <Button onClick={handleClearRows}  size="sm"
            disabled={selectedData == null || selectedData == undefined || selectedData.length == 0}>
                Limpar seleção
            </Button>
            </>
		);
	}, [filteredItems, selectedData, toggledClearRows]);
      
    return (
        <>
        <DataTable
            title="Registros"
            columns={columns}
            keyField="registroID"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
                rowsPerPageText: 'Registros por página:',
            }}
            persistTableHead
            Clicked
			contextActions={contextActions}
            selectableRows
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggledClearRows}
        />
        
        </>
    )
}

export default BasicTable
import * as React from 'react'
import { Card, Breadcrumb, Row, Col } from 'react-bootstrap'
import Swal from 'sweetalert2'
import apiAxios from '../../api/apiAxios';
import apiURLs from './../../api/apiURLs'
import titleCase from './../../components/Area/CAE/titleCase'
import TabelaCursoTitulacao from './../../components/Area/CAE/TabelaCursoTitulacao'
import CursoTitulacaoForm from './../../components/Area/CAE/CursoTitulacaoForm'
import TabelaCursoFormacao from './../../components/Area/CAE/TabelaCursoFormacao'
import CursoFormacaoForm from './../../components/Area/CAE/CursoFormacaoForm'
import { Link } from 'react-router-dom'
import TabelaCursoReconhecimentosVisualizacao from './../../components/Area/CAE/TabelaCursoReconhecimentosVisualizacao'
import TabelaAutorizacaoCursosVisualizacao from './../../components/Area/CAE/TabelaAutorizacaoCursosVisualizacao'
import TabelaAutorizacaoCursoVisualizacao from './../../components/Area/CAE/TabelaAutorizacaoCursoVisualizacao'

import { IrPortal } from "../../util/funcoes";
import './../../custom-curso.css'

export default class GerenciarCurso extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cursoId: this.props.match.params.cursoId,
            curso: {},
            titulacoes: [],
            formacoes: [],
            reconhecimentos: [],
            AutorizacaoCursos: [],
            updateTitulacaoNeed: false,
            updateFormacaoNeed: false,
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        this.updateListTitulacoes = this.updateListTitulacoes.bind(this)
        this.updateListFormacoes = this.updateListFormacoes.bind(this)
    }

    componentDidMount() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando dados de curso...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_CURSO_POR_ID + this.state.cursoId
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    curso: result.data,
                })
                this.setTitulacoes()
                this.setFormacoes()
                this.setReconhecimentos()
                this.setAutorizacaoCursos()
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }
    componentDidUpdate() {
        if (this.state.updateTitulacaoNeed) {
            this.setTitulacoes()

            this.setState({ updateTitulacaoNeed: false})
        }
        if (this.state.updateFormacaoNeed) {
            this.setFormacoes()

            this.setState({ updateFormacaoNeed: false })
        }
    }
    updateListTitulacoes() {
        this.setState({
            updateTitulacaoNeed: true,
        })
    }
    updateListFormacoes() {
        this.setState({
            updateFormacaoNeed: true,
        })
    }
    setTitulacoes() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando titulações de curso...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_CURSO_TITULACOES + this.state.cursoId
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    titulacoes: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }
    setFormacoes() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando formações de curso...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_CURSO_FORMACOES + this.state.cursoId
        apiAxios
            .get(url)
            .then((result) => {
                console.log(result.data)
                this.setState({
                    formacoes: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }
    setReconhecimentos() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando reconhecimentos de curso...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        //const url = apiURLs.GET_CURSO_RECONHECIMENTOS + this.state.cursoId
        const url = apiURLs.GET_CURSO_RECONHECIMENTOS_POR_CURSO + this.state.cursoId
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    reconhecimentos: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }
    setAutorizacaoCursos() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando cursos vinculados...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })

        //const url = `https://localhost:5001/api/curso/autorizacao/${this.state.cursoId}`;
        const url = apiURLs.GET_AUTORIZACAO_CURSOS_POR_CURSO + this.state.cursoId;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    AutorizacaoCursos: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            }) 
    }
    RedirectToConsultaReconhecimentos = () => {
        window.location.href = '/reconhecimentos'
    }
    
    RedirectToConsultaAutorizacoes = () => {
        window.location.href = '/autorizacaoCurso'
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./cursos">
                                Gerenciar Cursos
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Curso{' '}
                                {titleCase(this.state.curso?.cursoMec?.nome ?? "")}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>
                                Curso:{' '}
                                {titleCase(this.state.curso?.cursoMec?.nome ?? "")}
                            </h2>
                        </Card.Title>
                        <>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Código SIGA:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso
                                        ? this.state.curso.codigoInterno
                                        : ''}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Código E-MEC:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso.cursoMec
                                        ? this.state.curso.cursoMec.codigoEmec
                                        : ''}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Grau:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso.cursoMec
                                        ? this.state.curso.cursoMec.grau.descricao
                                        : ''}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Campus:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso.cursoMec?.campus?.nome ?? ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Oferta:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso.modalidade?.nome ?? ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Turno:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso?.turno?.nome ?? ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Periodicidade:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso?.periodicidade?.nome ?? ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="text-right">
                                    Regime:
                                </Col>
                                <Col lg={8} className="text-left">
                                    {this.state.curso?.regime?.nome ?? ""}
                                </Col>
                            </Row>
                        </>

                        <Card>
                            <Card.Body>
                                <TabelaCursoTitulacao
                                    titulacoes={this.state.titulacoes}
                                    updateListTitulacoes={this.updateListTitulacoes}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        {' '}
                                        Nova Titulação de curso
                                    </Card.Title>
                                    <CursoTitulacaoForm
                                        updateListTitulacoes={this.updateListTitulacoes}
                                        cursoId={this.state.cursoId}
                                    ></CursoTitulacaoForm>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <TabelaCursoFormacao
                                    formacoes={this.state.formacoes}
                                    updateListFormacoes={this.updateListFormacoes}
                                />
                                <Card.Body>
                                    <Card.Title>
                                        {' '}
                                        Nova Formação de curso
                                    </Card.Title>
                                    <CursoFormacaoForm
                                        updateListFormacoes={this.updateListFormacoes}
                                        cursoId={this.state.cursoId}
                                    ></CursoFormacaoForm>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <TabelaCursoReconhecimentosVisualizacao
                                    reconhecimentos={this.state.reconhecimentos}
                                /> 
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <TabelaAutorizacaoCursoVisualizacao
                                    autorizacaoCursos={this.state.AutorizacaoCursos}
                                />
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import ReconhecimentoForm from '../CAE/ReconhecimentoForm'
import apiAxios from './../../api/apiAxios';
import apiURLs from './../../api/apiURLs';
import { IrPortal } from "../../util/funcoes";

export default class ReconhecimentoEdita extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tiposAtoRegulatorio: []
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        this.setTiposAtoRegulatorio();
    }

    setTiposAtoRegulatorio() {
        //const url = 'https://localhost:5001/api/reconhecimento/tiposAto'; 
        apiAxios
        .get(apiURLs.GET_TIPOS_ATO_REGULATORIO)
        .then((result) => {
            this.setState({ tiposAtoRegulatorio: result.data })
        })
        .catch((ex) => {
            console.log(ex)
        }) 
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <Breadcrumb.Item href="./cae">
                        Página Inicial - CAE
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="./reconhecimentos">
                        Consulta de Atos Regulatórios de Reconhecimento de Curso(s)
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    Editar Ato Regulatório de Reconhecimento de Curso(s)
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card className="my-3">
                    <Card.Body>
                        <Card.Title>
                            <h2>Editar Ato Regulatório de Reconhecimento de Curso(s)</h2>
                        </Card.Title>
                        { <ReconhecimentoForm 
                            tiposAtoRegulatorio={this.state.tiposAtoRegulatorio}
                            atoRegulatorioUid={this.props.match.params.id}
                            /> }
                    </Card.Body>
                </Card>
            </>
        )
    }
}

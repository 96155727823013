import ActionTypes from './actionTypes'
import Swal from 'sweetalert2'

const initialState = {
    livros: [],
    loading: false,
    error: null,
}

export default function livroReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.FAILURE: {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao processar ação!',
                html:
                    'Tente novamente. ' +
                    'Se erro persistir entre em contato com o administrador do sistema.',
            })
            return {
                ...state,
                loading: false,
            }
        }
        case ActionTypes.ADD_LIVRO_REGISTRO: {
            Swal.close()
            Swal.fire({
                icon: 'success',
                title:
                    'Livro de registro cadastrado com sucesso! Redirecionando...',
                showConfirmButton: false,
                timer: 2000,
            })
            const livro = payload
            const newState = state.livros.concat(livro)
            //history.push('/livros')
            return {
                ...state,
                loading: false,
                livros: newState,
            }
        }
        case ActionTypes.ADD_LIVRO_REGISTRO_FAILURE: {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao cadastrar livro de registro!',
                html:
                    'Verifique se todos os campos obrigatórios foram preenchidos e tente novamente. ' +
                    'Se erro persistir entre em contato com o administrador do sistema.' +
                    '<br></br>' +
                    payload
                        ? payload
                        : '',
            })
            return {
                ...state,
                loading: false,
                livros: state.livros,
            }
        }
        case ActionTypes.REMOVE_LIVRO_REGISTRO: {
            const id = payload

            const newState = state.livros.filter(
                (livro) => livro.livroID !== id
            )

            Swal.close()
            Swal.fire({
                icon: 'success',
                title: 'Livro de registro excluído com sucesso!',
                showConfirmButton: false,
                timer: 2000,
            })
            return {
                ...state,
                loading: false,
                livros: newState,
            }
        }
        case ActionTypes.REMOVE_LIVRO_REGISTRO_FAILURE: {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao excluir livro de registro!',
                html:
                    'Verifique se o livro possui registros vinculados e tente novamente. ' +
                    'Se erro persistir entre em contato com o administrador do sistema.' +
                    '<br></br>' +
                    payload
                        ? payload
                        : '',
            })
            return {
                ...state,
                loading: false,
                livros: state.livros,
            }
        }
        case ActionTypes.LOAD_LIVROS_IN_PROGRESS: {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Carregando livros de registro...',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_LIVROS_SUCCESS: {
            Swal.close()
            return {
                ...state,
                loading: false,
                livros: payload.livros,
            }
        }
        case ActionTypes.LOAD_LIVROS_FAILURE: {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao carregar livros de registro!',
                html:
                    'Tente novamente. Se erro persistir entre em contato com o administrador do sistema.',
            })
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                livros: [],
            }
        }
        default:
            return state
    }
}
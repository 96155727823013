import * as React from 'react'

const RegistroPublicoAnuladoDetalhe = () => (
    <div className="card">
        <div className="card-body">
            <header>
                <h2 className="card-title">
                    Consulta Pública de Registro de Diplomas
                </h2>
                <h6 className="card-subtitle mb-2 text-muted">
                    Consulta registros de diploma por período, nome e CPF.
                </h6>
            </header>
        </div>
    </div>
)

export default RegistroPublicoAnuladoDetalhe

import * as React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Número sequência"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

export const BasicTable = ({ diplomasAnulados = [], onDownloadXMLPressed }) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = diplomasAnulados;

    function handleButtonClick(id, sequencia) {
        onDownloadXMLPressed(id, sequencia)
    }

    function FormatDate(props) {
        if (props.data != null) {
            let dateTimeArray = props.data.split('T')
            let dataArray = dateTimeArray[0].split('-')
            //console.log(dataArray)
            return (
                <span>
                    {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
                </span>
            )
        } else {
            return <span></span>
        }
    }
    const columns = [
        {
            name: 'Nº Sequencia',
            selector: 'numeroDeSequencia',
            sortable: true,
            maxWidth: '15%',
        },
        {
            name: 'Data de Criação',            
            cell:(props) =>  (props.dataCriacao != null && props.dataCriacao != undefined) ?  <FormatDate
                data={props.dataCriacao}
            /> : '',
            sortable: true,
        },
        {
            name: 'Data Máxima Próxima Atualização',
            cell:(props) =>  (props.dataMaximaProximaAtualizacao != null && props.dataMaximaProximaAtualizacao != undefined) ?  <FormatDate
                data={props.dataMaximaProximaAtualizacao}
            /> : '',
            sortable: true,
        },
        {
            cell: (row) => (
                <Button
                    variant="primary"
                    onClick={() => handleButtonClick(row.controleDiplomasAnuladosUID, row.numeroDeSequencia)}
                >
                    <i className="fas fa-file-download"></i> 
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Listas de Diplomas Anulados"
            columns={columns}
            keyField="text"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            //subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhuma lista encontrada."
            paginationComponentOptions={{
                rowsPerPageText: 'Listas por página:',
            }}
            persistTableHead
            Clicked
        />
    )
}

export default BasicTable

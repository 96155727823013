import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaEstudantes from './../../components/Area/CAE/TabelaEstudantes'
import { connect } from 'react-redux'
import { loadEstudantes } from './../../store/thunks'
import ConsultaFormandosFiltroForm from './../../components/Area/CAE/ConsultaFormandosFiltroForm'
import queryString from 'query-string'
import { IrPortal } from "../../util/funcoes";
import { Link } from 'react-router-dom'

class ConsultarFormandos extends React.Component {
    static displayName = ConsultarFormandos.name
    constructor(props) {
        super(props)
        this.state = {
            estudantes: [],
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        let { dataColacaoId } = queryString.parse(this.props.location.search)
        this.props.dispatch(loadEstudantes(dataColacaoId))
    }

    render() {
        const { estudantes } = this.props //error, loading,
        return (
            <>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link
                            to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/registros">
                            Consulta de Registros
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Consulta de Estudantes Formandos
                    </Breadcrumb.Item>
                </Breadcrumb>
                <ConsultaFormandosFiltroForm
                    anoURL={queryString.parse(this.props.location.search).ano}
                    campusIdURL={queryString.parse(this.props.location.search).campusId}
                    dataColacaoIdURL={queryString.parse(this.props.location.search).dataColacaoId}
                    className="my-3"
                />
                <Card>
                    <Card.Body>
                        <TabelaEstudantes estudantes={estudantes} />
                    </Card.Body>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    estudantes: state.registros.estudantes,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(ConsultarFormandos)
import * as React from 'react'
import { Card, Col, Form } from 'react-bootstrap'

export class AtoRegulatorioDetalhe extends React.Component {
    render() {
        
        function FormatDate(props) {
            if (props.data != null) {
                let dateTimeArray = props.data.split('T')
                let dataArray = dateTimeArray[0].split('-')
                //console.log(dataArray)
                return (
                    <span>
                        {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
                    </span>
                )
            } else {
                return <span></span>
            }
        }

        return (            
            <Card>
                <Card.Body>
                    <Form.Row>
                                                            
                    <Form.Group as={Col} sm={12} md={6} lg={5}>
                        <Form.Label>
                            Tipo de Ato Regulatório:{' '}
                        </Form.Label><br />
                            <Form.Label>{this.props.atoRegulatorio.tipo}</Form.Label>
                    </Form.Group>
                    </Form.Row>
                    
                    <Form.Row>   
                        <Form.Group
                            as={Col}
                            sm={12}
                            md={2}
                            ld={2}
                            controlId="formGridNome"
                        >
                            <Form.Label>Número:{' '} </Form.Label><br />
                            <Form.Label>{this.props.atoRegulatorio.numero}</Form.Label>
                            
                        </Form.Group>
                                                    
                        <Form.Group as={Col} sm={12} md={2} lg={2}>
                            <Form.Label>Data:{' '} </Form.Label>{' '}<br />
                            <Form.Label>{(this.props.atoRegulatorio.data != null && this.props.atoRegulatorio.data != undefined) ?  <FormatDate
                                        data={this.props.atoRegulatorio.data}
                                    /> : ''}
                                    </Form.Label>
                        </Form.Group>

                    </Form.Row>
                    
                    <Form.Row>  
                        <Form.Group
                            as={Col}
                            sm={12}
                            md={2}
                            ld={2}
                            controlId="formGridNome"
                        >
                            <Form.Label>Veículo de Publicação:</Form.Label><br />
                            <Form.Label>{(this.props.atoRegulatorio.veiculoPublicacao == null || this.props.atoRegulatorio.veiculoPublicacao == "" ||
                            this.props.atoRegulatorio.veiculoPublicacao == undefined) ? "--" : this.props.atoRegulatorio.veiculoPublicacao}</Form.Label>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            sm={12}
                            md={2}
                            ld={2}
                            controlId="formGridNome"
                        >
                            <Form.Label>Número da Publicação:{' '} </Form.Label><br />
                            <Form.Label>{this.props.atoRegulatorio.numeroPublicacao}</Form.Label>
                            
                        </Form.Group>
                    </Form.Row>

                    <Form.Row> 
    
                        <Form.Group as={Col} sm={12} md={2} lg={2}>
                            <Form.Label>Data de Publicação:</Form.Label>{' '}<br />
                            <Form.Label>{(this.props.atoRegulatorio.dataPublicacao != null && this.props.atoRegulatorio.dataPublicacao != undefined) ?  <FormatDate
                                        data={this.props.atoRegulatorio.dataPublicacao}
                                    /> : ''}
                                    </Form.Label>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            sm={12}
                            md={2}
                            ld={2}
                            controlId="formGridNome"
                        >
                            <Form.Label>Seção de Publicação:</Form.Label><br />
                            <Form.Label>{this.props.atoRegulatorio.secaoPublicacao}</Form.Label>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            sm={12}
                            md={2}
                            ld={2}
                            controlId="formGridNome"
                        >
                            <Form.Label>Página de Publicação:</Form.Label><br />
                            <Form.Label>{this.props.atoRegulatorio.paginaPublicacao}</Form.Label>
                        </Form.Group>
                    </Form.Row>
                </Card.Body>
            </Card>
        )
    }
}

export default AtoRegulatorioDetalhe

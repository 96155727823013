import * as React from 'react';
import { Card, Breadcrumb,Row, Col, Button, Collapse, Alert } from 'react-bootstrap';
import TabelaRegistro from './../../components/Area/CAE/TabelaRegistro';
import { connect } from 'react-redux';
import { loadRegistros,  loadRegistrosPeriodo, setSelectedRegistros} from './../../store/thunks';
import { IrPortal } from "../../util/funcoes";
import { Link } from 'react-router-dom'
import RegistroConsultaFiltro from './RegistroConsultaFiltro';
import { format } from 'date-fns'
import queryString from 'query-string'
import Swal from 'sweetalert2'
import apiURLs from "../../api/apiURLs";
import apiAxios from '../../api/apiAxios';

class RegistroConsulta extends React.Component {
    static displayName = RegistroConsulta.name
    constructor(props) {
        super(props)
        this.state = { 
            redireciona: false,
            registros: [],
            selectedRows: [],
            dataInicio: null,
            dataFim: null,
            open:false
        }
        this.handleSelectedRegistros = this.handleSelectedRegistros.bind(this)
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        
        this.redireciona = this.redireciona.bind(this)
        this.handleInputChangeDate2 = this.handleInputChangeDate2.bind(this)
        this.verTodos = this.verTodos.bind(this)
    }

    handleInputChangeDate2(date, name) {
        if (date === null) {
            date = new Date()
        }
        this.setState({
            [name]: date,
        })
    }

    verTodos() {
        this.props.history.push(`/registros`)
        this.setState({ redireciona: true })
    }

    redireciona(dataInicio, dataFim) {
        const dataInicioFormatada = format(dataInicio, 'yyyy-MM-dd')
        const dataFimFormatada = format(dataFim, 'yyyy-MM-dd')
        this.props.history.push(
            `/registros?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}`
        )
        this.setState({ redireciona: true })
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        const dataInicio = params.dataInicio
        const dataFim = params.dataFim
        //this.getDadosListaDiplomasAnulados();
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Carregando registros.',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        if (dataInicio != null && dataFim != null) {
            this.props.dispatch(loadRegistrosPeriodo(dataInicio, dataFim))
        } else {
            this.props.dispatch(loadRegistros())
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.redireciona === true) {
            let params = queryString.parse(this.props.location.search)
            const dataInicio = params.dataInicio
            const dataFim = params.dataFim

            if (dataInicio != null && dataFim != null) {
                this.props.dispatch(loadRegistrosPeriodo(dataInicio, dataFim))
            } else {
                this.props.dispatch(loadRegistros())
            }
            this.setState({ redireciona: false })
        }
    }
    
    getDadosListaDiplomasAnulados() {
        
         try {            
             let url = apiURLs.GET_LISTA_DIPLOMAS_ANULADOS;
             //console.log(url);
             apiAxios
                 .get(url)
                 .then((result) => {
                     console.log(result.data);
                     this.setState({ listaDiplomasAnuladosDataCriacao: result.data.dataCriacao, 
                         listaDiplomasAnuladosDataMaximaProximaAtualizacao: result.data.dataMaximaProximaAtualizacao })
                 })
                 .catch((ex) => {
                     console.log(ex)
                 }) 
         } catch (e) {       
             Swal.close()      
             Swal.fire({
                 icon: 'error',
                 title: 'Erro ao buscar lista de diplomas anulados!',
                 html: 'Se erro persistir entre em contato com o administrador do sistema.'
             })
         }
    }

    handleSelectedRegistros(registrosSelecionados) {
        this.props.dispatch(setSelectedRegistros(registrosSelecionados))
        window.location.href = `/gravar-nova-data-publicacao-em-lote`;
    }

    exportarExtratoDOUPDF = () => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados dos registros...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        try {
            let params = queryString.parse(this.props.location.search)
            const dataInicio = params.dataInicio
            const dataFim = params.dataFim

            let buscaDto = {
                DataInicio: dataInicio,
                DataFim: dataFim
            }
            console.log(buscaDto);
            apiAxios.post("/api/registro/exportar-extrato-dou-pdf", JSON.stringify(buscaDto), {
                headers: { 'content-type': 'application/json' },
                responseType: 'blob',
            }).then(response => {
                Swal.close();
                    console.log(response)
                    let fileNameServidor;
                    try {
                        fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
                    } catch (ex) {
                        console.log(ex);
                        fileNameServidor = undefined;
                    }
                    const fileName = (fileNameServidor == undefined ? "extrato-dou.pdf" : fileNameServidor[0]);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    link.click();
            }).catch(ex => {
                Swal.close();
                Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
            });
        } catch (ex) {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        }
    }
    
    exportarExtratoDOUDOC = () => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados dos registros...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        try {
            let params = queryString.parse(this.props.location.search)
            const dataInicio = params.dataInicio
            const dataFim = params.dataFim

            let buscaDto = {
                DataInicio: dataInicio,
                DataFim: dataFim
            }
            console.log(buscaDto);
            apiAxios.post("/api/registro/exportar-extrato-dou-doc", JSON.stringify(buscaDto), {
                headers: { 'content-type': 'application/json' },
                responseType: 'blob',
            }).then(response => {
                Swal.close();
                    console.log(response)
                    let fileNameServidor;
                    try {
                        fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
                    } catch (ex) {
                        console.log(ex);
                        fileNameServidor = undefined;
                    }
                    const fileName = (fileNameServidor == undefined ? "extrato-dou.docx" : fileNameServidor[0]);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    link.click();
            }).catch(ex => {
                Swal.close();
                Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
            });
        } catch (ex) {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        }
    }

    render() {
        let params = queryString.parse(this.props.location.search)
        const dataInicio = params.dataInicio
        const dataFim = params.dataFim
        const { registros } = this.props
        return (
            <div>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link to="/cae" >
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <Breadcrumb.Item active>Consulta de Registros</Breadcrumb.Item>
                </Breadcrumb>

                <div className="my-3">
                    <Button
                        onClick={() => this.setState({ open: !this.state.open })}
                        aria-controls="example-fade-text"
                        aria-expanded={this.state.open}
                    >
                        Consulta por período
                    </Button>
                    <div className="mt-3">
                        <Collapse in={this.state.open} dimension="width">
                            <div id="example-collapse-text">
                                <RegistroConsultaFiltro
                                    dataInicio={this.state.dataInicio}
                                    dataFim={this.state.dataFim}
                                    redireciona={this.redireciona}
                                    verTodos={this.verTodos}
                                    handleInputChangeDate2={this.handleInputChangeDate2}
                                />
                            </div>
                        </Collapse>
                    </div>
                </div>
                
                <Card>
                    <Card.Body>
                        <TabelaRegistro registros={registros} handleSelectedRegistros={this.handleSelectedRegistros}/>
                        {
                    (dataInicio != null && dataFim != null) 
                    ?
                    <>
                        <hr></hr>
                        <Row>
                            <Col>                        
                                <Button
                                    variant="primary"
                                    type="button"
                                    style={{ marginLeft: 15 }}    
                                    onClick={() => { this.exportarExtratoDOUPDF()}}>
                                    <i className="fas fa-file-download"></i> Exportar Extrato Detalhado PDF
                                </Button>        {'  '}       
                                <Button
                                    variant="primary"
                                    type="button"
                                    style={{ marginLeft: 15 }}    
                                    onClick={() => { this.exportarExtratoDOUDOC()}}>
                                    <i className="fas fa-file-download"></i> Exportar Extrato Detalhado DOC
                                </Button>
                            </Col>
                        </Row>
                    </>      
                    :null
                    }
                    {/*<br></br>*/}
                    {/*<Alert variant={(this.state.listaDiplomasAnuladosDataMaximaProximaAtualizacao < new Date) ? "danger": "primary" }>*/}
                    {/*{*/}
                    {/*(this.state.listaDiplomasAnuladosDataCriacao != null)*/}
                    {/*?*/}
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*    Lista de Diplomas Anulados Criada em: {" "}*/}
                    {/*    {this.state.listaDiplomasAnuladosDataCriacao.toLocaleDateString("pt-BR")}*/}
                    {/*    </Col>*/}
                    {/*    <Col>*/}
                    {/*    Próxima Atualização de Lista de Diplomas Anulados: {" "}*/}
                    {/*    {this.state.listaDiplomasAnuladosDataMaximaProximaAtualizacao.toLocaleDateString("pt-BR")}*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*: null*/}
                    {/*}*/}
                    {/*</Alert>*/}
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default connect((state) => ({
    registros: state.registros.registros,
    loading: state.registros.loading,
    error: state.registros.error,
}))(RegistroConsulta)
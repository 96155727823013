import * as React from 'react'
import * as api from './../../../api/api'
import { Breadcrumb, Card } from 'react-bootstrap'
import {  Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import AcoesRegistro from './AcoesRegistro'
import DiplomadoDados from './DiplomadoDados'
import CursoDados from './CursoDados'
import IESEmissoraDados from './IESEmissoraDados'
import IESRegistradoraDados from './IESRegistradoraDados'
import LivroRegistroDados from './LivroRegistroDados'
import SituacaoRegistro from './SituacaoRegistro'
import Swal from 'sweetalert2'

export class ConsultarDiploma extends React.Component {
    static displayName = ConsultarDiploma.name

    state = {
        registro: [],
        diplomado: [],
        naturalidade: [],
        rg: [],
        dadosCurso: [],
        enderecoCurso: [],
        autorizacaoCurso: [],
        reconhecimentoCurso: [],
        iesEmissora: [],
        iesEmissoraEndereco: [],
        iesEmissoraCredenciamento: [],
        iesEmissoraRecredenciamento: [],
        iesEmissoraMantenedora: [],
        iesEmissoraMantenedoraEndereco: [],
        iesRegistradora: [],
        iesRegistradoraEndereco: [],
        iesRegistradoraCredenciamento: [],
        iesRegistradoraRecredenciamento: [],
        iesRegistradoraMantenedora: [],
        iesRegistradoraMantenedoraEndereco: [],
        livroRegistro: [],
        ingressoCurso: [],
        responsavelRegistro: [],
        registroID: 0,
        isCancelado: true,
        codigoValidacao: 0,
        anotacoes: [],
    }

    componentDidMount() {
        const { codigoValidacao } = this.props.match.params;

        const isCaptchaValid= localStorage.getItem('isCaptchaValid');

        if (!isCaptchaValid) {
            this.RedirectToLocalizarDiploma(codigoValidacao)
        }
        localStorage.removeItem('isCaptchaValid')

        let registroID = 0

        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Carregando dados do diploma.',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        api.getRegistroPorCodigoValidacao(codigoValidacao)
            .then((result) => {
                if (result === null || result === '') {
                    //Swal.fire('Oops', `Inscrição não encontrada.`, 'error')
                    console.log('erro1')
                } else {
                    registroID = result.registro.registroID

                    this.setState({
                        registro: result.registro,
                        diplomado:
                            result.diploma.infDiploma.dadosDiploma.diplomado,
                        naturalidade:
                            result.diploma.infDiploma.dadosDiploma.diplomado
                                .naturalidade,
                        rg: result.diploma.infDiploma.dadosDiploma.diplomado.rg,
                       dadosCurso:
                            result.diploma.infDiploma.dadosDiploma.dadosCurso,
                        enderecoCurso:
                            result.diploma.infDiploma.dadosDiploma.dadosCurso
                                .enderecoCurso,
                        autorizacaoCurso:
                            result.diploma.infDiploma.dadosDiploma.dadosCurso
                                .autorizacao,
                        reconhecimentoCurso:
                            result.diploma.infDiploma.dadosDiploma.dadosCurso
                                .reconhecimento,
                        iesEmissora:
                            result.diploma.infDiploma.dadosDiploma.iesEmissora,
                        iesEmissoraEndereco:
                            result.diploma.infDiploma.dadosDiploma.iesEmissora
                                .endereco,
                        iesEmissoraCredenciamento:
                            result.diploma.infDiploma.dadosDiploma.iesEmissora
                                .credenciamento,
                        iesEmissoraRecredenciamento:
                            result.diploma.infDiploma.dadosDiploma.iesEmissora
                                .recredenciamento,
                        iesEmissoraMantenedora:
                            result.diploma.infDiploma.dadosDiploma.iesEmissora
                                .mantenedora,
                        iesEmissoraMantenedoraEndereco:
                            result.diploma.infDiploma.dadosDiploma.iesEmissora
                                .mantenedora.endereco,
                        iesRegistradora:
                            result.diploma.infDiploma.dadosRegistro
                                .iesRegistradora,
                        iesRegistradoraEndereco:
                            result.diploma.infDiploma.dadosRegistro
                                .iesRegistradora.endereco,
                        iesRegistradoraCredenciamento:
                            result.diploma.infDiploma.dadosRegistro
                                .iesRegistradora.credenciamento,
                        iesRegistradoraRecredenciamento:
                            result.diploma.infDiploma.dadosRegistro
                                .iesRegistradora.recredenciamento,
                        iesRegistradoraMantenedora:
                            result.diploma.infDiploma.dadosRegistro
                                .iesRegistradora.mantenedora,
                        iesRegistradoraMantenedoraEndereco:
                            result.diploma.infDiploma.dadosRegistro
                                .iesRegistradora.mantenedora.endereco,
                        livroRegistro:
                            result.diploma.infDiploma.dadosRegistro
                                .livroRegistro,
                        ingressoCurso:
                            result.documentacaoAcademicaRegistro.registroReq
                                .dadosPrivadosDiplomado.ingressoCurso,
                        responsavelRegistro:
                            result.diploma.infDiploma.dadosRegistro
                                .livroRegistro.responsavelRegistro,
                        registroID: result.registro.registroID,
                        isCancelado: result.registro.cancelado,
                        codigoValidacao: codigoValidacao,
                    })

                    api.getRegistroAnotacoesPorID(registroID)
                        .then((result) => {
                            if (result === null || result === '') {
                                //Swal.fire('Oops', `Inscrição não encontrada.`, 'error')
                                console.log('erro1')
                            } else {
                                this.setState({
                                    anotacoes: result,
                                })
                            }
                            Swal.close()
                        })
                        .catch((ex) =>
                            console.log(
                                `O seguinte erro foi gerado: ` + ex,
                                'error'
                            )
                        ) //Swal.fire('Oops', )
                }
            })
            .catch((ex) =>
                console.log(`O seguinte erro foi gerado: ` + ex, 'error')
            ) //Swal.fire('Oops', )
    }

    RedirectToLocalizarDiploma = (codigoValidacao) => {
        window.location.href = `/localizar/${codigoValidacao}`
    }

    render() {
        function DadosDiplomaAnulado(props) {
            return (
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Diploma - {props.codigoValidacao}</h2>
                        <br />
                        <br />
                        </Card.Title>
                        <SituacaoRegistro
                            isCancelado={props.isCancelado}
                            anotacoes={props.anotacoes}
                        />
                        <br />
                    </Card.Body>
                </Card>
            )
        }

        function DadosCompletosDiploma(props) {
            return (
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Diploma - {props.registro.codigoValidacao}</h2>
                        </Card.Title>
                        <AcoesRegistro registroID={props.registro.registroID} />
                        <br />
                        <SituacaoRegistro
                            isCancelado={props.isCancelado}
                            anotacoes={props.anotacoes}
                        />
                        <br />
                        <DiplomadoDados
                            diplomado={props.diplomado}
                            rg={props.rg}
                            naturalidade={props.naturalidade}
                            mostrarReduzido={false}
                        />
                        <br />
                        <CursoDados
                            dadosCurso={props.dadosCurso}
                            enderecoCurso={props.enderecoCurso}
                            autorizacaoCurso={props.autorizacaoCurso}
                            reconhecimentoCurso={props.reconhecimentoCurso}
                        />
                        <br />
                        <IESEmissoraDados
                            ies={props.iesEmissoraDados}
                            endereco={props.iesEmissoraEndereco}
                            credenciamento={props.iesEmissoraCredenciamento}
                            recredenciamento={props.iesEmissoraRecredenciamento}
                            mantenedora={props.iesEmissoraMantenedora}
                            enderecoMantenedora={
                                props.iesEmissoraMantenedoraEndereco
                            }
                        />
                        <br />
                        <IESRegistradoraDados
                            ies={props.iesRegistradoraDados}
                            endereco={props.iesRegistradoraEndereco}
                            credenciamento={props.iesRegistradoraCredenciamento}
                            recredenciamento={
                                props.iesRegistradoraRecredenciamento
                            }
                            mantenedora={props.iesRegistradoraMantenedora}
                            enderecoMantenedora={
                                props.iesRegistradoraMantenedoraEndereco
                            }
                        />
                        <br />
                        <LivroRegistroDados
                            livroRegistro={props.livroRegistro}
                            responsavelRegistro={props.responsavelRegistro}
                        />
                        <br />
                    </Card.Body>
                </Card>
            )
        }

        function DadosDiploma(props) {
            const isCancelado = props.isCancelado
            if (isCancelado) {
                return (
                    <DadosDiplomaAnulado
                        isCancelado={props.isCancelado}
                        codigoValidacao={props.codigoValidacao}
                        anotacoes={props.anotacoes}
                    />
                )
            } else {
                return (
                    <DadosCompletosDiploma
                        registro={props.registro}
                        isCancelado={props.isCancelado}
                        anotacoes={props.anotacoes}
                        diplomado={props.diplomado}
                        rg={props.rg}
                        naturalidade={props.naturalidade}
                        dadosCurso={props.dadosCurso}
                        enderecoCurso={props.enderecoCurso}
                        autorizacaoCurso={props.autorizacaoCurso}
                        reconhecimentoCurso={props.reconhecimentoCurso}
                        iesEmissoraDados={props.iesEmissoraDados}
                        iesEmissoraEndereco={props.iesEmissoraEndereco}
                        iesEmissoraCredenciamento={
                            props.iesEmissoraCredenciamento
                        }
                        iesEmissoraRecredenciamento={
                            props.iesEmissoraRecredenciamento
                        }
                        iesEmissoraMantenedora={props.iesEmissoraMantenedora}
                        iesEmissoraMantenedoraEndereco={
                            props.iesEmissoraMantenedoraEndereco
                        }
                        iesRegistradoraDados={props.iesRegistradoraDados}
                        iesRegistradoraEndereco={props.iesRegistradoraEndereco}
                        iesRegistradoraCredenciamento={
                            props.iesRegistradoraCredenciamento
                        }
                        iesRegistradoraRecredenciamento={
                            props.iesRegistradoraRecredenciamento
                        }
                        iesRegistradoraMantenedora={
                            props.iesRegistradoraMantenedora
                        }
                        iesRegistradoraMantenedoraEndereco={
                            props.iesRegistradoraMantenedoraEndereco
                        }
                        livroRegistro={props.livroRegistro}
                        responsavelRegistro={props.responsavelRegistro}
                    />
                )
            }
        }

        return (
            <div>
                <Breadcrumb className="arr-right">
                    <Breadcrumb.Item href="./">
                        Consultar Registros por Periodo
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Consulta Diploma por Código de validação
                    </Breadcrumb.Item>
                </Breadcrumb>
                <DadosDiploma
                    isCancelado={this.state.isCancelado}
                    codigoValidacao={this.state.codigoValidacao}
                    anotacoes={this.state.anotacoes}
                    registro={this.state.registro}
                    diplomado={this.state.diplomado}
                    rg={this.state.rg}
                    naturalidade={this.state.naturalidade}
                    dadosCurso={this.state.dadosCurso}
                    enderecoCurso={this.state.enderecoCurso}
                    autorizacaoCurso={this.state.autorizacaoCurso}
                    reconhecimentoCurso={this.state.reconhecimentoCurso}
                    iesEmissoraDados={this.state.iesEmissora}
                    iesEmissoraEndereco={this.state.iesEmissoraEndereco}
                    iesEmissoraCredenciamento={
                        this.state.iesEmissoraCredenciamento
                    }
                    iesEmissoraRecredenciamento={
                        this.state.iesEmissoraRecredenciamento
                    }
                    iesEmissoraMantenedora={this.state.iesEmissoraMantenedora}
                    iesEmissoraMantenedoraEndereco={
                        this.state.iesEmissoraMantenedoraEndereco
                    }
                    iesRegistradoraDados={this.state.iesRegistradora}
                    iesRegistradoraEndereco={this.state.iesRegistradoraEndereco}
                    iesRegistradoraCredenciamento={
                        this.state.iesRegistradoraCredenciamento
                    }
                    iesRegistradoraRecredenciamento={
                        this.state.iesRegistradoraRecredenciamento
                    }
                    iesRegistradoraMantenedora={
                        this.state.iesRegistradoraMantenedora
                    }
                    iesRegistradoraMantenedoraEndereco={
                        this.state.iesRegistradoraMantenedoraEndereco
                    }
                    livroRegistro={this.state.livroRegistro}
                    responsavelRegistro={this.state.responsavelRegistro}
                /><br></br>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col lg={12} className="text-center">
                                <Link className="btn btn-info" to="/ConsultaPublica">
                                Ir para Consulta Pública de Registro de Diplomas
                                </Link>{' '}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default ConsultarDiploma

import * as React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import apiAxios from "./../../../api/apiAxios";
import apiURLs from '../../../api/apiURLs'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Formação ou Curso"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

export const BasicTable = ({ formacoes = [], updateListFormacoes }) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = formacoes.filter((item) =>
        item.formacao.toLowerCase().includes(filterText.toLowerCase())
    )

    function handleButtonClick(id) {
        Swal.fire({
            title: 'Deseja cancelar essa formação de curso?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                const url = apiURLs.GET_CURSO_FORMACOES + id
                apiAxios
                    .delete(url)
                    .then((result) => {
                        Swal.close()
                        Swal.fire('Removido!', '', 'success')
                        updateListFormacoes()
                    })
                    .catch((ex) => {
                        console.log(ex)
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                    })
            } else if (result.isDenied) {
            }
        })
    }

    const columns = [
        {
            name: 'Formação',
            selector: 'formacao',
            sortable: true,
        },
        {
            cell: (row) => (
                <Button
                    variant="danger"
                    onClick={() => handleButtonClick(row.cursoFormacaoID)}
                >
                    <i className="fas fa-trash-alt"></i>
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Formações de Curso"
            columns={columns}
            keyField="text"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            //subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhuma formação de curso encontrada."
            paginationComponentOptions={{
                rowsPerPageText: 'Formações por página:',
            }}
            persistTableHead
            Clicked
            className="titulacao-table-head"
        />
    )
}

export default BasicTable

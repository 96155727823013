import 'bootstrap/dist/css/bootstrap.css'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
//import { BrowserRouter } from 'react-router-dom'
//import { createBrowserHistory } from 'history'
import { configureStore } from './store'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'

const rootElement = document.getElementById('root')

const store = configureStore()
const persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
            <App />
        </PersistGate>
    </Provider>,
    rootElement
)

registerServiceWorker()
import ActionTypes from './actionTypes'

const initialState = {
    curso: {},
    cursosGraus: [],
    cursoFormacoes: [],
    cursoTitulacoes: [],
    cursoReconhecimentos: [],
    loading: false,
    error: null,
}

export default function cursoReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.LOAD_CURSO_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_CURSO_SUCCESS: {
            return {
                ...state,
                loading: false,
                curso: payload.curso,
            }
        }
        case ActionTypes.LOAD_CURSO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                curso: {},
            }
        }
        case ActionTypes.LOAD_CURSOS_GRAUS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_CURSOS_GRAUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                cursosGraus: payload.cursosGraus,
            }
        }
        case ActionTypes.LOAD_CURSOS_GRAUS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                graus: [],
            }
        }
        case ActionTypes.LOAD_CURSO_FORMACOES_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_CURSO_FORMACOES_SUCCESS: {
            return {
                ...state,
                loading: false,
                cursoFormacoes: payload.cursoFormacoes,
            }
        }
        case ActionTypes.LOAD_CURSO_FORMACOES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                cursoFormacoes: [],
            }
        }
        case ActionTypes.LOAD_CURSO_TITULACOES_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_CURSO_TITULACOES_SUCCESS: {
            return {
                ...state,
                loading: false,
                cursoTitulacoes: payload.cursoTitulacoes,
            }
        }
        case ActionTypes.LOAD_CURSO_TITULACOES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                cursoTitulacoes: [],
            }
        }
        case ActionTypes.LOAD_CURSO_RECONHECIMENTOS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_CURSO_RECONHECIMENTOS_SUCCESS: {
            return {
                ...state,
                loading: false,
                cursoReconhecimentos: payload.cursoReconhecimentos,
            }
        }
        case ActionTypes.LOAD_CURSO_RECONHECIMENTOS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                cursoReconhecimentos: [],
            }
        }
        default:
            return state
    }
}

import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addNewLivroRequest } from '../../../store/thunks'
import * as api from './../../../api/api'
import Swal from 'sweetalert2'
import { Formik, Field, ErrorMessage } from 'formik'
import apiAxios from "./../../../api/apiAxios";

const options = {
    headers: { 'content-type': 'application/json' },
}
class LivroRegistroForm extends React.Component {
    static displayName = LivroRegistroForm.name
    constructor(props) {
        super(props)
        this.state = {
            Nome: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    RedirectToConsultLivros = () => {
        window.location.href = '/livros'
    }

    salvarLivro = () => {
        try {
            Swal.fire({
                showCancelButton: false,
                showConfirmButton: false,
                title: 'Por favor aguarde!',
                html: 'Livro de Registro está sendo salvo.',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            const novoLivro = JSON.stringify({
                Nome: this.state.Nome,
            })
            let url = '/api/livro'
            apiAxios
                .post(url, novoLivro, options)
                .then((resp) => {
                    Swal.close()

                    Swal.fire({
                        icon: 'success',
                        title:
                            'Livro de Registro cadastrado com sucesso! Redirecionando...',
                        showConfirmButton: false,
                        timer: 4000,
                    }).then(this.RedirectToConsultLivros())
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        if (error.response.status !== 200) {
                            Swal.close()
                            Swal.fire({
                                icon: 'error',
                                title: 'Erro ao cadastrar livro de registro!',
                                html:
                                    'Verifique se todos os campos obrigatórios foram preenchidos e tente novamente. ' +
                                    'Se erro persistir entre em contato com o administrador do sistema.' +
                                    '<br></br>' +
                                    error //payload
                                        ? error//payload
                                        : '',
                            })
                        }
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                        console.log(error.request)
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                        console.log('Error', error.message)
                    }
                })
        } catch (e) {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao processar ação!',
                html:
                    'Tente novamente. ' +
                    'Se erro persistir entre em contato com o administrador do sistema.',
            })
            console.log(e)
        }
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return JSON.stringify(obj) === JSON.stringify({})
    }

    render() {
        return (
            <Formik
                initialValues={{
                    Nome: this.state.Nome,
                }}
                validate={(values) => {
                    let errors = {}
                    if (this.state.Nome === '') {
                        errors.Nome = 'Nome é obrigatório'
                    }
                    if (!this.isEmpty(errors)) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text:
                                'Existem campos obrigatórios não preenchidos!',
                        })
                    }
                    return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.salvarLivro()
                }}
            >
                {({ touched, errors, isSubmitting, handleSubmit }) => (
                    <Form
                        name="novoLivroForm"
                        id="novoLivroForm"
                        onSubmit={handleSubmit}
                    >
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={10}
                                ld={10}
                                controlId="formGridNome"
                            >
                                <Form.Label>Nome</Form.Label>
                                <Field
                                    type="text"
                                    placeholder="Ex.: MED/2019"
                                    maxLength="100"
                                    title="Nome"
                                    name="Nome"
                                    id="Nome"
                                    value={this.state.Nome}
                                    onChange={this.handleInputChange}
                                    className={`form-control ${
                                        touched.Nome && errors.Nome
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    required
                                />
                                <ErrorMessage
                                    component="div"
                                    name="Nome"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Link className="btn btn-primary" to="/livros">
                            Voltar
                        </Link>{' '}
                        <Button variant="success" type="submit">
                            Cadastrar
                        </Button>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default connect()(LivroRegistroForm)

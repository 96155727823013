import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { revogaCancelamentoRegistroRequest } from '../../../store/thunks'
import { format } from 'date-fns'

function FormatDate(props) {
    if (props.data != null) {
        let dateTimeArray = props.data.split('T')
        let dataArray = dateTimeArray[0].split('-')
        return (
            <span>
                {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
            </span>
        )
    } else {
        return <span></span>
    }
}

const RevogaCancelamentoRegistroForm = ({
    registroId,
    estudante,
    dataRegistro,
    responsavelRegistro,
    //onCreatePressed,
    handleSubmit,
}) => {
    const history = useHistory()
    const [inputValue, setInputValue] = React.useState('')

    const handleRedirect = () => {
        history.push('/registros')
    }
    return (
        <Form name="revogaCancelamentoRegistroForm">
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Estudante: </Form.Label> <br></br>
                    {estudante}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Data de Registro: </Form.Label> <br></br>
                    <FormatDate data={dataRegistro} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridJustificativa">
                    <Form.Label>Justificativa</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        title="justificativa"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="form-control"
                        required
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Responsável pelo Registro: </Form.Label>{' '}
                    <br></br>
                    Nome: {responsavelRegistro.nome} <br></br>
                    Matrícula (SIAPE): {
                        responsavelRegistro.iDouNumeroMatricula
                    }{' '}
                    <br></br>
                    CPF: {responsavelRegistro.cpf}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Responsável por Revogar Cancelamento:{' '}
                    </Form.Label>{' '}
                    <br></br>
                    Nome: {sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome')} <br></br>
                    Matrícula (SIAPE): {sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula')}{' '}
                    <br></br>
                    CPF: {sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF')}
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <small>
                        Nota Técnica No. 13/2019/DIFES/SESU/SESU Versão 1.0{' '}
                        <br />
                        7.12.3 A anulação do diploma digital pode ocorrer nos
                        casos de erros de fato e erros de ofício, sendo o
                        processo de anulação diferente em cada caso.
                        <br />
                        7.12.5 Nos casos de erro de ofício, o diploma deve ser
                        anulado de forma irreversível através da sua indicação
                        como inativo, indicando na URL única do diploma o motivo
                        de sua revogação assim como a data e hora em que a
                        anulação foi feita.
                        <br />
                        7.12.6 Nos casos de erro de fato, o diploma pode ser
                        anulado de forma temporária, indicando na URL única do
                        diploma o motivo de sua revogação assim como a data e
                        hora em que a anulação foi feita. Caso o fato cesse ou
                        seja suspenso, o status do diploma poderá voltar a ser
                        ativo, mas a URL única do diploma deve manter as
                        anotações relativas ao período em que o diploma se
                        encontra suspenso.
                    </small>
                </Form.Group>
            </Form.Row>
            <Link className="btn btn-primary" to="/registros">
                Voltar
            </Link>{' '}
            <Button
                variant="success"
                type="button"
                onClick={() => {
                    if (inputValue) {
                        handleSubmit( registroId,
                                inputValue,
                                sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome'),
                                sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula'));
                        // onCreatePressed(
                        //     registroId,
                        //     inputValue,
                        //     sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome'),
                        //     sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula')
                        // )
                        // setInputValue('')
                        // handleRedirect()
                    }
                }}
            >
                Revogar
            </Button>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    anotacoes: state.registros.anotacoes,
    loading: state.registros.loading,
    error: state.registros.error,
})

const mapDispatchToProps = (dispatch) => ({
    onCreatePressed: (
        registroId,
        justificativa,
        responsavel,
        responsavelDocumento
    ) =>
        dispatch(
            revogaCancelamentoRegistroRequest(
                registroId,
                justificativa,
                responsavel,
                responsavelDocumento
            )
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RevogaCancelamentoRegistroForm)

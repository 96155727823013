import React from 'react';
import { Col, Form, Card, Button } from 'react-bootstrap';
import { Field } from 'formik';

const RegistroAssinaturas = ({
    currentStep,
    setAssinanteDadosRegistro,
    assinaturaDadosRegistro,
    updateAssinanteDadosRegistro,
    removeAssinanteDadosRegistro,
    setAssinanteDiploma,
    assinaturasDiploma,
    updateAssinanteDiploma,
    removeAssinanteDiploma
}) => {

    if (currentStep !== 6) {
        return null;
    }
    return (
        <Card>
            <Card.Header>
                <h3>{currentStep} - Assinaturas</h3>
            </Card.Header>
            <Card.Body>
                <div className="d-flex mb-3">
                    <h5>Dados de Registro</h5>
                    <div className="ms-auto">
                        <Button className="btn btn-info align-self-end" type="button" onClick={setAssinanteDadosRegistro}>Incluir</Button>
                    </div>
                </div>
                {assinaturaDadosRegistro ? <>
                    <Form.Row>
                        <Form.Group as={Col} sm={12}>
                            {assinaturaDadosRegistro.length > 0 ? <>
                                {assinaturaDadosRegistro.map((a, index) => {
                                    return <AssinaturaForm
                                        key={index} {...a}
                                        i={index}
                                        updateAssinante={updateAssinanteDadosRegistro}
                                        removeAssinante={removeAssinanteDadosRegistro} />
                                })}
                            </> : <></>}
                        </Form.Group>
                    </Form.Row>
                </> : <></>}
                <hr />
                <div className="d-flex mb-3">
                    <h5>Diploma</h5>
                    <div className="ms-auto">
                        <Button className="btn btn-info" type="button" onClick={setAssinanteDiploma}>Incluir</Button>
                    </div>
                </div>
                {assinaturasDiploma ? <>
                    <Form.Row>
                        <Form.Group as={Col} sm={12}>
                            {assinaturasDiploma.length > 0 ? <>
                                {assinaturasDiploma.map((a, index) => {
                                    return <AssinaturaForm
                                        key={index} {...a}
                                        i={index}
                                        updateAssinante={updateAssinanteDiploma}
                                        removeAssinante={removeAssinanteDiploma} />
                                })}
                            </> : <></>}
                        </Form.Group>
                    </Form.Row>
                </> : <></>}
            </Card.Body>
        </Card>
    )
}

export default RegistroAssinaturas;

const AssinaturaForm = ({
    i,
    updateAssinante,
    removeAssinante,
    nome,
    cpf,
    cargo,
}) => {

    return (
        <Form.Row style={{ marginBottom: 10, padding: 10, borderRadius: 15, borderStyle: 'solid', borderWidth: 1, borderColor: '#ccc' }}>
            <Form.Group as={Col} sm={12} md={8}>
                <Form.Label>Nome Completo:</Form.Label>
                <Field
                    type="text"
                    name="nome"
                    title="Nome completo"
                    placeholder="Ex.: José da Silva"
                    maxLength="255"
                    className={`form-control`}
                    onChange={(e) => {
                        updateAssinante("nome", e.target.value ?? '', i);
                    }}
                    value={nome}
                />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={4}>
                <Form.Label>CPF:</Form.Label>
                <Field
                    type="text"
                    title="CPF"
                    name="cpf"
                    placeholder="99999999999"
                    maxLength="255"
                    className={`form-control`}
                    onChange={(e) => {
                        updateAssinante("cpf", e.target.value ?? '', i);
                        console.log(e.target.value, i);
                    }}
                    value={cpf}
                />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8}>
                <Form.Label>Cargo:</Form.Label>
                <Field
                    type="text"
                    title="Cargo"
                    name="cargo"
                    placeholder="Ex.: Reitor"
                    maxLength="255"
                    className={`form-control`}
                    onChange={(e) => {
                        updateAssinante("cargo", e.target.value ?? '', i);
                        console.log(e.target.value, i);
                    }}
                    value={cargo}
                />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={4}>
                <br />
                <Button className="btn btn-danger" type="button" onClick={() => removeAssinante(i)}>Remover</Button>
            </Form.Group>
        </Form.Row>
    )
}
import * as React from 'react'
import { Card, Breadcrumb, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import apiAxios from './../../api/apiAxios';
import ReconhecimentoDetalhe from '../../components/Area/CAE/ReconhecimentoDetalhe'
import Swal from 'sweetalert2'
import apiURLs from './../../api/apiURLs';
import { IrPortal } from "../../util/funcoes";

export default class ReconhecimentoExclui extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            atoRegulatorioUid: 0,
            atoRegulatorio: {} 
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        this.handleExcluir = this.handleExcluir.bind(this)
    }

    componentDidMount() {
        if(this.props.match.params.id != undefined){
            this.setAtoRegulatorio(); 
        }
    }
    
    setAtoRegulatorio() {
       // const url = `https://localhost:5001/api/reconhecimento/ato-regulatorio/dados/${this.props.match.params.id}`;
        const url = apiURLs.GET_DADOS_ATO_REGULATORIO + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                console.log( result.data)
                this.setState({ 
                    atoRegulatorio: result.data,
                    atoRegulatorioUid: result.data.atoRegulatorioUid
                })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }    
    
    RedirectToConsultaReconhecimentos = () => {
        window.location.href = '/reconhecimentos'
    }

    handleExcluir(event) {
        Swal.fire({
            title: 'Deseja cancelar Ato Regulatorio de Reconhecimento de Curso(s)?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
               // const url = `https://localhost:5001/api/reconhecimento/ato-regulatorio/cancela/${this.state.atoRegulatorioUid}`;
                const url = apiURLs.CANCELA_ATO_REGULATORIO + this.props.match.params.id;
                apiAxios
                    .delete(url)
                    .then((result) => {
                        Swal.close()
                        Swal.fire('Cancelado com sucesso!', '', 'success')
                        this.RedirectToConsultaReconhecimentos();
                    })
                    .catch((ex) => {
                        console.log(ex)
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                    })
            } else if (result.isDenied) {
                //Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./reconhecimentos">
                                Consulta de Atos Regulatórios de Reconhecimento de Curso(s)
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Excluir Ato Regulatório de Reconhecimento de Curso(s)
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>
                            Excluir Ato Regulatório de Reconhecimento de Curso(s)
                            </h2>
                        </Card.Title>                       
                        
                    <ReconhecimentoDetalhe
                            atoRegulatorio={this.state.atoRegulatorio}
                        />
                        <Link className="btn btn-primary" to="/reconhecimentos">
                            Voltar
                        </Link>{' '}
                        <Button
                            variant="danger"
                            type="button"
                            onClick={this.handleExcluir}
                        >
                            Excluir
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

import * as React from 'react'
import { Col, Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { Formik, Field, ErrorMessage } from 'formik'
import apiAxios from "./../../../api/apiAxios";
import apiURLs from './../../../api/apiURLs'
import { Option } from './Option';
import { default as ReactSelect } from "react-select";

const options = {
    headers: { 'content-type': 'application/json' },
}
class NovoReconhecimentoCursoForm extends React.Component {
    static displayName = NovoReconhecimentoCursoForm.name
    constructor(props) {
        super(props)
        this.state = {
            CursoID: [],
            ProcessoReconhecimento: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        let regexpProcess = /^[0-9 -/.\b]+$/ //só numero e - e . e /

        if (name === 'Processo') {
            if (regexpProcess.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        }else {
            this.setState({
                [name]: value,
            })
        }
    }

    updateListReconhecimentoCursos = () => {
        this.props.updateListReconhecimentoCursos()
    }

    salvarReconhecimentoCurso = (cursosIds) => {
        try {
            
            let errors = {}
            // if (this.state.CursoID === '' || this.state.CursoID == '0') {
            //     errors.TipoAtoRegulatorioUid = 'Curso é obrigatório'
            // }
            
            if (cursosIds.length == 0) {
                errors.CursoID = 'Curso é obrigatório'
            }
            if (!this.isEmpty(errors)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text:
                        'Existem campos obrigatórios não preenchidos!',
                })
                return;
            }

            Swal.fire({
                showCancelButton: false,
                showConfirmButton: false,
                title: 'Por favor aguarde!',
                html: 'Curso(s) está sendo vinculado.',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            const novoReconhecimentoCurso = JSON.stringify({
                AtoRegulatorioUid: Number(this.props.AtoRegulatorioUid),
                ProcessoReconhecimento: this.state.ProcessoReconhecimento,
                CursosUid: cursosIds,
            })
            
            //const url = `https://localhost:5001/api/reconhecimento/novo-vinculo-curso`;

            apiAxios.post(apiURLs.NOVO_VINCULO_RECONHECIMENTO_CURSO_EM_LOTE, novoReconhecimentoCurso, options)
                .then((resp) => {
                    Swal.close()

                    Swal.fire({
                        icon: 'success',
                        title: 'Curso(s) vinculado com sucesso!',
                        showConfirmButton: false,
                        timer: 4000,
                    })
                    this.updateListReconhecimentoCursos()
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status !== 200) {
                            Swal.close()
                            Swal.fire({
                                icon: 'error',
                                title: 'Erro ao vincular curso(s)!',
                                html:
                                    'Verifique se todos os campos obrigatórios foram preenchidos e tente novamente. ' +
                                    'Se erro persistir entre em contato com o administrador do sistema.',
                            })
                        }
                    } else if (error.request) {
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                        console.log(error.request)
                    } else {
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao processar ação!',
                            html:
                                'Tente novamente. ' +
                                'Se erro persistir entre em contato com o administrador do sistema.',
                        })
                        console.log('Error', error.message)
                    }
                })
        } catch (e) {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Erro ao processar ação!',
                html:
                    'Tente novamente. ' +
                    'Se erro persistir entre em contato com o administrador do sistema.',
            })
            console.log(e)
        }
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return JSON.stringify(obj) === JSON.stringify({})
    }

    render() {

        var listOfObjects = [];
        var listOfGroupedObjects = [];
        
        this.props.cursos.forEach(element => {
            var singleObj = {};
            singleObj['label'] = element.codigoInterno + ' - '+ element.cursoMec.campus.sigla +' - '+element.cursoMec.nome+' - '+element.cursoMec.grau.descricao;
            singleObj['value'] = element.cursoID;
            listOfObjects.push(singleObj);

            let hasGroup = false;

            listOfGroupedObjects.forEach(function(obj) {
                if (obj.label === element.cursoMec.campus.nome && obj.options[0].value != element.cursoID) {
                    var newOptions = obj.options;
                    newOptions.push(singleObj);
                    obj.options = newOptions;
                    hasGroup = true;
                }
            });

            if(!hasGroup){
                var groupedObj = {};
                groupedObj['label'] = element.cursoMec.campus.nome;
                groupedObj['options'] = [];
                groupedObj['options'].push(singleObj);
                listOfGroupedObjects.push(groupedObj);
            }
        });

        return (
            <Formik
                initialValues={{
                    CursoID: this.state.CursoID,
                    ProcessoReconhecimento: this.state.ProcessoReconhecimento,
                }}
                validate={(values) => {
                    let errors = {}
                    if (this.state.CursoID === '') {
                        errors.CursoID =
                            'CursoID é obrigatório'
                    }
                    if (!this.isEmpty(errors)) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text:
                                'Existem campos obrigatórios não preenchidos!',
                        })
                    }
                    return errors
                }}
                onSubmit={(values, { resetForm  }) => {
                    let cursosIds = values.CursoID.map(a => a.value);
                    this.salvarReconhecimentoCurso(cursosIds)
                    this.setState({
                        CursoID: null,
                        Habilitacao: '',
                        ProcessoReconhecimento: ''
                    })
                    resetForm();
                }}
            >
            {({ touched, errors, isSubmitting, handleSubmit, setFieldValue, values }) => (
                    <Form
                        name="novoReconhecimentoCursoForm"
                        id="novoReconhecimentoCursoForm"
                        onSubmit={handleSubmit}
                    >
                        <Form.Row>
                            {/* <Form.Group
                                as={Col}
                                sm={12}
                                md={6}
                                ld={6}
                                controlId="formGridNome"
                            >
                                <Form.Label>
                                    Curso
                                </Form.Label>
                                <Field
                                as="select"
                                title="Curso"
                                id="CursoID"
                                name="CursoID"
                                className={`form-control ${
                                    touched.CursoID &&
                                    errors.CursoID
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                onChange={this.handleInputChange}
                                value={this.state.CursoID}
                                required
                            >
                            <option
                            value=""
                    >
                        Selecione...
                    </option>
                                {this.props.cursos.map((option, index) => (
                                    <option
                                        key={option.cursoID}
                                        value={option.cursoID}
                                    >
                                        {option.codigoInterno + ' - '+ option.cursoMec.campus.sigla +' - '+option.cursoMec.nome+' - '+option.cursoMec.grau.descricao}
                                    </option>
                                ))}
                            </Field>
                                <ErrorMessage
                                    component="div"
                                    name="CursoID"
                                    className="invalid-feedback"
                                />
                            </Form.Group>  */}
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={6}
                                ld={6}
                                controlId="formGridNome"
                            >
                            <Form.Label>
                                Curso(s):
                            </Form.Label>
                                <ReactSelect
                                    options={listOfGroupedObjects}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                    Option
                                    }}
                                    id="CursoID"
                                    name="CursoID"
                                    value={values.CursoID}
                                    onChange={option => setFieldValue("CursoID", option)}
                                    allowSelectAll={true}
                                />
                            </Form.Group> 
                                    <Form.Group
                                        as={Col}
                                        sm={12}
                                        md={3}
                                        ld={3}
                                        controlId="formGridNome"
                                    >
                                        <Form.Label>Processo de Reconhecimento:</Form.Label>
                                        <Field
                                            type="text"
                                            placeholder="Ex.: 99999.999999/9999-99"
                                            maxLength="50"
                                            title="Processo de Reconhecimento"
                                            name="ProcessoReconhecimento"
                                            id="ProcessoReconhecimento"
                                            value={this.state.ProcessoReconhecimento}
                                            onChange={this.handleInputChange}
                                            className={`form-control ${
                                                touched.ProcessoReconhecimento && errors.ProcessoReconhecimento
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="ProcessoReconhecimento"
                                            className="invalid-feedback"
                                        />
                                    </Form.Group>                           
                            <Form.Group
                                as={Col}
                                sm={12}
                                md={3}
                                ld={3}
                                controlId="formGridNome"
                            >
                                <Button
                                    variant="success"
                                    type="submit"
                                    className="btn-titulacao"
                                >
                                    Cadastrar
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default connect()(NovoReconhecimentoCursoForm)

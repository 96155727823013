import * as React from 'react'
import { Col, Form, Card } from 'react-bootstrap'
import { Field, ErrorMessage } from 'formik'

class RegistroNovoCamposFiliacao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        if (this.props.currentStep !== 1) {
            return null
        }
        return (
            <Card>
                <Card.Header>
                    <h3>Filiação</h3>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <Form.Group as={Col} sm={12} md={12} lg={8}>
                            <Form.Label>Nome completo da Mãe:</Form.Label>
                            <Field
                                type="text"
                                title="Nome completo da Mãe"
                                name="NomeMae"
                                id="NomeMae"
                                placeholder="Ex.: Maria da Silva"
                                maxLength="255"
                                min="2"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${
                                    this.props.touched.NomeMae &&
                                    this.props.errors.NomeMae
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                value={this.props.NomeMae}
                                required
                            />
                            <ErrorMessage
                                component="div"
                                name="NomeMae"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                        <div className="mb-3 col-auto">
                            <Form.Label>Sexo</Form.Label>
                            <Form.Control
                                as="select"
                                name="SexoMae"
                                id="SexoMae"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${
                                    this.props.touched.SexoMae &&
                                    this.props.errors.SexoMae
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                value={this.props.SexoMae}
                                required
                            >
                                <option value="F">Feminino</option>
                                <option value="M">Masculino</option>
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="SexoMae"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm={12} md={12} lg={8}>
                            <Form.Label>Nome completo do Pai:</Form.Label>
                            <Field
                                type="text"
                                title="Nome completo do Pai"
                                name="NomePai"
                                id="NomePai"
                                placeholder="Ex.: José da Silva"
                                maxLength="255"
                                //min="2"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${
                                    this.props.touched.NomePai &&
                                    this.props.errors.NomePai
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                value={this.props.NomePai}
                                //required
                            />
                            <ErrorMessage
                                component="div"
                                name="NomePai"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                        <div className="mb-3 col-auto">
                            <Form.Label>Sexo</Form.Label>
                            <Form.Control
                                as="select"
                                name="SexoPai"
                                id="SexoPai"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${
                                    this.props.touched.SexoPai &&
                                    this.props.errors.SexoPai
                                        ? 'is-invalid'
                                        : ''
                                }`}
                                value={this.props.SexoPai}
                               // required
                            >
                                <option value="M">Masculino</option>
                                <option value="F">Feminino</option>
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="SexoPai"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default RegistroNovoCamposFiliacao
import * as React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

export class LivroRegistroDetalhe extends React.Component {
    render() {
        return (
            <Card id="livroRegistro">
                <Card.Body>
                    <Row>
                        <Col lg={4} className="text-right">
                            Nº:
                        </Col>
                        <Col lg={8} className="text-left">
                            {this.props.livroRegistro.livroId}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} className="text-right">
                            Nome:
                        </Col>
                        <Col lg={8} className="text-left">
                            {this.props.livroRegistro.nome}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}

export default LivroRegistroDetalhe

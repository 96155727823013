import React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TextField = styled.input`
    height: 32px;
    width: 290px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Formando, RGA ou Curso"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

const ButtonRegistroDisponivel = (props) => {
    return (
        <Link className="btn btn-success" to={`/novo-registro/${props.rga}`}>
            Registrar
        </Link>
    )
}

const columns = [
    {
        name: 'Formando',
        selector: 'sigaNomeALuno',
        sortable: true,
    },
    {
        name: 'RGA',
        selector: 'sigaRGAAluno',
        sortable: true,
        maxWidth: '10%',
    },
    {
        name: 'Curso',
        selector: 'sigaNomePCurso',
        sortable: true,
        maxWidth: '35%',
    },
    {
        name: 'Pendências',
        cell: (props) =>
            props.historico?.length > 0 ?
                <span >{props.historico.length} pendências</span> :
                <span>Livre de pendências</span>,
        sortable: false,
        maxWidth: '6%',
    },
    {
        cell: (props) =>
            props.registroDiplomaAtivo ? <Button variant="success" disabled>Já registrado</Button> : <ButtonRegistroDisponivel rga={props.sigaRGAAluno} />
        ,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        maxWidth: '154px',
    },
]

const BasicTable = (props) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = props.estudantes.filter(
        (item) =>
            (item.sigaNomeALuno &&
                item.sigaNomeALuno
                    .toLowerCase()
                    .includes(filterText.toLowerCase())) ||
            (item.sigaNomePCurso &&
                item.sigaNomePCurso
                    .toLowerCase()
                    .includes(filterText.toLowerCase())) ||
            (item.sigaRGAAluno &&
                String(item.sigaRGAAluno).includes(filterText.toLowerCase()))
    )

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Consulta de Estudantes Formandos"
            columns={columns}
            keyField="rga"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhum estudante encontrado."
            paginationComponentOptions={{ rowsPerPageText: 'Estudantes por página:' }}
            persistTableHead
            Clicked
        />
    )
}

export default BasicTable
import * as React from 'react'
import {Col, Form, Card, Row} from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import titleCase from './../titleCase'
import { Field, ErrorMessage } from 'formik'
import ptBR from 'date-fns/locale/pt-BR';
registerLocale("ptBR", ptBR);


const DocumentoIdentificacaoEstudante = (props) => {
    if (props.Nacionalidade) {
        if (props.Nacionalidade.toUpperCase() === 'BRASILEIRO' || props.Nacionalidade.toUpperCase() === 'BRASILEIRA') {
            return (
                <>            
                <Row>            
                    <Form.Group as={Col} sm={12} md={6} lg={4}>
                        <Form.Label>
                            Documento de Identificação - Número:
                        </Form.Label>
                        <Field
                            type="text"
                            title="Documento de Identificação"
                            name="RGNumero"
                            id="RGNumero"
                            placeholder="Ex.: 1010105"
                            onChange={props.handleChange}
                            value={props.RGNumero}                            
                            maxLength="15"
                            min="4"
                            className={`form-control ${
                                props.touched.RGNumero &&
                                props.errors.RGNumero
                                    ? 'is-invalid'
                                    : ''
                            }`}
                        />
                        <ErrorMessage
                            component="div"
                            name="RGNumero"
                            className="invalid-feedback"
                        />
                    </Form.Group>
                    <div className="mb-3 col-auto">
                        <Form.Label>
                            Documento de Identificação - UF:{' '}
                        </Form.Label>
                        <Field
                            as="select"
                            title="Documento de Identificação - UF"
                            id="RGUF"
                            name="RGUF"
                            className={`form-select ${
                                props.touched.RGUF &&
                                props.errors.RGUF
                                    ? 'is-invalid'
                                    : ''
                            }`}
                            onChange={props.handleChange}
                            value={props.RGUF}
                        >
                            {props.ufs.map((option, index) => (
                                <option
                                    key={option.ufuid}
                                    value={option.ufuid}
                                >
                                    {titleCase(option.nome)}
                                </option>
                            ))}
                        </Field>
                        <ErrorMessage
                            component="div"
                            name="RGUF"
                            className="invalid-feedback"
                        />
                    </div>
                    <Form.Group as={Col} sm={12} md={6} lg={4}>
                        <Form.Label>
                            Documento de Identificação - Órgão expedidor
                        </Form.Label>{' '}
                        <br></br>
                        <Field
                            type="text"
                            title="Documento de Identificação - Órgão expedidor"
                            name="RGOrgaoExpedidor"
                            id="RGOrgaoExpedidor"
                            placeholder="Ex.: SSP"
                            onChange={props.handleChange}
                            value={props.RGOrgaoExpedidor}
                            maxLength="255"
                            min="2"
                            className={`form-control ${
                                props.touched.RGOrgaoExpedidor &&
                                props.errors.RGOrgaoExpedidor
                                    ? 'is-invalid'
                                    : ''
                            }`}
                        />
                        <ErrorMessage
                            component="div"
                            name="RGOrgaoExpedidor"
                            className="invalid-feedback"
                        />
                    </Form.Group>  
                </Row>    
            </>
            )
        } else {
            return(
            <>     
                <Row> 
                    <Form.Group as={Col} sm={12} md={6} lg={6}>
                        <Form.Label>
                            Documento de Identificação - Identificador:
                        </Form.Label>
                        <Field
                            type="text"
                            title="Documento de Identificação - Identificador"
                            name="OutroDocumentoIdentificacaoIdentificador"
                            id="OutroDocumentoIdentificacaoIdentificador"
                            placeholder="Ex.: 1010105"
                            onChange={props.handleChange}
                            value={props.OutroDocumentoIdentificacaoIdentificador}
                            maxLength="255"
                            min="2"
                            className={`form-control ${
                                props.touched.OutroDocumentoIdentificacaoIdentificador &&
                                props.errors.OutroDocumentoIdentificacaoIdentificador
                                    ? 'is-invalid'
                                    : ''
                            }`}
                        />
                        <ErrorMessage
                            component="div"
                            name="OutroDocumentoIdentificacaoIdentificador"
                            className="invalid-feedback"
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6} lg={6}>
                        <Form.Label>
                            Documento de Identificação - Tipo de Documento
                        </Form.Label>{' '}
                        <br></br>
                        <Field
                            type="text"
                            title="Documento de Identificação - Tipo de Documento"
                            name="OutroDocumentoIdentificacaoTipoDocumento"
                            id="OutroDocumentoIdentificacaoTipoDocumento"
                            placeholder="Ex.: Passaporte"
                            onChange={props.handleChange}
                            value={props.OutroDocumentoIdentificacaoTipoDocumento}
                            maxLength="255"
                            min="2"
                            className={`form-control ${
                                props.touched.OutroDocumentoIdentificacaoTipoDocumento &&
                                props.errors.OutroDocumentoIdentificacaoTipoDocumento
                                    ? 'is-invalid'
                                    : ''
                            }`}
                        />
                        <ErrorMessage
                            component="div"
                            name="OutroDocumentoIdentificacaoTipoDocumento"
                            className="invalid-feedback"
                        />
                    </Form.Group>
                </Row>
            </>
            )
        }
    } else {
        return <></>
    }
}

const NaturalidadeEstudante = (props) => {
    if (props.Nacionalidade) {
        if (props.Nacionalidade.toUpperCase() === 'BRASILEIRO' || props.Nacionalidade.toUpperCase() === 'BRASILEIRA') {
            return (
                <>
                    <div className="mb-3 col-auto">
                        <Form.Label>Naturalidade - UF:</Form.Label>
                        <Form.Control
                            as="select"
                            id="NaturalidadeUF"
                            name="NaturalidadeUF"
                            className={`form-select ${props.touched.NaturalidadeUF && props.errors.NaturalidadeUF ? 'is-invalid' : ''}`}
                            onChange={props.handleChange}
                            value={props.NaturalidadeUF}>
                            {props.ufs.map((option, index) => (
                                <option key={option.ufuid} value={option.ufuid}>
                                    {titleCase(option.nome)}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorMessage
                            component="div"
                            name="NaturalidadeUF"
                            className="invalid-feedback"
                        />
                    </div>
                    <div className="mb-3 col-auto">
                        <Form.Label>Naturalidade - Município:</Form.Label>
                        <Form.Control
                            as="select"
                            id="NaturalidadeCodigoMunicipio"
                            name="NaturalidadeCodigoMunicipio"
                            className={`form-select ${props.touched.NaturalidadeUF && props.errors.NaturalidadeUF ? 'is-invalid' : ''}`}
                            onChange={props.handleChange}
                            value={props.NaturalidadeCodigoMunicipio}>
                            {Array.isArray(props.municipios) && props.municipios.map((option, index) => (
                                <option
                                    key={option.municipioUID}
                                    value={option.municipioUID}>
                                    {option.nome}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorMessage
                            component="div"
                            name="NaturalidadeCodigoMunicipio"
                            className="invalid-feedback"
                        />
                    </div>
                </>
            )
        } else {
            return (
            <>
                <Form.Group as={Col} sm={12} md={6} lg={6}>
                    <Form.Label>
                        Naturalidade - Município Estrangeiro
                    </Form.Label>{' '}
                    <Field
                        type="text"
                        title="Naturalidade - Município Estrangeiro"
                        name="NomeMunicipioEstrangeiro"
                        id="NomeMunicipioEstrangeiro"
                        placeholder="Ex.: Santiago"
                        onChange={props.handleChange}
                        value={props.NomeMunicipioEstrangeiro}
                        maxLength="255"
                        min="2"
                        className={`form-control ${
                            props.touched.NomeMunicipioEstrangeiro &&
                            props.errors.NomeMunicipioEstrangeiro
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        component="div"
                        name="NomeMunicipioEstrangeiro"
                        className="invalid-feedback"
                    />
                </Form.Group>
            </>)
        }
    } else {
        return (<></>)
    }
}

class RegistroNovoCamposDiplomado extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        if (this.props.currentStep !== 1) {
            return null
        }
        return (
            <Card className="mb-3">
                <Card.Header>
                    <h3>{this.props.currentStep} - Dados do Diplomado</h3>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="mb-3 col">
                            <Form.Label>Nome</Form.Label>
                            <Field
                                type="text"
                                title="Nome Completo"
                                name="Nome"
                                id="Nome"
                                placeholder="Ex.: José da Silva"
                                maxLength="255"
                                min="2"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${this.props.touched.Nome && this.props.errors.Nome ? 'is-invalid' : ''}`}
                                required
                                value={this.props.Nome}
                            />
                            <ErrorMessage
                                component="div"
                                name="Nome"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3 col-auto">
                            <Form.Label>RGA</Form.Label> <br></br>
                            {this.props.ID}
                        </div>
                    </div>
                    {(this.props.NomeSocial != '' && this.props.NomeSocial != undefined && this.props.NomeSocial != null)
                    ?
                    <>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={10} lg={8}>
                            <Form.Label>Nome Social</Form.Label>
                            <Field
                                type="text"
                                title="Nome Social Completo"
                                name="NomeSocial"
                                id="NomeSocial"
                                placeholder="Ex.: José da Silva"
                                maxLength="255"
                                min="2"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${this.props.touched.NomeSocial && this.props.errors.NomeSocial ? 'is-invalid' : ''}`}
                                required
                                value={this.props.NomeSocial}
                            />
                            <ErrorMessage
                                component="div"
                                name="NomeSocial"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                    </Form.Row>
                    </>
                    :
                    <></>
                    }
                    <div className="row">
                        <div className="mb-3 col-auto">
                            <Form.Label>Sexo</Form.Label>
                            <Field
                                as="select"
                                name="Sexo"
                                id="Sexo"
                                className={`form-select ${this.props.touched.Sexo && this.props.errors.Sexo ? 'is-invalid' : '' }`}
                                onChange={this.props.handleInputChange}
                                required
                                value={this.props.Sexo}>
                                <option value="F">Feminino</option>
                                <option value="M">Masculino</option>
                            </Field>
                            <ErrorMessage
                                component="div"
                                name="Sexo"
                                className="invalid-feedback"
                            />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>CPF</Form.Label> <br></br>
                            <Field
                                type="text"
                                title="CPF"
                                name="CPF"
                                id="CPF"
                                placeholder="99999999999"
                                onChange={this.props.handleInputChange}
                                required
                                value={this.props.CPF}
                                className={`form-control ${this.props.touched.CPF && this.props.errors.CPF ? 'is-invalid' : ''}`}
                                style={{maxWidth: "160px"} }
                            />
                            <ErrorMessage
                                component="div"
                                name="CPF"
                                className="invalid-feedback"
                            />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>Data de Nascimento</Form.Label>{' '}
                            <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={this.props.DataNascimento}
                                className={`form-control ${this.props.touched.DataNascimento && this.props.errors.DataNascimento ? 'is-invalid' : ''}`}
                                onChange={(date) => this.props.handleInputChangeDate(date, 'DataNascimento')}
                            />
                            <ErrorMessage
                                component="div"
                                name="DataNascimento"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm={12} md={6} lg={4}>
                            <Form.Label>Nacionalidade</Form.Label>
                            <Field
                                type="text"
                                title="Nacionalidade"
                                name="Nacionalidade"
                                id="Nacionalidade"
                                placeholder="Ex.: Brasileiro"
                                onChange={this.props.handleInputChange}
                                value={this.props.Nacionalidade}
                                required
                                maxLength="255"
                                min="2"
                                className={`form-control ${
                                    this.props.touched.Nacionalidade &&
                                    this.props.errors.Nacionalidade
                                        ? 'is-invalid'
                                        : ''
                                }`}
                            />
                            <ErrorMessage
                                component="div"
                                name="Nacionalidade"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                        <NaturalidadeEstudante
                            Nacionalidade={this.props.Nacionalidade}
                            ufs={this.props.ufs}
                            municipios={this.props.municipios}
                            NaturalidadeUF={this.props.NaturalidadeUF}
                            NaturalidadeCodigoMunicipio={this.props.NaturalidadeCodigoMunicipio}
                            NomeMunicipioEstrangeiro={this.props.NomeMunicipioEstrangeiro}
                            handleChange={this.props.handleInputChange}
                            touched={this.props.touched}
                            errors={this.props.errors}
                        />
                    </div>
                    
                    <DocumentoIdentificacaoEstudante
                        Nacionalidade={this.props.Nacionalidade}
                        ufs={this.props.ufs}
                        OutroDocumentoIdentificacaoTipoDocumento={this.props.OutroDocumentoIdentificacaoTipoDocumento}
                        OutroDocumentoIdentificacaoIdentificador={this.props.OutroDocumentoIdentificacaoIdentificador}
                        RGOrgaoExpedidor={this.props.RGOrgaoExpedidor}
                        RGUF={this.props.RGUF}
                        RGNumero={this.props.RGNumero}
                        handleChange={this.props.handleInputChange}
                        touched={this.props.touched}
                        errors={this.props.errors}
                    />    

                </Card.Body>
            </Card>
        )
    }
}

export default RegistroNovoCamposDiplomado

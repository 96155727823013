import * as React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import apiAxios from '../../api/apiAxios';
import { IrPortal } from "../../util/funcoes";
import Swal from 'sweetalert2';

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        const { token } = this.props.match.params;

        // Make a request for a user with a given ID

        if (token) {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Carregando dados do usuário.',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            apiAxios.get(`/api/Login?cookie=${token}`)
                .then(result => {
                    console.log("Login result", result);
                    console.log("Login result data", result.data);
                    if (result.data == null || result.data == "" || (typeof result.data === 'string' && result.data.startsWith("<!doctype html>"))) {
                        // this.IrPortal();
                        Swal.close();
                        IrPortal();
                    }
                    else {
                        this.CarregarUsuario(result.data);
                        Swal.close();
                    }
                    // window.location = document.URL.replace("/"+token,'');
                    window.location.href = '/digital/registros';
                }).catch(ex => {
                    console.log(ex);
                    Swal.close();
                    IrPortal();
                    //this.IrPortal();
                });
        } else {
            console.log("sem token");
            const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
            console.log("usuario", usuario);
            if (usuario == null || usuario == undefined || usuario == "99999999999") {
                //this.IrPortal();
                IrPortal();
            }
        }
    }

    CarregarUsuario = (usuario) => {
        sessionStorage.setItem('@ufmt.diploma.app/ResponsavelRegistroNome', usuario.nome)
        sessionStorage.setItem('@ufmt.diploma.app/ResponsavelRegistroCPF', usuario.cpf)
        sessionStorage.setItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula', usuario.matricula)
        sessionStorage.setItem('@ufmt.diploma.app/ResponsavelRegistroCargo', usuario.cargo)
    }

    render() {
        return (
            <React.Fragment>
                <h4>Administração</h4>
                <Card className="mb-5">
                    <Card.Body>
                        <div className="row d-flex flex-wrap gap-3">
                            <div className="col-auto">
                                <Link
                                    to="/Conector/Enviar"
                                    className="btn btn-primary btn-lg">
                                    <i className="fas fa-file-import"></i>
                                    <br></br>
                                    Gerenciar Envios dos arquivos XML
                                </Link>
                            </div>

                            <div className="col-auto">
                                <Link
                                    to="/Documentacao/Cadastrar"
                                    className="btn btn-primary btn-lg">
                                    <i className="far fa-file-pdf"></i>
                                    <br></br>
                                    Documentação Acadêmica
                                </Link>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <h4>Consultas</h4>
                <Card className="mb-5">
                    <Card.Body>

                        <div className="row d-flex flex-wrap gap-3">
                            <div className="col-auto">
                                <Link
                                    to="/registros"
                                    className="btn btn-primary btn-lg">
                                    <i className="fas fa-certificate"></i>
                                    <br></br>
                                    Consultar Registros
                                </Link>
                            </div>
                            <div className="col-auto">
                                <Link
                                    to="/ConsultaPublica"
                                    className="btn btn-primary btn-lg">
                                    <i className="fas fa-users"></i>
                                    <br></br>
                                    Consulta Externa
                                </Link>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <h4>Cadastros</h4>
                <Card className="mb-3">
                    <Card.Body>

                        <div className="row d-flex flex-wrap gap-3">
                            <div className="col-auto">
                                <Link
                                    to="/livros"
                                    className="btn btn-primary btn-lg">
                                    <i className="fas fa-book"></i>
                                    <br></br>
                                    Livros de Registro
                                </Link>
                            </div>
                            <div className="col-auto">
                                <Link
                                    to="/cursos"
                                    className="btn btn-primary btn-lg">
                                    <i className="fas fa-folder-open"></i>
                                    <br></br>
                                    Gerenciar Cursos
                                </Link>
                            </div>

                        </div>

                        {/* <Col lg={3}>
                            <div className="botaoHome">
                                <Link
                                    to="/Diploma/Lista-Anulados"
                                    className="btn btn-primary btn-lg">
                                    <i className="fas fa-list"></i>
                                    <br></br>
                                    Lista de Diplomas Anulados
                                </Link>
                            </div>
                        </Col> */}
                    </Card.Body>
                </Card>
            </React.Fragment>
        )
    }
}
import React from "react";
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Menu = ({ title, menus }) => {
    return (
        <Breadcrumb className="arr-right mb-3">
            {menus && menus.map((m, i) => {
                return (
                    <li key={i} className="breadcrumb-item">
                        <Link to={m.path}>
                            {m.desc}
                        </Link>
                    </li>
                )
            })}
            <Breadcrumb.Item active>
                {title}
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default Menu;
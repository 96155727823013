import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { cancelarRegistroRequest } from '../../../store/thunks'
import { format } from 'date-fns'

function FormatDate(props) {
    if (props.data != null) {
        let dateTimeArray = props.data.split('T')
        let dataArray = dateTimeArray[0].split('-')
        return (
            <span>
                {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
            </span>
        )
    } else {
        return <span></span>
    }
}

const CancelarRegistroForm = ({
    registroId,
    estudante,
    dataRegistro,
    responsavelRegistro,
    handleSubmit,
    motivos,
    revogarOuSuspender,
    suspenso
}) => {
    const history = useHistory()
    const [inputValue, setInputValue] = React.useState('')
    const [selectValue, setSelectValue] = React.useState('2')
    const [acao, setAcao] = React.useState('suspender')

    return (
        <Form name="cancelarRegistroForm">
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <Form.Label>Estudante: </Form.Label> <br></br>
                    {estudante}
                </div>
            </Form.Row>
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <Form.Label>Data de Registro: </Form.Label> <br></br>
                    <FormatDate data={dataRegistro} />
                </div>
            </Form.Row>            
            {revogarOuSuspender ? <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <Form.Label>Ação:</Form.Label>
                    <Form.Control
                        as="select"
                        id="revogarOuSuspender"
                        name="revogarOuSuspender"
                        className={`form-select`}
                        value={acao}
                        onChange={(e) => setAcao(e.target.value)}
                    >
                        <option key="suspender" value="suspender" selected>
                            Suspender
                        </option>
                        <option key="revogar" value="revogar">
                            Revogar
                        </option>
                    </Form.Control>
                </div>
            </Form.Row> : <></> }
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <Form.Label>Motivo:</Form.Label>
                    <Form.Control
                        as="select"
                        id="MotivoId"
                        name="MotivoId"
                        className={`form-select`}
                        value={selectValue}
                        onChange={(e) => setSelectValue(e.target.value)}
                        >
                        {motivos.map((option, index) => (
                            <option key={option.registroMotivoAnulacaoUID} value={option.registroMotivoAnulacaoUID}>
                                {option.nome}
                            </option>
                        ))}
                    </Form.Control>
                </div>
            </Form.Row>
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto" controlId="formGridJustificativa">
                    <Form.Label>Justificativa</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        cols={100}
                        title="justificativa"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="form-control"
                        required
                    />
                </div>
            </Form.Row>
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <Form.Label>Responsável pelo Registro: </Form.Label>{' '}
                    <br></br>
                    Nome: {responsavelRegistro.nome} <br></br>
                    Matrícula (SIAPE): {
                        responsavelRegistro.iDouNumeroMatricula
                    }{' '}
                    <br></br>
                    CPF: {responsavelRegistro.cpf}
                </div>
            </Form.Row>
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <Form.Label>Responsável {suspenso ? 'pela Reativação' : 'pelo Cancelamento'}: </Form.Label>{' '}
                    <br></br>
                    Nome: {sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome')} <br></br>
                    Matrícula (SIAPE): {sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula')}{' '}
                    <br></br>
                    CPF: {sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF')}
                </div>
            </Form.Row>
            <Form.Row className="d-flex ">
                <div className="mb-3 col-auto">
                    <small>
                        Nota Técnica No. 13/2019/DIFES/SESU/SESU Versão 1.0{' '}
                        <br />
                        7.12.3 A anulação do diploma digital pode ocorrer nos
                        casos de erros de fato e erros de ofício, sendo o
                        processo de anulação diferente em cada caso.
                        <br />
                        7.12.5 Nos casos de erro de ofício, o diploma deve ser
                        anulado de forma irreversível através da sua indicação
                        como inativo, indicando na URL única do diploma o motivo
                        de sua revogação assim como a data e hora em que a
                        anulação foi feita.
                        <br />
                        7.12.6 Nos casos de erro de fato, o diploma pode ser
                        anulado de forma temporária, indicando na URL única do
                        diploma o motivo de sua revogação assim como a data e
                        hora em que a anulação foi feita. Caso o fato cesse ou
                        seja suspenso, o status do diploma poderá voltar a ser
                        ativo, mas a URL única do diploma deve manter as
                        anotações relativas ao período em que o diploma se
                        encontra suspenso.
                    </small>
                </div>
            </Form.Row>
            <Link className="btn btn-primary" to="/registros">
                Voltar
            </Link>{' '}
            <Button
                variant="danger"
                type="button"
                onClick={() => {
                    if (inputValue) {
                        handleSubmit( registroId,
                                selectValue,
                                inputValue,
                                sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome'),
                                sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula'),
                                acao
                                );
                        // onCreatePressed(
                        //     registroId,
                        //     inputValue,
                        //     sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome'),
                        //     sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula'),
                        // )
                        //setInputValue('')
                        //handleRedirect()
                    }
                }}
            >
                {suspenso ? 'Desfazer Suspensão' : 'Cancelar'}
            </Button>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    anotacoes: state.registros.anotacoes,
    loading: state.registros.loading,
    error: state.registros.error,
})

const mapDispatchToProps = (dispatch) => ({
    onCreatePressed: (
        registroId,
        motivoId,
        justificativa,
        responsavel,
        responsavelDocumento
    ) =>
        dispatch(
            cancelarRegistroRequest(
                registroId,
                motivoId,
                justificativa,
                responsavel,
                responsavelDocumento
            )
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelarRegistroForm)

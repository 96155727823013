import React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

const TextField = styled.input`
    height: 32px;
    width: 290px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Formando, RGA ou Curso"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

const ButtonRegistroDisponivel = (props) => {
    return (
        <Link className="btn btn-success btn-sm" to={`/novo-registro/${props.matricula}`}>
            Registrar
        </Link>
    )
}

const columns = [
    {
        name: 'Formando',
        selector: 'nome',
        sortable: true,
        maxWidth: '30%',
    },
    {
        name: 'RGA',
        selector: 'matricula',
        sortable: true,
        maxWidth: '10%',
    },
    {
        name: 'Campus',
        selector: 'campus',
        sortable: true,
        maxWidth: '35%',
    },
    {
        name: 'Curso',
        selector: 'curso',
        sortable: true,
        maxWidth: '35%',
    },
    {
        name: 'Data de Colação',
        cell: (props) => (props.dataColacaoGrau1 != null && props.dataColacaoGrau1 != undefined) ? <FormatDate
            data={props.dataColacaoGrau1}
        /> : '',
        sortable: true,
        maxWidth: '35%',
    },
    {
        name: 'Pendências',
        cell: (props) =>
            props.historico?.length > 0 ?
                <OverlayTrigger trigger="click" placement="top" overlay={
                    <Popover id="popover-basic">
                        <Popover.Title><strong>Disciplinas com pendências</strong></Popover.Title>
                        <Popover.Content>
                            {props.historico.map((disciplina) => (
                                <p className="mb-0" key={disciplina.codigoDisciplina}>
                                    <span>{disciplina.nomeDisciplina}</span><br />
                                    <span>Período: {disciplina.periodoLetivo?.slice(0, 4) + '/' + disciplina.periodoLetivo?.slice(4)}</span><br />
                                    <span>Motivo: {disciplina.docentes?.length > 0 ? disciplina?.docentes[0].nome : disciplina.situacao}</span>
                                </p>
                            ))}
                        </Popover.Content>
                    </Popover>
                }
                >
                    <Button variant="info" size="sm" className="text-white ">Ver</Button>
                </OverlayTrigger>
                :
                <span>Livre de pendências</span>,
        sortable: false,
        maxWidth: '6%',
    },
    {
        cell: (props) => props.registroDiplomaAtivo ? <Button variant="success" size="sm" disabled>Já registrado</Button> : <ButtonRegistroDisponivel matricula={props.matricula} />,
        ignoreRowClick: true,
        allowOverflow: true,
        maxWidth: '12%',
    },
]

function FormatDate(props) {
    if (props.data != null) {
        let dateTimeArray = props.data.split('T');
        let dateArray = dateTimeArray[0].split('-');
        return (
            <span>
                {dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]}
            </span>
        )
    } else {
        return <span></span>
    }
}

const BasicTable = (props) => {

    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = props.estudantesFormandos.filter(
        (item) =>
            (item.nome &&
                item.nome
                    .toLowerCase()
                    .includes(filterText.toLowerCase())) ||
            (item.curso &&
                item.curso
                    .toLowerCase()
                    .includes(filterText.toLowerCase())) ||
            (item.matricula &&
                String(item.matricula).includes(filterText.toLowerCase()))
    )

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Consulta Individual de Estudantes Formandos"
            columns={columns}
            keyField="rga"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhum estudante encontrado."
            paginationComponentOptions={{ rowsPerPageText: 'Estudantes por página:' }}
            persistTableHead
            Clicked
        />
    )
}

export default BasicTable
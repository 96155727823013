import * as React from 'react'
import { Card, Row, Col, ListGroup} from 'react-bootstrap'
import ResponsavelRegistroDiploma from './ResponsavelRegistroDiploma'

export class LivroRegistroDados extends React.Component {
    render() {
        function FormatDate(props) {
            if (props.data != null) {
                let dataArray = props.data.split('-')
                return (
                    <span>
                        {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
                    </span>
                )
            } else {
                return <span></span>
            }
        }
        return (
            <Card id="livroRegistro">
                <Card.Body>
                    <Card.Title>
                        <h3>Livro de Registro</h3>
                    </Card.Title>

                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                        
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Livro de Registro:</div>
                            <div className='fonte-cinza'>
                            {this.props.livroRegistro.livroRegistro}
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Nº Registro:</div>
                            <div className='fonte-cinza'>
                            {this.props.livroRegistro.numeroRegistro}
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">
                            Processo do Diploma:
                        </div>
                            <div className='fonte-cinza'>
                            {this.props.livroRegistro.processoDoDiploma}
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">
                            Data de Colação:
                        </div>
                            <div className='fonte-cinza'>
                            <FormatDate
                                data={this.props.livroRegistro.dataColacaoGrau}
                            />
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">
                            Data de Expedição:
                        </div>
                            <div className='fonte-cinza'>
                            <FormatDate
                                data={
                                    this.props.livroRegistro
                                        .dataExpedicaoDiploma
                                }
                            />
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">
                            Data de Registro:
                        </div>
                            <div className='fonte-cinza'>
                            <FormatDate
                                data={
                                    this.props.livroRegistro.dataRegistroDiploma
                                }
                            />
                            </div>
                        </div>
                    </ListGroup.Item>

                    </ListGroup> 
                    <ResponsavelRegistroDiploma
                        responsavelRegistro={this.props.responsavelRegistro}
                    />
                </Card.Body>
            </Card>
        )
    }
}

export default LivroRegistroDados

const urls = {
    GET_DADOS_ESTUDANTE: '/api/Aluno/detail/',
    GET_CURSO_POR_ID: '/api/curso/',
    GET_CURSO_POR_SIGA_ID: '/api/curso/siga/',
    GET_CURSO_FORMACOES: '/api/curso/formacao/',
    GET_CURSO_TITULACOES: '/api/curso/titulacao/',
    GET_CURSO_RECONHECIMENTOS: '/api/reconhecimento/curso/',
    GET_CURSO_RECONHECIMENTOS_POR_CURSO: '/api/reconhecimento/cursos/',
    GET_MUNICIPIOS: '/api/endereco/ufs/municipios/',
    GET_DADOS_ENADE_ESTUDANTE: '/api/Historico/Enade/',
    GET_CURSOS_GRAUS: '/api/grau',
    GET_UFS: '/api/endereco/ufs',
    GET_IES: '/api/instituicao/',
    GET_LIVROS: '/api/livro',
    POST_NOVO_REGISTRO: '/api/registro',
    GET_SIGA_TODOS_CAMPUS: '/api/colacao/campussiga',
    GET_SIGA_DATAS_COLACAO: '/api/colacao/recentes/ano/campus/',
    GET_COLACAO_ESTUDANTES: '/api/colacao/estudantes/',
    GET_CURSOS_ATIVOS: '/api/curso/ativos',
    POST_NOVA_TITULACAO_CURSO: '/api/curso/titulacao',
    POST_NOVA_FORMACAO_CURSO: '/api/curso/formacao',

    NOVO_VINCULO_RECONHECIMENTO_CURSO: '/api/reconhecimento/novo-vinculo-curso',
    NOVO_VINCULO_RECONHECIMENTO_CURSO_EM_LOTE: '/api/reconhecimento/lote-novo-vinculo-curso',
    NOVO_VINCULO_AUTORIZACAO_CURSO_EM_LOTE: '/api/curso/lote-novo-autorizacao',
    NOVO_VINCULO_AUTORIZACAO_CURSO: '/api/curso/novo-autorizacao',

    EXCLUI_VINCULO_RECONHECIMENTO_CURSO: '/api/reconhecimento/exclui-vinculo-curso/',
    EXCLUI_VINCULO_AUTORIZACAO_CURSO: '/api/curso/exclui-autorizacao/',

    GET_CURSO_VINCULADOS_RECONHECIMENTO_CURSOS: '/api/reconhecimento/cursos-ato-regulatorio/',
    GET_CURSO_VINCULADOS_AUTORIZACAO_CURSOS: '/api/cursos/autorizacao-cursos/',

    CANCELA_ATO_REGULATORIO: '/api/reconhecimento/ato-regulatorio/cancela/',
    CANCELA_ATO_REGULATORIO_POR_ID: '/api/atoregulatorio/cancela/',

    GET_TIPOS_ATO_REGULATORIO: '/api/reconhecimento/tiposAto',
    GET_ATO_REGULATORIO_TIPOS: '/api/atoregulatorio/tipos',

    GET_DADOS_ATO_REGULATORIO: '/api/reconhecimento/ato-regulatorio/dados/',
    GET_ATO_REGULATORIO: '/api/atoregulatorio/dados/',

    NOVO_ATO_REGULATORIO: '/api/reconhecimento/ato-regulatorio/novo',
    POST_ATO_REGULATORIO: '/api/atoregulatorio/novo',

    GET_AUTORIZACAO_CURSOS_POR_CURSO: '/api/curso/autorizacao/',
    GET_REGISTRO_SITUACAO: '/api/registro/situacao/',
    GET_LISTA_DIPLOMAS_ANULADOS: '/api/diploma/lista-diplomas-anulados-atual',
    GERAR_LISTA_DIPLOMAS_ANULADOS: '/api/diploma/xml-lista-diplomas-anulados',
    GET_XML_LISTA_DIPLOMAS_ANULADOS: '/api/diploma/atual-xml-lista-diplomas-anulados',

    GET_LISTAS_DIPLOMAS_ANULADOS: '/api/diploma/todas-listas-diplomas-anulados',
    GET_XML_LISTA_DIPLOMAS_ANULADOS_POR_ID: '/api/diploma/xml-lista-diplomas-anulados/',
    
    GET_ASSINATURAS: "/api/registro/assinaturas"
}
export default urls
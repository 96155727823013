import * as React from 'react'
import { Row, Col, Card, ListGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { cpfMask } from '../../../util/funcoes'

const ResponsavelRegistroDiploma = ({ responsavelRegistro }) => {
    return (
        <>        
        <ListGroup.Item
                style={{paddingBottom:0}}
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                <div className="fw-bold"><h5>Responsável pelo Registro</h5></div>
                    <div className='fonte-cinza'>
                        {responsavelRegistro.Nome ? responsavelRegistro.Nome : responsavelRegistro.nome}
                    </div>
                </div>
            </ListGroup.Item>
            <ListGroup.Item
                style={{paddingTop:0}}
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                    <div className='fonte-cinza'>
                    SIAPE:{' '}
                        {responsavelRegistro.IDouNumeroMatricula ? responsavelRegistro.IDouNumeroMatricula : responsavelRegistro.iDouNumeroMatricula}
                    </div>
                </div>
            </ListGroup.Item>
        </>
    )
}

export default connect()(ResponsavelRegistroDiploma)
import React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Nome ou CPF"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

const ButtonDetalhes = (props) => {
    return (
        <Link
            className="btn btn-primary"
            to={`/RegistroPublicoDetalhe/${props.registroID}`}
        >
            Detalhar
        </Link>
    )
}

const columns = [
    {
        name: 'Nº Registro',
        selector: 'registroID',
        sortable: true,
        maxWidth: '10%',
    },
    /* {
        name: 'Nº Expedição',
        selector: 'numeroSequenciaDoDiploma',
        sortable: true,
    }, */
    {
        name: 'Name',
        selector: 'nomeDiplomado',
        sortable: true,
    },
    {
        cell: (props) => <ButtonDetalhes registroID={props.registroID} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
]

const BasicTable = (props) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )

    let filteredItems = [];
    if (props.registros != null && props.registros != undefined) {
        try {
            filteredItems = props.registros.filter(
                (item) => (item.nomeDiplomado && item.nomeDiplomado.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.cpf && item.cpf.includes(filterText.toLowerCase())) ||
                    (item.id && item.id.includes(filterText.toLowerCase()))
            )
        } catch (ex) {
            console.log("erro BasicTable", ex);
            console.log("BasicTable registros", props.registros);
        }
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Registros"
            columns={columns}
            keyField="registroID"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhum registro encontrado para o período selecionado."
            paginationComponentOptions={{
                rowsPerPageText: 'Registros por página:',
            }}
            persistTableHead
            Clicked
        />
    )
}

export default BasicTable

import * as React from 'react'
import { Card, Row, Col, ListGroup } from 'react-bootstrap'
import EnderecoDados from './EnderecoDados'
import AtoRegulatorioDados from './AtoRegulatorioDados'

export class CursoDados extends React.Component {
    render() {
        function CursoDadosReduzidos(props) {
            return (
                <>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                    <div className="fw-bold">Curso:</div>
                        <div className='fonte-cinza'>
                        {props.dadosCurso.nomeCurso}
                        </div>
                    </div>
                </ListGroup.Item>
                    <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                    <div className="fw-bold">Código E-MEC:</div>
                        <div className='fonte-cinza'>
                                {props.dadosCurso.codigoCursoEMEC}
                        </div>
                    </div>
                </ListGroup.Item>
                </>
            )
        }
        function CursoDadosCompleto(props) {
            if (!props.mostrarReduzido) {
                return (
                    <>
                    {(props.dadosCurso.nomeHabilitacao != undefined 
                        && props.dadosCurso.nomeHabilitacao != null 
                        && props.dadosCurso.nomeHabilitacao != "" 
                        && props.dadosCurso.nomeHabilitacao != "Não se aplica")
                        ?
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start list-group-item-no-border"
                        >
                            <div className="ms-2 me-auto" >
                            <div className="fw-bold">Habilitação:</div>
                                <div className='fonte-cinza'>
                                {props.dadosCurso.nomeHabilitacao}
                                </div>
                            </div>
                        </ListGroup.Item>
                        : <></>
                    }
                    {(props.dadosCurso.habilitacao != undefined 
                        && props.dadosCurso.habilitacao != null)
                        ?
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start list-group-item-no-border"
                        >
                            <div className="ms-2 me-auto" >
                            <div className="fw-bold">Habilitação:</div>
                                <div className='fonte-cinza'>
                                {props.dadosCurso.habilitacao.nomeHabilitacao}
                                </div>
                            </div>
                        </ListGroup.Item>
                        : <></>
                    }
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Modalidade:</div>
                            <div className='fonte-cinza'>
                                {props.dadosCurso.modalidade}
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Título conferido:</div>
                            <div className='fonte-cinza'>
                                {(props.dadosCurso.tituloConferido?.titulo == null)
                                ? props.dadosCurso.tituloConferido?.outroTitulo
                                : props.dadosCurso.tituloConferido?.titulo}
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Grau conferido:</div>
                            <div className='fonte-cinza'>
                                {props.dadosCurso.grauConferido}
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <EnderecoDados dados={props.enderecoCurso} 
                        tipo="Endereço do Curso"/>
                    </ListGroup>

                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <AtoRegulatorioDados
                        dados={props.autorizacaoCurso}
                        tipo="Autorização do Curso"
                    />
                    </ListGroup>

                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <AtoRegulatorioDados
                        tipo="Reconhecimento do Curso"
                        dados={props.reconhecimentoCurso}
                    />
                    </ListGroup>
                    </>
                )
            } else {
                return null
            }
        }
        return (
            <Card id="dadosCurso">
                <Card.Body>
                    <Card.Title>
                        <h3>Dados Curso</h3>
                    </Card.Title>
                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <CursoDadosReduzidos dadosCurso={this.props.dadosCurso} />
                    <CursoDadosCompleto
                        mostrarReduzido={this.props.mostrarReduzido}
                        dadosCurso={this.props.dadosCurso}
                        enderecoCurso={this.props.enderecoCurso}
                        autorizacaoCurso={this.props.autorizacaoCurso}
                        reconhecimentoCurso={this.props.reconhecimentoCurso}
                    />
                    </ListGroup>       
                </Card.Body>
            </Card>
        )
    }
}

export default CursoDados

import * as React from 'react'
import { Card, Breadcrumb, Alert } from 'react-bootstrap'
import CancelarRegistroForm from '../../components/Area/CAE/CancelarRegistroForm'
import { loadRegistro } from '../../store/thunks'
import { connect } from 'react-redux'
import { IrPortal } from "../../util/funcoes";
import Swal from 'sweetalert2'
import * as api from './../../api/api'
import apiAxios from './../../api/apiAxios';
import apiURLs from './../../api/apiURLs';
import { Link } from 'react-router-dom'

class CancelarRegistro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registroId: this.props.match.params.registroId,
            assinado: true,
            situacaoEnvioId: 0,
            motivos:[]
        }
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(loadRegistro(this.state.registroId))
        this.getSituacaoRegistro();
        this.getMotivos();
    }

    handleRedirect(){
        window.location.href = '/digital/registros'
    }    
    
    getMotivos () {
        try {            
            let url = "/api/registro/cancelaMotivos";
            //console.log(url);
            apiAxios
                .get(url)
                .then((result) => {
               //     console.log(result.data);
                    this.setState({ motivos: result.data })
                })
                .catch((ex) => {
                    console.log(ex)
                }) 
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao buscar motivos de anulacao de registro!',
                html: 'Se erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    getSituacaoRegistro () {
        try {            
            let url = apiURLs.GET_REGISTRO_SITUACAO + this.state.registroId;
            //console.log(url);
            apiAxios
                .get(url)
                .then((result) => {
                    console.log(result.data);
                    this.setState({ assinado: result.data.assinado, situacaoEnvioId: result.data.situacaoEnvioId })
                })
                .catch((ex) => {
                    console.log(ex)
                }) 
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao buscar situacao de registro!',
                html: 'Se erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    chamarApi (acao, dadosCancelamento, registroId)
    {
        switch (acao)
        {
            case 'revogar':
                return api.revogarRegistro(dadosCancelamento, registroId);
            case 'suspender':
                return api.suspenderRegistro(dadosCancelamento, registroId);
            default:
                return api.cancelarRegistro(dadosCancelamento, registroId);
        }
    }

    handleSubmit (registroId,
        motivoId,
        justificativa,
        responsavel,
        responsavelDocumento,
        acao) {
        try {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Cancelando registro...',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            const dadosCancelamento = JSON.stringify({
                registroId: Number(registroId),
                motivoId: Number(motivoId),
                justificativa,
                responsavel,
                responsavelDocumento,
            })
            console.log(dadosCancelamento);
            this.chamarApi(acao, dadosCancelamento, registroId).then((resp) => {
                console.log(resp)
                if (resp.status !== 200) {              
                    Swal.close()      
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao cancelar registro!',
                        html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
                    })
                } else {
                    Swal.close()            
                    Swal.fire({
                    icon: 'success',
                    title: 'Registro cancelado com sucesso! Redirecionando...',
                    showConfirmButton: false,
                    timer: 10000,
                    }).then(this.handleRedirect())             
                }
            })
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao cancelar registro!',
                html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    renderAvisoRevogacaoOuSuspensao = () =>
    {
        if (this.state.assinado)
        {
            return (
                <Alert variant="warning">
                    Existe documento XML assinado vinculado a esse registro. Neste caso, as opções são:<br /><br />
                    • Suspensão: O diploma será anulado temporariamente e poderá ser reativado a qualquer momento<br />
                    • <b>Revogação: O diploma será revogado de forma irreversível. Esta ação não pode ser desfeita</b><br />                    
                </Alert>
            )
        }

        return <></>
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link
                            to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/registros">
                            Consulta de Registros
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Cancelar Registro
                    </Breadcrumb.Item>
                </Breadcrumb>
                {(this.state.assinado != true)
                    ?
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <h2>Cancelar Registro</h2>
                                <small>
                                    Preencha as informações abaixo para continuar
                                    com o cancelamento do registro de diploma.
                                </small>
                            </Card.Title>
                            {
                                /*TODO: Remover comentário para reabilitar revogação ou suspensão na issue https://git.ufmt.br/csi/ufmt.diploma/-/issues/62 */
                                /*{this.renderAvisoRevogacaoOuSuspensao()}*/
                            }                            
                            <br></br>
                            <CancelarRegistroForm
                                registroId={this.state.registroId}
                                estudante={this.props.registro?.diplomado?.nome ?? ""}
                                dataRegistro={this.props.registro?.livroRegistro?.dataRegistroDiploma ?? ""}
                                responsavelRegistro={this.props.registro?.livroRegistro?.responsavelRegistro ?? ""}
                                handleSubmit={this.handleSubmit}
                                motivos={this.state.motivos}
                                revogarOuSuspender={this.state.assinado}
                            />
                        </Card.Body>
                    </Card>                    
                    :                    
                    <Card>
                        {/*TODO: Remover este card para reabilitar revogação ou suspensão na issue https://git.ufmt.br/csi/ufmt.diploma/-/issues/62*/ }
                        <Card.Body>
                            <Card.Title>
                                <h2>Cancelar Registro</h2>
                                <small>
                                    Existe documento XML assinado vinculado a esse registro. Entre em contato com o administrador do sistema para prosseguir com o cancelamento do registro.
                                </small>
                            </Card.Title>
                            <Link className="btn btn-primary" to="/registros">
                                Voltar
                            </Link>{' '}
                        </Card.Body>
                    </Card >                    
                }
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(CancelarRegistro)

import * as React from 'react'
import { Card, Row, Col, ListGroup } from 'react-bootstrap'
import EnderecoDados from './EnderecoDados'
import AtoRegulatorioDados from './AtoRegulatorioDados'
import { cnpjMask } from "../../../util/funcoes";

export class IESDados extends React.Component {
    render() {
        function IesDadosReduzidos(props) {
            return (
                <>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Nome:</div>
                            <div className='fonte-cinza'>
                            {props.ies.nome}
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Código E-MEC:</div>
                            <div className='fonte-cinza'>
                            {props.ies.codigoEmec ? props.ies.codigoEmec : props.ies.codigoMEC}
                            </div>
                        </div>
                    </ListGroup.Item>
                </>
            )
        }
        function IesDadosCompleto(props) {
            if (!props.mostrarReduzido) {
                return (
                    <>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start list-group-item-no-border"
                        >
                            <div className="ms-2 me-auto" >
                            <div className="fw-bold">CNPJ:</div>
                                <div className='fonte-cinza'>
                                    {(props.ies.cnpj != undefined) ? cnpjMask(props.ies.cnpj) : ""}
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup as="ol" style={{fontSize:"16px"}}>
                        <EnderecoDados dados={props.endereco} 
                            tipo="Endereço"/>
                        </ListGroup>

                        <ListGroup as="ol" style={{fontSize:"16px"}}>
                        <AtoRegulatorioDados
                                    dados={props.credenciamento} 
                            tipo="Credenciamento"
                        />
                        </ListGroup>

                        <ListGroup as="ol" style={{fontSize:"16px"}}>
                        <AtoRegulatorioDados
                                    dados={props.recredenciamento} 
                            tipo="Recredenciamento"
                        />
                        </ListGroup>

                        <ListGroup as="ol" style={{fontSize:"16px"}}>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start list-group-item-no-border"
                            >
                                <div className="ms-2 me-auto" >
                                <div className="fw-bold"><h5>Mantenedora</h5></div>
                                    <div className='fonte-cinza'>{props.mantenedora.razaoSocial}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start list-group-item-no-border"
                        >
                            <div className="ms-2 me-auto" >
                                <div className='fonte-cinza'>
                                CNPJ: {(props.mantenedora.cnpj != undefined) ? cnpjMask(props.mantenedora.cnpj) : ""}
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup as="ol" style={{fontSize:"16px"}}>
                        <EnderecoDados 
                                    dados={props.enderecoMantenedora}
                            tipo="Endereço da Mantenedora"/>
                        </ListGroup>
                    </>
                )
            } else {
                return null
            }
        }
        return (
            <>
                <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <IesDadosReduzidos ies={this.props.ies} />
                    <IesDadosCompleto
                        ies={this.props.ies}
                        endereco={this.props.endereco}
                        credenciamento={this.props.credenciamento}
                        recredenciamento={this.props.recredenciamento}
                        mantenedora={this.props.mantenedora}
                        enderecoMantenedora={this.props.enderecoMantenedora}
                        mostrarReduzido={this.props.mostrarReduzido}
                    />
                </ListGroup>    
            </>
        )
    }
}

export default IESDados
import Swal from 'sweetalert2';

export const exibirMensagemAguarde = (title, mensagem) => {
    Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        title: title,
        html: mensagem,
        allowOutsideClick: false,
        willOpen: () => {
            Swal.showLoading()
        },
    });
}

export const formatarData = (data) => {
    try {
        if (data == undefined || data == null) {
            return null;
        }
        if (data.includes("T00:00:00")) {
            data = data.replace("T00:00:00", "T07:00:00");
        }
        return new Date(data);
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const exibirMensagemErro = (title, error) => {
    try {
        const jsonError = JSON.parse(error?.request?.response || null);
        if (jsonError && jsonError.message) {
            if (jsonError.message.includes("{")) {
                const jsonErrorDetail = JSON.parse(jsonError.message || null);
                if (jsonErrorDetail && jsonErrorDetail.message) {
                    Swal.fire('Erro ao buscar registros', jsonErrorDetail.message, 'error');
                } else {
                    Swal.fire('Erro ao buscar registros', jsonError.message, 'error');
                }
            } else {
                Swal.fire('Erro ao buscar registros', jsonError.message, 'error');    
            }
        } else {
            Swal.fire('Erro ao buscar registros', `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
        }
        console.error("try", error);
    } catch (e) {
        console.error(e);
        Swal.fire(title, `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
    }
}
import ActionTypes from './actionTypes'

export const failure = () => ({
    type: ActionTypes.FAILURE,
})

export const getRegistros = (text) => ({
    type: ActionTypes.GET_REGISTROS,
    payload: text,
})

export const loadAnotacoesInProgress = () => ({
    type: ActionTypes.LOAD_ANOTACOES_IN_PROGRESS,
})

export const loadAnotacoesSuccess = (anotacoes) => ({
    type: ActionTypes.LOAD_ANOTACOES_SUCCESS,
    payload: { anotacoes },
})

export const loadAnotacoesFailure = (error) => ({
    type: ActionTypes.LOAD_ANOTACOES_FAILURE,
    payload: { error },
})

export const loadRegistroPorIdInProgress = () => ({
    type: ActionTypes.LOAD_REGISTRO_POR_ID_IN_PROGRESS,
})

export const loadRegistroPorIdSuccess = (registro) => ({
    type: ActionTypes.LOAD_REGISTRO_POR_ID_SUCCESS,
    payload: { registro },
})


export const setSelectedRegistrosInProgress = () => ({
    type: ActionTypes.SET_SELECTED_REGISTROS_IN_PROGRESS,
})

export const setSelectedRegistrosSuccess = (registrosSelecionados) => ({
    type: ActionTypes.SET_SELECTED_REGISTROS_SUCCESS,
    payload: { registrosSelecionados },
})

export const setSelectedRegistrosFailure = (error) => ({
    type: ActionTypes.SET_SELECTED_REGISTROS_FAILURE,
    payload: { error },
})

export const loadRegistroPorIdFailure = (error) => ({
    type: ActionTypes.LOAD_REGISTRO_POR_ID_FAILURE,
    payload: { error },
})


export const loadRegistrosSelecionadosInProgress = () => ({
    type: ActionTypes.LOAD_REGISTROS_SELECIONADOS_IN_PROGRESS,
})

export const loadRegistrosSelecionadosSuccess = (registrosSelecionadosDados) => ({
    type: ActionTypes.LOAD_REGISTROS_SELECIONADOS_SUCCESS,
    payload: { registrosSelecionadosDados },
})

export const loadRegistrosSelecionadosFailure = (error) => ({
    type: ActionTypes.LOAD_REGISTROS_SELECIONADOS_FAILURE,
    payload: { error },
})

export const loadRegistrosInProgress = () => ({
    type: ActionTypes.LOAD_REGISTROS_IN_PROGRESS,
})

export const loadRegistrosSuccess = (registros) => ({
    type: ActionTypes.LOAD_REGISTROS_SUCCESS,
    payload: { registros },
})

export const loadRegistrosFailure = (error) => ({
    type: ActionTypes.LOAD_REGISTROS_FAILURE,
    payload: { error },
})

export const loadEstudantesFormandosInProgress = () => ({
    type: ActionTypes.LOAD_ESTUDANTES_FORMANDOS_IN_PROGRESS,
})

export const loadEstudantesFormandosSuccess = (estudantes) => ({
    type: ActionTypes.LOAD_ESTUDANTES_FORMANDOS_SUCCESS,
    payload: { estudantes },
})

export const loadEstudantesFormandosFailure = (error) => ({
    type: ActionTypes.LOAD_ESTUDANTES_FORMANDOS_FAILURE,
    payload: { error },
})

export const loadEstudantesInProgress = () => ({
    type: ActionTypes.LOAD_ESTUDANTES_IN_PROGRESS,
})

export const loadEstudantesSuccess = (estudantes) => ({
    type: ActionTypes.LOAD_ESTUDANTES_SUCCESS,
    payload: { estudantes },
})

export const loadEstudantesFailure = (error) => ({
    type: ActionTypes.LOAD_ESTUDANTES_FAILURE,
    payload: { error },
})

export const loadReconhecimentosInProgress = () => ({
    type: ActionTypes.LOAD_RECONHECIMENTOS_IN_PROGRESS,
})

export const loadReconhecimentosSuccess = (reconhecimentos) => ({
    type: ActionTypes.LOAD_RECONHECIMENTOS_SUCCESS,
    payload: { reconhecimentos },
})

export const loadReconhecimentosFailure = (error) => ({
    type: ActionTypes.LOAD_RECONHECIMENTOS_FAILURE,
    payload: { error },
})

export const loadAtosRegulatoriosReconhecimentosInProgress = () => ({
    type: ActionTypes.LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_IN_PROGRESS,
})

export const loadAtosRegulatoriosReconhecimentosSuccess = (reconhecimentos) => ({
    type: ActionTypes.LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_SUCCESS,
    payload: { reconhecimentos },
})

export const loadAtosRegulatoriosReconhecimentosFailure = (error) => ({
    type: ActionTypes.LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_FAILURE,
    payload: { error },
})

export const loadLivrosInProgress = () => ({
    type: ActionTypes.LOAD_LIVROS_IN_PROGRESS,
})

export const loadLivrosSuccess = (livros) => ({
    type: ActionTypes.LOAD_LIVROS_SUCCESS,
    payload: { livros },
})

export const loadLivrosFailure = (error) => ({
    type: ActionTypes.LOAD_LIVROS_FAILURE,
    payload: { error },
})

export const addLivroRegistro = (livro) => ({
    type: ActionTypes.ADD_LIVRO_REGISTRO,
    payload: livro,
})
export const addLivroRegistroFailure = (codigoErro) => ({
    type: ActionTypes.ADD_LIVRO_REGISTRO_FAILURE,
    payload: codigoErro,
})
export const removeLivroRegistro = (id) => ({
    type: ActionTypes.REMOVE_LIVRO_REGISTRO,
    payload: id,
})
export const removeLivroRegistroFailure = (codigoErro) => ({
    type: ActionTypes.REMOVE_LIVRO_REGISTRO_FAILURE,
    payload: codigoErro,
})

export const addNovaDataPublicacao = () => ({
    type: ActionTypes.ADD_NOVA_DATA_PUBLICACAO_REGISTRO,
})

export const addNovaDataPublicacaoEmLote = () => ({
    type: ActionTypes.ADD_NOVA_DATA_PUBLICACAO_LOTE_REGISTRO,
})

export const cancelaRegistro = (registroId) => ({
    type: ActionTypes.CANCELA_REGISTRO,
    payload: registroId,
})

export const revogaCancelamentoRegistro = (registroId) => ({
    type: ActionTypes.REVOGA_CANCELAMENTO_REGISTRO,
    payload: registroId,
})

export const loadDadosFormandoInProgress = () => ({
    type: ActionTypes.LOAD_DADOS_FORMANDO_IN_PROGRESS,
})

export const loadDadosFormandoSuccess = (estudante) => ({
    type: ActionTypes.LOAD_DADOS_FORMANDO_SUCCESS,
    payload: { estudante },
})

export const loadDadosFormandoFailure = (error) => ({
    type: ActionTypes.LOAD_DADOS_FORMANDO_FAILURE,
    payload: { error },
})

export const addNovoRegistro = () => ({
    type: ActionTypes.ADD_NOVO_REGISTRO,
})

export const addNovoRegistroInProgress = () => ({
    type: ActionTypes.ADD_NOVO_REGISTRO_IN_PROGRESS,
})

export const addNovoRegistroSuccess = () => ({
    type: ActionTypes.ADD_NOVO_REGISTRO_SUCCESS,
})

export const addNovoRegistroFailure = (error) => ({
    type: ActionTypes.ADD_NOVO_REGISTRO_FAILURE,
    payload: { error },
})

export const loadCampusInProgress = () => ({
    type: ActionTypes.LOAD_CAMPUS_IN_PROGRESS,
})

export const loadCampusSuccess = (campus) => ({
    type: ActionTypes.LOAD_CAMPUS_SUCCESS,
    payload: { campus },
})

export const loadCampusFailure = (error) => ({
    type: ActionTypes.LOAD_CAMPUS_FAILURE,
    payload: { error },
})
export const clearEstudantesInProgress = () => ({
    type: ActionTypes.CLEAR_ESTUDANTES,
    payload: {},
})
export const clearEstudantesFormandosInProgress = () => ({
    type: ActionTypes.CLEAR_ESTUDANTES_FORMANDOS,
    payload: {},
})

export const loadAnosInProgress = () => ({
    type: ActionTypes.LOAD_ANOS_IN_PROGRESS,
})

export const loadAnosSuccess = (anos) => ({
    type: ActionTypes.LOAD_ANOS_SUCCESS,
    payload: { anos },
})

export const loadAnosFailure = (error) => ({
    type: ActionTypes.LOAD_ANOS_FAILURE,
    payload: { error },
})

export const loadDatasColacaoInProgress = () => ({
    type: ActionTypes.LOAD_DATAS_COLACAO_IN_PROGRESS,
})

export const loadDatasColacaoSuccess = (datasColacao) => ({
    type: ActionTypes.LOAD_DATAS_COLACAO_SUCCESS,
    payload: { datasColacao },
})

export const loadDatasColacaoFailure = (error) => ({
    type: ActionTypes.LOAD_DATAS_COLACAO_FAILURE,
    payload: { error },
})

export const loadUFsInProgress = () => ({
    type: ActionTypes.LOAD_UFS_IN_PROGRESS,
})

export const loadUFsSuccess = (ufs) => ({
    type: ActionTypes.LOAD_UFS_SUCCESS,
    payload: { ufs },
})

export const loadUFsFailure = (error) => ({
    type: ActionTypes.LOAD_UFS_FAILURE,
    payload: { error },
})

export const loadMunicipiosInProgress = () => ({
    type: ActionTypes.LOAD_MUNICIPIOS_IN_PROGRESS,
})

export const loadMunicipiosSuccess = (municipios) => ({
    type: ActionTypes.LOAD_MUNICIPIOS_SUCCESS,
    payload: { municipios },
})

export const loadMunicipiosFailure = (error) => ({
    type: ActionTypes.LOAD_MUNICIPIOS_FAILURE,
    payload: { error },
})

export const loadCursoInProgress = () => ({
    type: ActionTypes.LOAD_CURSO_IN_PROGRESS,
})

export const loadCursoSuccess = (curso) => ({
    type: ActionTypes.LOAD_CURSO_SUCCESS,
    payload: { curso },
})

export const loadCursoFailure = (error) => ({
    type: ActionTypes.LOAD_CURSO_FAILURE,
    payload: { error },
})

export const loadCursosGrausInProgress = () => ({
    type: ActionTypes.LOAD_CURSOS_GRAUS_IN_PROGRESS,
})

export const loadCursosGrausSuccess = (cursosGraus) => ({
    type: ActionTypes.LOAD_CURSOS_GRAUS_SUCCESS,
    payload: { cursosGraus },
})

export const loadCursosGrausFailure = (error) => ({
    type: ActionTypes.LOAD_CURSOS_GRAUS_FAILURE,
    payload: { error },
})

export const loadCursoFormacoesInProgress = () => ({
    type: ActionTypes.LOAD_CURSO_FORMACOES_IN_PROGRESS,
})

export const loadCursoFormacoesSuccess = (cursoFormacoes) => ({
    type: ActionTypes.LOAD_CURSO_FORMACOES_SUCCESS,
    payload: { cursoFormacoes },
})

export const loadCursoFormacoesFailure = (error) => ({
    type: ActionTypes.LOAD_CURSO_FORMACOES_FAILURE,
    payload: { error },
})

export const loadCursoTitulacoesInProgress = () => ({
    type: ActionTypes.LOAD_CURSO_TITULACOES_IN_PROGRESS,
})

export const loadCursoTitulacoesSuccess = (cursoTitulacoes) => ({
    type: ActionTypes.LOAD_CURSO_TITULACOES_SUCCESS,
    payload: { cursoTitulacoes },
})

export const loadCursoTitulacoesFailure = (error) => ({
    type: ActionTypes.LOAD_CURSO_TITULACOES_FAILURE,
    payload: { error },
})

export const loadCursoReconhecimentosInProgress = () => ({
    type: ActionTypes.LOAD_CURSO_RECONHECIMENTOS_IN_PROGRESS,
})

export const loadCursoReconhecimentosSuccess = (cursoReconhecimentos) => ({
    type: ActionTypes.LOAD_CURSO_RECONHECIMENTOS_SUCCESS,
    payload: { cursoReconhecimentos },
})

export const loadCursoReconhecimentosFailure = (error) => ({
    type: ActionTypes.LOAD_CURSO_RECONHECIMENTOS_FAILURE,
    payload: { error },
})

export const loadIesEmissoraInProgress = () => ({
    type: ActionTypes.LOAD_IES_EMISSORA_IN_PROGRESS,
})

export const loadIesEmissoraSuccess = (iesEmissora) => ({
    type: ActionTypes.LOAD_IES_EMISSORA_SUCCESS,
    payload: { iesEmissora },
})

export const loadIesEmissoraFailure = (error) => ({
    type: ActionTypes.LOAD_IES_EMISSORA_FAILURE,
    payload: { error },
})

export const loadIesRegistradoraInProgress = () => ({
    type: ActionTypes.LOAD_IES_REGISTRADORA_IN_PROGRESS,
})

export const loadIesRegistradoraSuccess = (iesRegistradora) => ({
    type: ActionTypes.LOAD_IES_REGISTRADORA_SUCCESS,
    payload: { iesRegistradora },
})

export const loadIesRegistradoraFailure = (error) => ({
    type: ActionTypes.LOAD_IES_REGISTRADORA_FAILURE,
    payload: { error },
})

export const loadEstudanteEnadeInProgress = () => ({
    type: ActionTypes.LOAD_ESTUDANTE_ENADE_IN_PROGRESS,
})

export const loadEstudanteEnadeSuccess = (enade) => ({
    type: ActionTypes.LOAD_ESTUDANTE_ENADE_SUCCESS,
    payload: { enade },
})

export const loadEstudanteEnadeFailure = (error) => ({
    type: ActionTypes.LOAD_ESTUDANTE_ENADE_FAILURE,
    payload: { error },
})

export const loadAtosRegulatoriosAutorizacaoCursoInProgress = () => ({
    type: ActionTypes.LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_IN_PROGRESS,
})

export const loadAtosRegulatoriosAutorizacaoCursoSuccess = (autorizacoes) => ({
    type: ActionTypes.LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_SUCCESS,
    payload: { autorizacoes },
})

export const loadAtosRegulatoriosAutorizacaoCursoFailure = (error) => ({
    type: ActionTypes.LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_FAILURE,
    payload: { error },
})

import ActionTypes from './actionTypes'

const initialState = {
    iesEmissora: {},
    iesRegistradora: {},
    loading: false,
    error: null,
}

export default function iesReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.LOAD_IES_EMISSORA_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_IES_EMISSORA_SUCCESS: {
            return {
                ...state,
                loading: false,
                iesEmissora: payload.iesEmissora,
            }
        }
        case ActionTypes.LOAD_IES_EMISSORA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                iesEmissora: {},
            }
        }
        case ActionTypes.LOAD_IES_REGISTRADORA_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_IES_REGISTRADORA_SUCCESS: {
            return {
                ...state,
                loading: false,
                iesRegistradora: payload.iesRegistradora,
            }
        }
        case ActionTypes.LOAD_IES_REGISTRADORA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                iesRegistradora: {},
            }
        }
        default:
            return state
    }
}

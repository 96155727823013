import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaLivro from './../../components/Area/CAE/TabelaLivro'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadLivros, removeLivroRequest } from './../../store/thunks'
import { IrPortal } from "../../util/funcoes";

class LivroRegistroConsulta extends React.Component {
    static displayName = LivroRegistroConsulta.name
    constructor(props) {
        super(props)
        this.state = { livros: [] }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        this.props.dispatch(loadLivros())
    }

    onRemovePressed = (payload) => {
        this.props.dispatch(removeLivroRequest(payload))
    }

    render() {
        const { livros } = this.props //error, loading,
        return (
            <>
                <Breadcrumb className="arr-right">
                    <Breadcrumb.Item href="./cae">
                        Página Inicial - CAE
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Consulta de Livros de Registro
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card className="mt-3">
                    <Card.Body>
                        <Link to="/livros/novo" className="btn btn-primary">
                            <i className="fas fa-plus-circle"></i> Novo
                        </Link>

                        <br></br>
                        <TabelaLivro
                            livros={livros}
                            onRemovePressed={this.onRemovePressed}
                        />
                    </Card.Body>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    livros: state.livros.livros,
    loading: state.livros.loading,
    error: state.livros.error,
})

export default connect(mapStateToProps)(LivroRegistroConsulta)

import * as React from 'react'
import { Card, Breadcrumb, Alert } from 'react-bootstrap'
import apiAxios from './../../api/apiAxios';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import TabelaReconhecimentoCursosVisualizacao from './../../components/Area/CAE/TabelaReconhecimentoCursosVisualizacao'
import ReconhecimentoDetalhe from './../../components/Area/CAE/ReconhecimentoDetalhe'
import apiURLs from './../../api/apiURLs';
import { IrPortal } from "../../util/funcoes";

export default class ReconhecimentoVerDetalhe extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            atoRegulatorio: {},
            ReconhecimentosCursos: [],
        }

        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        if (this.props.match.params.id != undefined) {
            this.setAtoRegulatorio();
            this.setReconhecimentoCursos();
        }
    }

    setAtoRegulatorio() {
        //const url = `https://localhost:5001/api/reconhecimento/ato-regulatorio/dados/${this.props.match.params.id}`;        
        const url = apiURLs.GET_DADOS_ATO_REGULATORIO + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    atoRegulatorio: result.data
                })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }
    setReconhecimentoCursos() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando cursos vinculados...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })

        //const url = `https://localhost:5001/api/reconhecimento/cursos-ato-regulatorio/${this.props.match.params.id}`;
        const url = apiURLs.GET_CURSO_VINCULADOS_RECONHECIMENTO_CURSOS + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    ReconhecimentosCursos: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            })
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <Breadcrumb.Item href="./cae">
                        Página Inicial - CAE
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="./reconhecimentos">
                        Consulta de Atos Regulatórios de Reconhecimento de Curso(s)
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Detalhes de Ato Regulatório de Reconhecimento de Curso(s)
                    </Breadcrumb.Item>
                </Breadcrumb>

                <h3 className="my-3">Detalhes de Ato Regulatório de Reconhecimento de Curso(s)</h3>

                {(this.state.atoRegulatorio.cancelado) ?
                    <Alert variant="danger">
                    Ato Regulatório de Reconhecimento de Curso(s) Cancelado
                    </Alert> :
                    <></>}
                <ReconhecimentoDetalhe
                    atoRegulatorio={this.state.atoRegulatorio}
                />

                <Card className="my-3">
                    <Card.Body>
                        <TabelaReconhecimentoCursosVisualizacao
                            reconhecimentosCursos={this.state.ReconhecimentosCursos}
                        />
                    </Card.Body>
                </Card>

                <Link className="btn btn-primary" to="/reconhecimentos">
                    Voltar
                </Link>
            </>
        )
    }
}

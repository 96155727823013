import { combineReducers, createStore, applyMiddleware } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import livros from './store/livroReducer'
import reconhecimentos from './store/reconhecimentoReducer'
import registros from './store/registroReducer'
import cursos from './store/cursoReducer'
import ies from './store/iesReducer'
import autorizacoes from './store/autorizacaoReducer'

const reducers = { livros, reconhecimentos, registros, cursos, ies, autorizacoes }

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
}

const rootReducer = combineReducers(reducers)
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = () =>
    createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))

import * as React from 'react'
import { Col, Form, Card } from 'react-bootstrap'
import ResponsavelRegistroDiploma from '../../Publica/ResponsavelRegistroDiploma'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorMessage, Field } from 'formik'
import ptBR from 'date-fns/locale/pt-BR';
registerLocale("ptBR", ptBR);

class RegistroNovoCamposLivroRegistro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        if (this.props.currentStep !== 3) {
            return null
        }
        return (
            <Card>
                <Card.Header>
                    <h3>{this.props.currentStep} - Livro de Registro</h3>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        {/* <Form.Group as={Col} sm={12} md={8} lg={4}>
                            <Form.Label>Livro:</Form.Label>
                            <Form.Control
                                as="select"
                                name="LivroID"
                                id="LivroID"
                                className={`form-control ${this.props.touched.LivroID && this.props.errors.LivroID ? 'is-invalid' : ''}`}
                                onChange={this.props.handleInputChange}
                                value={this.props.LivroID}
                                required>
                                {this.props.livros.map((option, index) => (
                                    <option key={option.livroID} value={option.livroID}>{option.nome}</option>
                                ))}
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="LivroID"
                                className="invalid-feedback"
                            />
                        </Form.Group> */}
                        
                        <div className="mb-3 col-auto">
                            <Form.Label>Livro</Form.Label> <br></br>
                            <input className="form-control-plaintext" value={this.props.LivroRegistro} readOnly type="text" />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>Nº do Processo:</Form.Label>
                            <Field
                                type="text"
                                title="Nº do Processo"
                                name="Processo"
                                id="Processo"
                                placeholder="Ex.: 23108.999999/9999-99"
                                onChange={this.props.handleInputChange}
                                value={this.props.Processo}
                                required
                                className={`form-control ${this.props.touched.Processo && this.props.errors.Processo ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage
                                component="div"
                                name="Processo"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                  {/*   //TODO: Processo deve ser obrigatorio? no xml nao é
                  
                  <Form.Row>
                        <Form.Group as={Col} sm={12} md={6} lg={4}>
                            <Form.Label>Numero Folha do Diploma:</Form.Label>
                            <Field
                                type="text"
                                maxLength="5"
                                title="Numero Folha do Diploma"
                                name="NumeroFolhaDoDiploma"
                                id="NumeroFolhaDoDiploma"
                                onChange={this.props.handleInputChange}
                                className={`form-control ${this.props.touched.NumeroFolhaDoDiploma && this.props.errors.NumeroFolhaDoDiploma ? 'is-invalid' : ''}`}
                                value={this.props.NumeroFolhaDoDiploma}
                                required
                            />
                            <ErrorMessage
                                component="div"
                                name="NumeroFolhaDoDiploma"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={6} lg={4}>
                            <Form.Label htmlFor="email">
                                Numero Sequencia do Diploma:
                            </Form.Label>
                            <Field
                                type="text"
                                maxLength="5"
                                title="Numero Sequencia do Diploma"
                                name="NumeroSequenciaDoDiploma"
                                id="NumeroSequenciaDoDiploma"
                                onChange={this.props.handleInputChange}
                                value={this.props.NumeroSequenciaDoDiploma}
                                required
                                className={`form-control ${
                                    this.props.touched
                                        .NumeroSequenciaDoDiploma &&
                                    this.props.errors.NumeroSequenciaDoDiploma
                                        ? 'is-invalid'
                                        : ''
                                }`} />
                            <ErrorMessage
                                component="div"
                                name="NumeroSequenciaDoDiploma"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                    </Form.Row> */}
                    <div className="row">
                        <div className="mb-3 col-auto">
                            <Form.Label>Data de Colação</Form.Label> <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={this.props.DataColacao}
                                className={`form-control ${this.props.touched.DataColacao && this.props.errors.DataColacao ? 'is-invalid' : ''}`}
                                onChange={(date) => this.props.handleInputChangeDate(date,'DataColacao')} />
                            <ErrorMessage
                                component="div"
                                name="DataColacao"
                                className="invalid-feedback"
                            />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>Data de Expedição</Form.Label> <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={this.props.DataExpedicao}
                                className={`form-control ${this.props.touched.DataExpedicao && this.props.errors.DataExpedicao ? 'is-invalid' : ''}`}
                                onChange={(date) => this.props.handleInputChangeDate(date, 'DataExpedicao') }/>
                            <ErrorMessage
                                component="div"
                                name="DataExpedicao"
                                className="invalid-feedback"
                            />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>Data de Registro</Form.Label> <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"                            
                                selected={this.props.DataRegistro}
                                className={`form-control ${this.props.touched.DataRegistro && this.props.errors.DataRegistro ? 'is-invalid' : '' }`}
                                onChange={(date) => this.props.handleInputChangeDate(date,'DataRegistro')} />
                            <ErrorMessage
                                component="div"
                                name="DataRegistro"
                                className="invalid-feedback"
                            />
                        </div>
                    </div>
                    
                    <ResponsavelRegistroDiploma
                            responsavelRegistro={this.props.responsavelRegistro}
                        />
                </Card.Body>
            </Card>
        )
    }
}
export default RegistroNovoCamposLivroRegistro
export const cnpjMask = (value) => {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') 
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
  }

  export const cpfMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
  }  

  export const IrPortal = () => {
    if (window.location.host.includes("localhost") === false) {
        const currentURL = window.location.href;
        if(currentURL.indexOf("localizador") !== -1){
            console.log('localizar')
            const urlParameters = currentURL.split("=")
            const localizador = urlParameters[1];    
            console.log(`/Registro/Localizar?localizador=${localizador}`);
            window.location.href = `/Registro/Localizar?localizador=${localizador}`;
        } else {
          window.location.href = `${process.env.REACT_APP_PORTAL_SISTEMAS}/sistema/SemPermissao/${process.env.REACT_APP_ID_SISTEMA}`;
        }      
    }
  }
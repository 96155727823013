import * as React from 'react'
import { Row, Col, ListGroup } from 'react-bootstrap'

export class AtoRegulatorioDados extends React.Component {
    render() {
        function FormatDate(props) {
            if (props.data != null) {
                let dataArray1 = props.data.split('T')
                let dataArray = dataArray1[0].split('-')
                return (
                    <span>
                        {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
                    </span>
                )
            } else {
                return <span></span>
            }
        }

        function DataPublicacao(props) {
            if (props.dataPublicacao != null) {
                return (                    
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                        <div className='fonte-cinza'>
                                Data de Publicação:{' '}
                                <FormatDate data={props.dataPublicacao} />
                        </div>
                    </div>
                </ListGroup.Item>
                )
            } else {
                return null
            }
        }
        function SecaoPublicacao(props) {
            if (props.secaoPublicacao != null) {
                return (                 
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                            <div className='fonte-cinza'>
                            Seção: {props.secaoPublicacao}
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            } else {
                return null
            }
        }
        function PaginaPublicacao(props) {
            if (props.paginaPublicacao != null) {
                return (     
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                            <div className='fonte-cinza'>
                            Página: {props.paginaPublicacao}
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            } else {
                return null
            }
        }

        return (
            <>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                    <div className="fw-bold"><h5>{this.props.tipo}</h5></div>
                        <div className='fonte-cinza'>
                        {this.props.dados.tipo}, Nº {this.props.dados.numero}
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                        <div className='fonte-cinza'>
                        Data: <FormatDate data={this.props.dados.data} />
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                        <div className='fonte-cinza'>
                        {(this.props.dados.veiculoPublicacao != null)
                        ?
                        this.props.dados.veiculoPublicacao
                        : null
                        } 
                        {
                        (this.props.dados.numeroPublicacao == null && this.props.dados.numeroDOU != null) 
                        ? ", Nº "+ this.props.dados.numeroDOU 
                        : 
                            (this.props.dados.numeroPublicacao != null) 
                            ?", Nº "+ this.props.dados.numeroPublicacao
                            :null
                        } 
                        </div>
                    </div>
                </ListGroup.Item>
                <DataPublicacao
                    dataPublicacao={this.props.dados.dataPublicacao}
                />
                <SecaoPublicacao
                    secaoPublicacao={this.props.dados.secaoPublicacao}
                />
                <PaginaPublicacao
                    paginaPublicacao={this.props.dados.paginaPublicacao}
                />
            </>
        )
    }
}

export default AtoRegulatorioDados

import * as React from 'react'
import { Col, Card, Breadcrumb, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import apiAxios from './../../api/apiAxios';
import ReconhecimentoDetalhe from '../../components/Area/CAE/ReconhecimentoDetalhe'
import apiURLs from './../../api/apiURLs'
import Swal from 'sweetalert2'
import { Formik, Field, ErrorMessage } from 'formik'
import TabelaReconhecimentosCursos from './../../components/Area/CAE/TabelaReconhecimentoCursos'
import NovoReconhecimentoCursoForm from './../../components/Area/CAE/NovoReconhecimentoCursoForm';
import { IrPortal } from "../../util/funcoes";

export default class ReconhecimentoVinculaCursos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            AtoRegulatorioUid: 0,
            atoRegulatorio: {} ,
            ReconhecimentosCursos: [],
            updateCursosNeed: false,
            cursos:[]
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        this.updateListReconhecimentoCursos = this.updateListReconhecimentoCursos.bind(this)
    }

    componentDidMount() {
        if(this.props.match.params.id != undefined){
            this.setAtoRegulatorio(); 
            this.setReconhecimentoCursos();
        }
        this.setCursos();
    }
    componentDidUpdate() {
        if (this.state.updateCursosNeed) {
            this.setReconhecimentoCursos()

            this.setState({
                updateCursosNeed: false,
            })
        }
    }
    
    setCursos() {
        const url = apiURLs.GET_CURSOS_ATIVOS
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ cursos: result.data })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }
    setReconhecimentoCursos() {
        Swal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            title: 'Por favor aguarde!',
            html: 'Carregando cursos vinculados...',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })

        //const url = `https://localhost:5001/api/reconhecimento/cursos-ato-regulatorio/${this.props.match.params.id}`;
        const url = apiURLs.GET_CURSO_VINCULADOS_RECONHECIMENTO_CURSOS + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    ReconhecimentosCursos: result.data,
                })
                Swal.close()
            })
            .catch((ex) => {
                console.log(ex)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar ação!',
                    html:
                        'Tente novamente. ' +
                        'Se erro persistir entre em contato com o administrador do sistema.',
                })
            }) 
    }
    updateListReconhecimentoCursos() {
        this.setState({
            updateCursosNeed: true,
        })
    }
    setAtoRegulatorio() {
        //const url = `https://localhost:5001/api/reconhecimento/ato-regulatorio/dados/${this.props.match.params.id}`;        
        const url = apiURLs.GET_DADOS_ATO_REGULATORIO + this.props.match.params.id;
        apiAxios
            .get(url)
            .then((result) => {
                console.log( result.data)
                this.setState({ 
                    atoRegulatorio: result.data,
                    AtoRegulatorioUid: result.data.atoRegulatorioUid
                })
            })
            .catch((ex) => {
                console.log(ex)
            })
    }    
    
    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./reconhecimentos">
                                Consulta de Atos Regulatórios de Reconhecimento de Curso(s)
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Vincular Cursos ao Ato Regulatório de Reconhecimento de Curso(s)
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>
                            Vincular Cursos ao Ato Regulatório de Reconhecimento de Curso(s)
                            </h2>
                        </Card.Title>  
                        
                        <ReconhecimentoDetalhe
                            atoRegulatorio={this.state.atoRegulatorio}
                        />

                    <Formik
                        initialValues={{   
                            ProcessoReconhecimento: this.state.ProcessoReconhecimento,                      
                        }}

                        onSubmit={(values, { setSubmitting }) => {
                        }}
                    >
                        {({ touched, errors, isSubmitting, handleSubmit }) => (
                            <Form
                                name="novoReconhecimentoForm"
                                id="novoReconhecimentoForm"
                                onSubmit={handleSubmit}
                            >                               
                                
                                <Card>
                                    <Card.Body>
                                        <TabelaReconhecimentosCursos
                                            reconhecimentosCursos={this.state.ReconhecimentosCursos}
                                            updateListReconhecimentoCursos={
                                                this.updateListReconhecimentoCursos
                                            }
                                        />
                                        <Card.Body>
                                            <Card.Title>
                                                {' '}
                                                Adicionar Curso
                                            </Card.Title>
                                            <NovoReconhecimentoCursoForm
                                                AtoRegulatorioUid={this.state.AtoRegulatorioUid}
                                                ProcessoReconhecimento={this.state.ProcessoReconhecimento}
                                                updateListReconhecimentoCursos={
                                                    this.updateListReconhecimentoCursos
                                                }
                                                cursos={this.state.cursos}
                                            />
                                        </Card.Body>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                        <Link className="btn btn-primary" to="/reconhecimentos">
                            Voltar
                        </Link>{' '}
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

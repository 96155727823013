import * as React from 'react'
import { Col, Form, Card } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorMessage } from 'formik'
import ptBR from 'date-fns/locale/pt-BR';
registerLocale("ptBR", ptBR);

class RegistroNovoCamposIngressoCurso extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        if (this.props.currentStep !== 5) {
            return null
        }
        return (
            <Card>
                <Card.Header>
                    <h3>
                        {this.props.currentStep} - Dados de Ingresso no Curso
                    </h3>
                </Card.Header>
                <Card.Body>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={12} lg={3}>
                            <Form.Label>
                                Forma de Acesso para Registro do Diploma:
                            </Form.Label>
                            <Form.Control
                                as="select"
                                title="Forma de Acesso para Registro do Diploma"
                                name="FormaAcesso"
                                id="FormaAcesso"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.FormaAcesso && this.props.errors.FormaAcesso ? 'is-invalid' : ''}`}
                                value={this.props.FormaAcesso}
                                required>
                                <option value="">**** Selecione a Forma de Acesso ****</option>
                                <option key="Convenios" value="Convenios">Convênios</option>
                                <option key="Sisu" value="Sisu">Sisu</option>
                                <option key="Enem" value="Enem">Enem</option>
                                <option key="Vestibular" value="Vestibular">Vestibular</option>
                                <option key="ProvaAgendada" value="ProvaAgendada">Prova agendada</option>
                                <option key="Entrevista" value="Entrevista">Entrevista</option>
                                <option key="Transferencia" value="Transferencia">Transferência</option>
                                <option key="Outros" value="Outros">Outros</option>
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="FormaAcesso"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={12} lg={6}>
                            <Form.Label>Forma de Acesso:</Form.Label>
                            <br></br>
                            {this.props.TipoIngressoDescricao}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={6} lg={2}>
                            <Form.Label>Data de Ingresso:</Form.Label>
                            <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={this.props.DataIngresso}
                                className={`form-control ${this.props.touched.DataIngresso && this.props.errors.DataIngresso ? 'is-invalid' : ''}`}
                                onChange={(date) => this.props.handleInputChangeDate(date, 'DataIngresso')}                            />
                            <ErrorMessage
                                component="div"
                                name="DataIngresso"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={6} lg={2}>
                            <Form.Label>Data de Conclusão:</Form.Label>
                            <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"                            
                                selected={this.props.DataConclusao}
                                className={`form-control ${this.props.touched.DataConclusao && this.props.errors.DataConclusao ? 'is-invalid' : ''}`}
                                onChange={(date) => this.props.handleInputChangeDate(date, 'DataConclusao')}
                            />
                            <ErrorMessage
                                component="div"
                                name="DataConclusao"
                                className="invalid-feedback"
                            />
                        </Form.Group>
                    </Form.Row>
                </Card.Body>
            </Card>
        )
    }
}

export default RegistroNovoCamposIngressoCurso
const types = {
    FAILURE: 'FAILURE',
    GET_REGISTROS: 'GET_REGISTROS',
    GET_REGISTRO_POR_ID: 'GET_REGISTRO_POR_ID',
    GET_REGISTRO_POR_RGA: 'GET_REGISTRO_POR_RGA',
    GET_DADOS_REGISTRO_POR_ID: 'GET_DADOS_REGISTRO_POR_ID',
    GET_DADOS_REGISTRO_POR_RGA: 'GET_DADOS_REGISTRO_POR_RGA',
    ADD_LIVRO_REGISTRO: 'ADD_LIVRO_REGISTRO',
    LOAD_LIVROS_IN_PROGRESS: 'LOAD_LIVROS_IN_PROGRESS',
    LOAD_LIVROS_SUCCESS: 'LOAD_LIVROS_SUCCESS',
    LOAD_LIVROS_FAILURE: 'LOAD_LIVROS_FAILURE',
    EDIT_LIVRO_REGISTRO: 'EDIT_LIVRO_REGISTRO',
    GET_LIVROS_REGISTRO: 'GET_LIVROS_REGISTRO',
    GET_LIVRO_REGISTRO: 'GET_LIVRO_REGISTRO',
    REMOVE_LIVRO_REGISTRO: 'REMOVE_LIVRO_REGISTRO',
    REMOVE_LIVRO_REGISTRO_FAILURE: 'REMOVE_LIVRO_REGISTRO_FAILURE',
    LOAD_RECONHECIMENTOS_IN_PROGRESS: 'LOAD_RECONHECIMENTOS_IN_PROGRESS',
    LOAD_RECONHECIMENTOS_SUCCESS: 'LOAD_RECONHECIMENTOS_SUCCESS',
    LOAD_RECONHECIMENTOS_FAILURE: 'LOAD_RECONHECIMENTOS_FAILURE',
    LOAD_REGISTROS_SELECIONADOS_IN_PROGRESS: 'LOAD_REGISTROS_SELECIONADOS_IN_PROGRESS',
    LOAD_REGISTROS_SELECIONADOS_SUCCESS: 'LOAD_REGISTROS_SELECIONADOS_SUCCESS',
    LOAD_REGISTROS_SELECIONADOS_FAILURE: 'LOAD_REGISTROS_SELECIONADOS_FAILURE',
    LOAD_REGISTROS_IN_PROGRESS: 'LOAD_REGISTROS_IN_PROGRESS',
    LOAD_REGISTROS_SUCCESS: 'LOAD_REGISTROS_SUCCESS',
    LOAD_REGISTROS_FAILURE: 'LOAD_REGISTROS_FAILURE',
    LOAD_REGISTRO_POR_ID_IN_PROGRESS: 'LOAD_REGISTRO_POR_ID_IN_PROGRESS',
    LOAD_REGISTRO_POR_ID_SUCCESS: 'LOAD_REGISTRO_POR_ID_SUCCESS',
    LOAD_REGISTRO_POR_ID_FAILURE: 'LOAD_REGISTRO_POR_ID_FAILURE',
    LOAD_ANOTACOES_IN_PROGRESS: 'LOAD_ANOTACOES_IN_PROGRESS',
    LOAD_ANOTACOES_SUCCESS: 'LOAD_ANOTACOES_SUCCESS',
    LOAD_ANOTACOES_FAILURE: 'LOAD_ANOTACOES_FAILURE',
    ADD_NOVA_DATA_PUBLICACAO_LOTE_REGISTRO: 'ADD_NOVA_DATA_PUBLICACAO_LOTE_REGISTRO',
    ADD_NOVA_DATA_PUBLICACAO_REGISTRO: 'ADD_NOVA_DATA_PUBLICACAO_REGISTRO',
    CANCELA_REGISTRO: 'CANCELA_REGISTRO',
    REVOGA_CANCELAMENTO_REGISTRO: 'REVOGA_CANCELAMENTO_REGISTRO',
    LOAD_ESTUDANTES_FORMANDOS_IN_PROGRESS: 'LOAD_ESTUDANTES_FORMANDOS_IN_PROGRESS',
    LOAD_ESTUDANTES_FORMANDOS_SUCCESS: 'LOAD_ESTUDANTES_FORMANDOS_SUCCESS',
    LOAD_ESTUDANTES_FORMANDOS_FAILURE: 'LOAD_ESTUDANTES_FORMANDOS_FAILURE',
    LOAD_ESTUDANTES_IN_PROGRESS: 'LOAD_ESTUDANTES_IN_PROGRESS',
    LOAD_ESTUDANTES_SUCCESS: 'LOAD_ESTUDANTES_SUCCESS',
    LOAD_ESTUDANTES_FAILURE: 'LOAD_ESTUDANTES_FAILURE',
    LOAD_DADOS_FORMANDO_IN_PROGRESS: 'LOAD_DADOS_FORMANDO_IN_PROGRESS',
    LOAD_DADOS_FORMANDO_SUCCESS: 'LOAD_DADOS_FORMANDO_SUCCESS',
    LOAD_DADOS_FORMANDO_FAILURE: 'LOAD_DADOS_FORMANDO_FAILURE',
    ADD_NOVO_REGISTRO: 'ADD_NOVO_REGISTRO',
    ADD_NOVO_REGISTRO_IN_PROGRESS: 'ADD_NOVO_REGISTRO_IN_PROGRESS',
    ADD_NOVO_REGISTRO_SUCCESS: 'ADD_NOVO_REGISTRO_SUCCESS',
    ADD_NOVO_REGISTRO_FAILURE: 'ADD_NOVO_REGISTRO_FAILURE',
    LOAD_CAMPUS_IN_PROGRESS: 'LOAD_CAMPUS_IN_PROGRESS',
    LOAD_CAMPUS_SUCCESS: 'LOAD_CAMPUS_SUCCESS',
    LOAD_CAMPUS_FAILURE: 'LOAD_CAMPUS_FAILURE',
    LOAD_ANOS_IN_PROGRESS: 'LOAD_ANOS_IN_PROGRESS',
    LOAD_ANOS_SUCCESS: 'LOAD_ANOS_SUCCESS',
    LOAD_ANOS_FAILURE: 'LOAD_ANOS_FAILURE',
    LOAD_DATAS_COLACAO_IN_PROGRESS: 'LOAD_DATAS_COLACAO_IN_PROGRESS',
    LOAD_DATAS_COLACAO_SUCCESS: 'LOAD_DATAS_COLACAO_SUCCESS',
    LOAD_DATAS_COLACAO_FAILURE: 'LOAD_DATAS_COLACAO_FAILURE',
    LOAD_UFS_IN_PROGRESS: 'LOAD_UFS_IN_PROGRESS',
    LOAD_UFS_SUCCESS: 'LOAD_UFS_SUCCESS',
    LOAD_UFS_FAILURE: 'LOAD_UFS_FAILURE',
    LOAD_MUNICIPIOS_IN_PROGRESS: 'LOAD_MUNICIPIOS_IN_PROGRESS',
    LOAD_MUNICIPIOS_SUCCESS: 'LOAD_MUNICIPIOS_SUCCESS',
    LOAD_MUNICIPIOS_FAILURE: 'LOAD_MUNICIPIOS_FAILURE',
    LOAD_CURSO_IN_PROGRESS: 'LOAD_CURSO_IN_PROGRESS',
    LOAD_CURSO_SUCCESS: 'LOAD_CURSO_SUCCESS',
    LOAD_CURSO_FAILURE: 'LOAD_CURSO_FAILURE',
    LOAD_CURSOS_GRAUS_IN_PROGRESS: 'LOAD_CURSOS_GRAUS_IN_PROGRESS',
    LOAD_CURSOS_GRAUS_SUCCESS: 'LOAD_CURSOS_GRAUS_SUCCESS',
    LOAD_CURSOS_GRAUS_FAILURE: 'LOAD_CURSOS_GRAUS_FAILURE',
    LOAD_CURSO_FORMACOES_IN_PROGRESS: 'LOAD_CURSOS_FORMACOES_IN_PROGRESS',
    LOAD_CURSO_FORMACOES_SUCCESS: 'LOAD_CURSOS_FORMACOES_SUCCESS',
    LOAD_CURSO_FORMACOES_FAILURE: 'LOAD_CURSOS_FORMACOES_FAILURE',
    LOAD_CURSO_TITULACOES_IN_PROGRESS: 'LOAD_CURSOS_TITULACOES_IN_PROGRESS',
    LOAD_CURSO_TITULACOES_SUCCESS: 'LOAD_CURSOS_TITULACOES_SUCCESS',
    LOAD_CURSO_TITULACOES_FAILURE: 'LOAD_CURSOS_TITULACOES_FAILURE',
    LOAD_CURSO_RECONHECIMENTOS_IN_PROGRESS: 'LOAD_CURSO_RECONHECIMENTOS_IN_PROGRESS',
    LOAD_CURSO_RECONHECIMENTOS_SUCCESS: 'LOAD_CURSO_RECONHECIMENTOS_SUCCESS',
    LOAD_CURSO_RECONHECIMENTOS_FAILURE: 'LOAD_CURSO_RECONHECIMENTOS_FAILURE',
    LOAD_IES_EMISSORA_IN_PROGRESS: 'LOAD_IES_EMISSORA_IN_PROGRESS',
    LOAD_IES_EMISSORA_SUCCESS: 'LOAD_IES_EMISSORA_SUCCESS',
    LOAD_IES_EMISSORA_FAILURE: 'LOAD_IES_EMISSORA_FAILURE',
    LOAD_IES_REGISTRADORA_IN_PROGRESS: 'LOAD_IES_REGISTRADORA_IN_PROGRESS',
    LOAD_IES_REGISTRADORA_SUCCESS: 'LOAD_IES_REGISTRADORA_SUCCESS',
    LOAD_IES_REGISTRADORA_FAILURE: 'LOAD_IES_REGISTRADORA_FAILURE',
    LOAD_ESTUDANTE_ENADE_IN_PROGRESS: 'LOAD_ESTUDANTE_ENADE_IN_PROGRESS',
    LOAD_ESTUDANTE_ENADE_SUCCESS: 'LOAD_ESTUDANTE_ENADE_SUCCESS',
    LOAD_ESTUDANTE_ENADE_FAILURE: 'LOAD_ESTUDANTE_ENADE_FAILURE',
    CLEAR_ESTUDANTES: 'CLEAR_ESTUDANTES',
    CLEAR_ESTUDANTES_FORMANDOS: 'CLEAR_ESTUDANTES_FORMANDOS',
    ADD_LIVRO_REGISTRO_FAILURE: 'ADD_LIVRO_REGISTRO_FAILURE',
    LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_IN_PROGRESS: 'LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_IN_PROGRESS',
    LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_SUCCESS: 'LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_SUCCESS',
    LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_FAILURE: 'LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_FAILURE',
    LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_IN_PROGRESS: 'LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_IN_PROGRESS',
    LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_SUCCESS: 'LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_SUCCESS',
    LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_FAILURE: 'LOAD_ATOS_REGULATORIOS_AUTORIZACAO_CURSO_FAILURE',
    SET_SELECTED_REGISTROS_IN_PROGRESS: 'SET_SELECTED_REGISTROS_IN_PROGRESS',
    SET_SELECTED_REGISTROS_SUCCESS: 'SET_SELECTED_REGISTROS_SUCCESS',
    SET_SELECTED_REGISTROS_FAILURE: 'SET_SELECTED_REGISTROS_FAILURE',
}

export default types

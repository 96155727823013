import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addNovaDataPublicacaoRequest } from '../../../store/thunks'
import { format } from 'date-fns'

const GravarPublicacaoForm = ({
    registroDOUData,
    registroId,
    onCreatePressed,
}) => {
    const history = useHistory()
    const [inputValue, setInputValue] = React.useState('')

    const handleRedirect = () => {
        history.push('/registros')
    }
    return (
        <Form name="novaDataPublicacaoForm">
            <div className="row ">
                <div className=" col-auto mb-3" controlId="formGridNome">
                    <Form.Label>Data de Publicação atual: </Form.Label>
                    <b className="form-control-plaintext">
                        {registroDOUData == null
                            ? 'Não Publicado'
                            : format(
                                  new Date(Date.parse(registroDOUData)),
                                  'dd/MM/yyyy'
                              )}
                    </b>
                </div>
            </div>
            <div className="row mb-3">
                <Form.Group as={Col} controlId="formGridNome" style={{ maxWidth: "250px"} }>
                    <Form.Label>Nova data de Publicação:</Form.Label>
                    <input
                        type="date"
                        title="data"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="form-control"
                        required
                    />
                </Form.Group>
            </div>

            <div className="d-flex gap-3">
                <Link className="btn btn-primary" to="/registros">
                    Voltar
                </Link>

                <Button
                    variant="success"
                    type="button"
                    onClick={() => {
                        if (inputValue) {
                            onCreatePressed(inputValue, registroId)
                            setInputValue('')
                            handleRedirect()
                        }
                    }}
                >
                    Publicar
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    onCreatePressed: (data, id) =>
        dispatch(addNovaDataPublicacaoRequest(data, id)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GravarPublicacaoForm)

import * as React from 'react'
import { Card } from 'react-bootstrap'
import IESDados from './IESDados'

export class IESEmissoraDados extends React.Component {
    render() {
        return (
            <Card id="iesEmissora">
                <Card.Body>
                    <Card.Title>
                        <h3>IES Emissora</h3>
                    </Card.Title>
                    <IESDados
                        ies={this.props.ies}
                        endereco={this.props.endereco}
                        credenciamento={this.props.credenciamento}
                        recredenciamento={this.props.recredenciamento}
                        mantenedora={this.props.mantenedora}
                        enderecoMantenedora={this.props.enderecoMantenedora}
                        mostrarReduzido={this.props.mostrarReduzido}
                    />
                </Card.Body>
            </Card>
        )
    }
}

export default IESEmissoraDados

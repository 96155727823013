import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import * as api from '../../api/api'
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { exibirMensagemAguarde } from "../../util/util";
import Menu from '../../components/Menu';

const RegistroPublicoDetalhe = () => {
    const [registro, setRegistro] = useState({});
    const [anotacoes, setAnotacoes] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        exibirMensagemAguarde('Por favor aguarde!', 'Carregando registro.');

        Promise.all([
            api.getRegistroPublicoPorID(id).then((resultado) => {
                setRegistro(resultado);
            }),

            api.getRegistroAnotacoesPorID(id).then((resultado) => {
                setAnotacoes(resultado);
            })
        ]).then(() =>
            Swal.close()
        );
    }, [id])

    return (
        <React.Fragment>
            <Menu
                title={"Registro Detalhes"}
                menus={[
                    { path: "/", desc: "Consultar Registros por Periodo" },
                ]} />

            <h3 className="my-3">Diploma - {registro?.codigoValidacao}</h3>

            <div className="card my-3">
                <div className="card-body">
                    <p className="mb-0">
                        <span className="fw-bold">Situação do registro/diploma: </span>
                        <span className="text-muted">{registro?.cancelado ? "Cancelado" : "Ativo"}</span>
                    </p>

                    {registro?.cancelado === false ?
                        <p className="mb-0">
                            <span className="fw-bold">XML Diploma Digital:</span>
                            <span className="text-muted">O acesso ao XML do Diploma Digital e da Representação Visual do Diploma (RVDD) é feito pela área do Estudante no Portal Acadêmico.</span>
                        </p> : ''}

                    <span className="fw-bold">Anotações/Justificativas: </span>
                    {anotacoes.length > 0 ?
                        anotacoes.map((anotacao) => (
                            <div key={anotacao.registroAnotacaoID}>
                                <div className="row">
                                    <div className="col">{anotacao.justificativa}</div>
                                </div>

                                <span>{format(
                                    new Date(
                                        Date.parse(anotacao.dataJustificativa)
                                    ),
                                    'dd/MM/yyyy HH:mm'
                                )}</span>
                                <br />
                                <small>
                                    {anotacao.responsavel}{' - '}{anotacao.responsavelDocumento}
                                </small>
                            </div>
                        ))
                        :
                        <span className="text-muted">Nenhuma anotação/justificativa cadastrada.</span>
                    }
                </div>

            </div>


            <div className="card my-3">
                <span className="card-header">
                    Diplomado
                </span>
                <div className="card-body">
                    <span className="fw-bold">Nome: </span>
                    <span className="text-muted">{registro?.nome}</span>
                    <br />
                    <span className="fw-bold">CPF: </span>
                    <span className="text-muted">***{registro?.cpf?.substring(3, 6)}{registro?.cpf?.substring(6, 9)}**</span>
                </div>
            </div>


            <div className="card my-3">
                <span className="card-header">
                    Dados do curso
                </span>
                <div className="card-body">
                    <span className="fw-bold">Curso: </span>
                    <span className="text-muted">{registro?.nomeCurso}</span>
                    <br />
                    <span className="fw-bold">Código E-MEC: </span>
                    <span className="text-muted">{registro?.codigoCursoEMEC}</span>
                </div>
            </div>


            <div className="card my-3">
                <span className="card-header">
                    IES Emissora
                </span>
                <div className="card-body">
                    <span className="fw-bold">Nome: </span>
                    <span className="text-muted">{registro?.nomeIesEmissora}</span>
                    <br />
                    <span className="fw-bold">Código E-MEC: </span>
                    <span className="text-muted">{registro?.codigoEmecEmissora}</span>
                </div>
            </div>


            <div className="card my-3">
                <span className="card-header">
                    IES Registradora
                </span>
                <div className="card-body">
                    <span className="fw-bold">Nome: </span>
                    <span className="text-muted">{registro?.nomeIesRegistradora}</span>
                    <br />
                    <span className="fw-bold">Código E-MEC: </span>
                    <span className="text-muted">{registro?.codigoEmecRegistradora}</span>
                </div>
            </div>


            <div className="card my-3">
                <span className="card-header">
                    Dados Gerais
                </span>
                <div className="card-body">
                    <span className="fw-bold">Data de ingresso: </span>
                    <span className="text-muted">{registro?.dataIngresso == "0001-01-01T00:00:00" ? "" : new Date(registro?.dataIngresso).toLocaleDateString()}</span><br />

                    <span className="fw-bold">Data de conclusão: </span>
                    <span className="text-muted">{new Date(registro?.dataConclusao).toLocaleDateString()}</span><br />

                    <span className="fw-bold">Nº de expedição: </span>
                    <span className="text-muted">{registro?.nrRegistro}</span><br />

                    <span className="fw-bold">Data de registro: </span>
                    <span className="text-muted">{new Date(registro?.dataRegistro).toLocaleDateString()}</span><br />

                    <span className="fw-bold">Data de Publicação do Registro no Diário Oficial da União: </span>

                    {registro?.publicacaoRegistro != null ?
                        <span className="text-muted">{new Date(registro?.publicacaoRegistro).toLocaleDateString()}</span> :
                        <span className="text-muted">Não publicado</span>}

                </div>
            </div>

            <Link className="btn btn-info" to="/ConsultaPublica">
                Ir para Consulta Pública de Registro de Diplomas
            </Link>
        </React.Fragment>
    )
}

export default RegistroPublicoDetalhe
import * as React from 'react'
import { Col, Form, Card } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorMessage } from 'formik'
import ptBR from 'date-fns/locale/pt-BR';
registerLocale("ptBR", ptBR);

class RegistroNovoCamposHistoricoEscolar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        if (this.props.currentStep !== 4) {
            return null
        }
        return (
            <Card>
                <Card.Header>
                    <h3>{this.props.currentStep} - Historico Escolar</h3>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <Form.Group as={Col} sm={12} md={8} lg={4}>
                            <Form.Label>Situação Aluno:</Form.Label> <br></br>
                            {this.props.SituacaoAlunoHistorico}
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={6} lg={4}>
                            <Form.Label>Carga Horária Curso:</Form.Label>
                            <br></br>
                            {this.props.CargaHorariaCurso}
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={6} lg={4}>
                            <Form.Label>
                                Carga Horária Curso Integralizada:
                            </Form.Label>
                            <br></br>
                            {this.props.CargaHorariaCursoIntegralizada}
                        </Form.Group>
                    </div>
                    <div className="row my-3">
                        <Form.Group as={Col} sm={12} md={12} lg={6}>
                            <Form.Label>Situação ENADE Ingressante:</Form.Label>
                            <br></br>
                            {this.props.SituacaoENADEIngressante}
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={12} lg={6}>
                            <Form.Label>Situação ENADE Concluinte:</Form.Label>
                            <br></br>
                            {this.props.SituacaoENADEConcluinte}
                        </Form.Group>
                    </div>
                    <div className="row">
                        <div className="mb-3 col-auto">
                            <Form.Label>
                                Situação ENADE para Registro do Diploma:
                            </Form.Label>
                            <Form.Control
                                as="select"
                                title="Situação ENADE para Diploma"
                                name="SituacaoENADE"
                                id="SituacaoENADE"
                                onChange={this.props.handleInputChange}
                                className={`form-select ${this.props.touched.SituacaoENADE && this.props.errors.SituacaoENADE ? 'is-invalid' : ''}`}
                                value={this.props.SituacaoENADE}
                                required>
                                <option value="">**** Selecione a Situação ENADE ****</option>
                                <option value="Irregular">IRREGULAR</option>
                                <option value="IngressanteParticipante">Ingressante / Participante</option>
                                <option value="DispensadoEmRazaoDoCalendarioTrienal">Dispensado, em razão do calendário trienal</option>
                                <option value="ConcluinteParticipante">Concluinte / Participante</option>
                                <option value="DispensadoPeloMEC">Dispensado pelo MEC</option>
                                <option value="DispensadoEmRazaoDaNaturezaDoCurso">Dispensado, em razão da natureza do curso</option>
                                <option value="DispensadoPorRazaoDeOrdemPessoal">Dispensado, por razão de ordem pessoal</option>
                                <option value="DispensadoPorAtoDaInstituicaoDeEnsino">Dispensado, por ato da instituição de ensino</option>
                                <option value="DispensadoEmRazaoDeMobilidadeAcademicaNoExterior">Dispensado, em razão de mobilidade acadêmica no exterior</option>
                            </Form.Control>
                            <ErrorMessage
                                component="div"
                                name="SituacaoENADE"
                                className="invalid-feedback" />
                        </div>
                        <div className="mb-3 col-auto">
                            <Form.Label>
                                Data de Emissão do Histórico:
                            </Form.Label>
                            <br></br>
                            <DatePicker
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"                            
                                selected={this.props.DataEmissaoHistorico}
                                className={`form-control ${this.props.touched.DataEmissaoHistorico && this.props.errors.DataEmissaoHistorico ? 'is-invalid' : ''}`}
                                onChange={(date) => this.props.handleInputChangeDate(date, 'DataEmissaoHistorico')} />
                            <ErrorMessage
                                component="div"
                                name="DataEmissaoHistorico"
                                className="invalid-feedback" />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default RegistroNovoCamposHistoricoEscolar
import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaCursoFormacao from './../../components/Area/CAE/TabelaCursoFormacao'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { IrPortal } from "../../util/funcoes";
//import { loadLivros, removeLivroRequest } from './../../store/thunks'

class CursoFormacaoConsulta extends React.Component {
    static displayName = CursoFormacaoConsulta.name
    constructor(props) {
        super(props)
        this.state = { formacoes: [] }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    componentDidMount() {
        //this.props.dispatch(loadLivros())
    }

    onRemovePressed = (payload) => {
        //this.props.dispatch(removeLivroRequest(payload))
    }

    render() {
        const { formacoes } = this.state //this.props //error, loading,
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Consulta de Formações de Curso
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Link
                            to="/curso-formacoes/novo"
                            className="btn btn-primary"
                        >
                            <i className="fas fa-plus-circle"></i> Novo
                        </Link>

                        <br></br>
                        <TabelaCursoFormacao
                            formacoes={formacoes}
                            onRemovePressed={this.onRemovePressed}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    //livros: state.livros.livros,
    //loading: state.livros.loading,
    //error: state.livros.error,
})

export default connect(mapStateToProps)(CursoFormacaoConsulta)

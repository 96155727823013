import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {Col, Form, Card, Button, Row} from 'react-bootstrap'
import { Field, ErrorMessage } from 'formik'
import { loadCampus, loadAnos, loadDatasColacao } from './../../../store/thunks'
import apiURLs from './../../../api/apiURLs'
import apiAxios from './../../../api/apiAxios'
import Swal from 'sweetalert2'
import { cpfMask } from "./../../../util/funcoes";

class ConsultaIndividualFormandosFiltroForm extends React.Component {
    static displayName = ConsultaIndividualFormandosFiltroForm.name
    constructor(props) {
        super(props)
        this.state = {
            rga: '',
            cpf: '',
            nome: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleLimpaBusca = this.handleLimpaBusca.bind(this)
    }

    componentDidMount() {
    }
    
    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        let regexpOnlyNumber = /^[0-9\b]+$/ //só numero
        let regexpOnlyLetter = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/ //só letra com acentuacao

        if (name === 'nome') {
            if (regexpOnlyLetter.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else if (name === 'rga' || name === "cpf") {
            if (regexpOnlyNumber.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else {
            this.setState({ [name]: value })
        }
    }

    handleSubmit(event) {
        event.preventDefault()
            
        let dadosBuscaEstudanteFormando = {
            Rga: this.state.rga,
            Cpf: this.state.cpf,
            Nome: this.state.nome
        }

        if(this.state.rga == "" && this.state.cpf == "" && this.state.nome == "")
        {            
            Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar busca!',
                html: 'Verifique se os campos foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
            })
            return;
        }

        if(this.state.nome != "" && this.state.nome.length < 10)
        {            
            Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar busca!',
                html: 'O mínimo de caracteres para o Nome são 10 dígitos. Verifique os campos preenchidos e tente novamente.'
            })
            return;
        }

        if(this.state.rga != "" && this.state.rga.length < 10)
        {            
            Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar busca!',
                html: 'O mínimo de caracteres para o RGA são 10 dígitos. Verifique os campos preenchidos e tente novamente.'
            })
            return;
        }

        if(this.state.cpf != "" && this.state.cpf.length < 10)
        {            
            Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar busca!',
                html: 'O mínimo de caracteres para o CPF são 11 dígitos. Verifique os campos preenchidos e tente novamente.'
            })
            return;
        }

        this.props.handleFormSubmit(dadosBuscaEstudanteFormando);
        //this.props.history.push(`/consulta-formandos/:${this.state.dataColacaoId}`)
    }
    handleLimpaBusca() {
        this.setState({ rga: '', cpf:'', nome: ''});
        this.props.handleLimparBusca();
    }

    render() {
        return (
            <Card className="filterArea">
                <Card.Body>
                    <Form
                        id="filterForm"
                        name="filterForm"
                        onSubmit={this.handleSubmit}>
                        <Row className="align-items-center">
                            <Form.Group as={Col} sm={12} md={6} lg={6}>
                                <Form.Label>Nome:</Form.Label>
                                <input
                                    type="text"
                                    title="Nome Completo"
                                    name="nome"
                                    id="nome"
                                    placeholder="Ex.: José da Silva"
                                    maxLength="255"
                                    min="10"
                                    onChange={this.handleInputChange}
                                    className={`form-control`}
                                    value={this.state.nome}
                                />
                                {/* <ErrorMessage
                                    component="div"
                                    name="Nome"
                                    className="invalid-feedback"
                                /> */}
                            </Form.Group>  

                           {/*  <Form.Group as={Col} sm={12} md={10} lg={3} className="btn-filtro-consulta-formandos">
                                <Button type="submit" className="btn btn-primary">
                                    <i className="fas fa-search"></i>{' '}Consultar
                                </Button>{' '}
                            </Form.Group>   */}          
                        </Row>
                        <Row className="align-items-center">
                            <Form.Group as={Col} sm={12} md={6} lg={2}>
                                <Form.Label>RGA:</Form.Label>
                                <input
                                    type="text"
                                    title="RGA"
                                    name="rga"
                                    id="rga"
                                    placeholder="Ex.: 99999999999"
                                    maxLength="20"
                                    min="10"
                                    onChange={this.handleInputChange}
                                    className={`form-control`}
                                    value={this.state.rga}
                                />
                            </Form.Group>   

                            {/* <Form.Group as={Col} sm={12} md={10} lg={3} className="btn-filtro-consulta-formandos">
                                <Button type="submit" className="btn btn-primary">
                                    <i className="fas fa-search"></i>{' '}Consultar
                                </Button>{' '}
                            </Form.Group>   */}                        
                        </Row>
                        <Row className="align-items-center">
                            <Form.Group as={Col} sm={12} md={6} lg={2}>
                                <Form.Label>CPF:</Form.Label>
                                <input
                                    type="text"
                                    title="CPF"
                                    name="cpf"
                                    id="cpf"
                                    placeholder="Ex.: 9999999999"
                                    maxLength="11"
                                    min="11"
                                    onChange={this.handleInputChange}
                                    className={`form-control`}
                                    value={this.state.cpf}
                                />
                            </Form.Group>                          
                        </Row>
                        <Row>    

                        <Form.Group as={Col} sm={12} md={10} lg={3} className="btn-filtro-consulta-formandos">
                                           
                                <Button className="btn btn-primary" onClick={() => this.handleLimpaBusca()}>
                                   Limpar busca
                                </Button>{' '}
                                <Button type="submit" className="btn btn-primary">
                                <i className="fas fa-search"></i>{' '}Consultar
                            </Button>{' '}
                        </Form.Group> 
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    estudantes: state.registros.estudantes,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(ConsultaIndividualFormandosFiltroForm)
import * as React from 'react'
import { Card, Breadcrumb, Button } from 'react-bootstrap'
import LivroRegistroDetalhe from '../../components/Area/Publica/LivroRegistroDetalhe'
import { Link } from 'react-router-dom'
import { IrPortal } from "../../util/funcoes";

export default class LivroRegistroConsulta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            livro: [],
            livroId: 0,
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        this.handleExcluir = this.handleExcluir.bind(this)
    }
    handleExcluir(event) {}
    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item href="./cae">
                                Página Inicial - CAE
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="./livros">
                                Consulta de Livros de Registro
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Excluir Livro de Registro
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Excluir Livro de Registro</h2>
                        </Card.Title>
                        <LivroRegistroDetalhe
                            livroRegistro={this.state.livro}
                        />
                        <Link className="btn btn-primary" to="/livros">
                            Voltar
                        </Link>{' '}
                        <Button
                            variant="danger"
                            type="button"
                            onClick={this.handleExcluir}
                        >
                            Excluir
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

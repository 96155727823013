import ActionTypes from './actionTypes'

const initialState = {
    reconhecimentos: [],
    loading: false,
    error: null,
}

export default function reconhecimentoReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.ADD_RECONHECIMENTO_REGISTRO: {
            const reconhecimento = payload
            const newState = state.reconhecimentos.concat(reconhecimento)
            return {
                ...state,
                loading: false,
                reconhecimentos: newState,
            }
        }
        case ActionTypes.REMOVE_RECONHECIMENTO_REGISTRO: {
            const id = payload
            const newState = state.reconhecimentos.filter(
                (reconhecimento) => reconhecimento.reconhecimentoID !== id
            )
            return {
                ...state,
                loading: false,
                reconhecimentos: newState,
            }
        }
        case ActionTypes.LOAD_RECONHECIMENTOS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_RECONHECIMENTOS_SUCCESS: {
            return {
                ...state,
                loading: false,
                reconhecimentos: payload.reconhecimentos,
            }
        }
        case ActionTypes.LOAD_RECONHECIMENTOS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                reconhecimentos: [],
            }
        }
        case ActionTypes.LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_IN_PROGRESS: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case ActionTypes.LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_SUCCESS: {
            return {
                ...state,
                loading: false,
                reconhecimentos: payload.reconhecimentos,
            }
        }
        case ActionTypes.LOAD_ATOS_REGULATORIOS_RECONHECIMENTOS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                reconhecimentos: [],
            }
        }
        default:
            return state
    }
}

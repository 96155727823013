import React from "react";
import { Breadcrumb, Card, Form, Button, Col, Table, Spinner, Row, Alert } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IrPortal } from "../../util/funcoes";
import apiAxios from "../../api/apiAxios";
import { Link } from "react-router-dom";

export class CadastrarDocumento extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rga: "",
            aluno: null,
            tipos: [],
            tipoId: 0,
            arquivoName: "Selecione o arquivo",
            arquivo: null,
            observacao: "",
            documentos: [],
            docsObrigatorios: [],
            isLoadingDocumentosEnviado: false
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    async componentDidMount() {
        this.getTiposDocumentos();
        if(this.props.match.params.rga != undefined){
            this.setState({ rga: this.props.match.params.rga })
        }
    }

    getDocumentos = async () => {
        this.setState({ isLoadingDocumentosEnviado: true });
        const result = await apiAxios.get(`/api/Documentacao/${this.state.rga}`);
        const documentos = result.data;
        this.setState({ documentos, isLoadingDocumentosEnviado: false });
    }

    getTiposDocumentos = async () => {
        const result = await apiAxios.get("/api/Documentacao/tipos");
        const tipos = result.data;
        this.setState({ tipos });
        if (tipos.length > 0) {
            this.setState({ tipoId: tipos[0].documentoTipoId, observacao: tipos[0].nome });
        }
    }

    CheckTamanho(file) {
        const tamanho = file.size / 1024 / 1024;
        const tipo = file.type;

        if (tamanho >= 7) {
            return false;
        }

        return true;
    }

    ExcluirDocumento = async (id, index) => {
        const result = await Swal.fire({
            title: 'Deseja realmente excluir esse arquivo?',
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        });

        if (result.isConfirmed) {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Excluindo Arquivo',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            await apiAxios.get(`/api/Documentacao/excluir/${id}`);
            const documentos = [...this.state.documentos];
            documentos.splice(index, 1);
            this.setState({ documentos });
            Swal.close();
            Swal.fire('Arquivo excluido!', '', 'success');
        }
    }

    EnviarHistoricoApp = async () => {
        if (this.state.tipoId <= 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Tipo do Arquivo não selecionado!'
            });
            return;
        }

        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Enviando Arquivo',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });

        let form = new FormData();
        form.append("rga", this.state.rga);
        form.append("tipoId", this.state.tipoId.toString());

        apiAxios.post("/api/Documentacao/enviar/historico", form).then(result => {
            this.getDocumentos();
            this.limparArquivo();
            Swal.close();
        }).catch(ex => {
            Swal.close();
            if (ex.response) {
                const ret = JSON.parse(ex.response.data.mensagem)
                Swal.fire('Erro ao enviar arquivo', ret.message, 'error');
            }
            else {
                Swal.fire('Erro ao enviar arquivo', `${ex}`, 'error');
            }
        });
    }

    DownloadDocumento = async (guid, nome) => {
        apiAxios({
            url: `/api/Documentacao/download/${guid}`,
            method: 'GET',
            responseType: 'blob',
        }).then(response => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nome}`);
            link.click();
        }).catch(ex => {
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        });
    }

    limparAluno = () => {
        const tipos = this.state.tipos;
        this.setState({ aluno: null, rga: "", documentos: [] });
        if (tipos.length > 0) {
            this.setState({ tipoId: tipos[0].documentoTipoId, observacao: tipos[0].nome });
        }
        this.limparArquivo();
    }

    limparArquivo = () => {
        this.setState({ arquivoName: "Selecione o arquivo", arquivo: null });
        document.getElementById("imgupload").value = "";
        const tipos = this.state.tipos;
        if (tipos.length > 0)
            this.setState({ tipoId: tipos[0].documentoTipoId });
    }

    setArquivo = async () => {
        if (this.state.arquivo == null) {
            Swal.fire({
                icon: 'warning',
                title: 'Arquivo não selecionado!'
            });
            return;
        }

        if (!this.state.observacao || this.state.observacao.trim().length == 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Observação não informada, Verifique!'
            });
            return;
        }

        if (this.state.tipoId <= 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Tipo do Arquivo não selecionado!'
            });
            return;
        }

        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Enviando Arquivo',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });

        if (this.CheckTamanho(this.state.arquivo)) {
            let form = new FormData();
            form.append("file", this.state.arquivo);
            form.append("rga", this.state.rga);
            form.append("tipoId", this.state.tipoId.toString());
            form.append("obs", this.state.observacao);

            apiAxios.post("/api/Documentacao/enviar", form).then(result => {
                this.getDocumentos();
                this.limparArquivo();
                Swal.close();
            }).catch(ex => {
                Swal.close();
                if (ex.response) {
                    const ret = JSON.parse(ex.response.data.mensagem)
                    Swal.fire('Erro ao enviar arquivo', ret.message, 'error');
                }
                else {
                    Swal.fire('Erro ao enviar arquivo', `${ex}`, 'error');
                }
            });
        } else {
            Swal.fire('Oops...', "O tamanho do arquivo ultrapassa o limite (7 MB).", 'error');
        }
    }

    getEstudante = async () => {
        this.setState({ aluno: null });

        if (!this.state.rga) {
            Swal.fire({
                icon: 'warning',
                title: 'RGA não informado!'
            });
            return;
        }

        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Carregando registros.',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });

      try {
            const result = await apiAxios.get(`api/Documentacao/estudante/${this.state.rga}`);
            const aluno = result.data;
            if (!aluno) {
                Swal.close()
                Swal.fire({
                    icon: 'warning',
                    title: 'RGA não encontrado!',
                    html: 'Verifique RGA informado',
                });
            } else {
                this.setState({ aluno });
                Swal.close();
                this.getDocumentos();
            }
        } catch (ex) {
            Swal.fire({
                icon: 'error',
                title: 'Erro ao localizar dados!',
                html: 'Tente novamente. Se erro persistir entre em contato com o administrador do sistema.',
            });
            console.log(ex);
        }
    }

    renderDocumentos = () => {
        if (this.state.documentos.length > 0) {
            return (<>
                <br />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            <th>Nome</th>
                            <th>Observações</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.documentos.map((doc, index) => {
                            return <tr key={index}>
                                <td>{doc.tipo}</td>
                                <td>{doc.nome}</td>
                                <td>{doc.observacao}</td>
                                <td>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={() => this.DownloadDocumento(doc.arquivoGuid, doc.nome)}>
                                        <i className="fas fa-file-download"></i>&nbsp;Download
                                    </Button>
                                    <Button
                                        variant="danger"
                                        type="button"
                                        style={{ marginLeft: 15 }}
                                        onClick={() => this.ExcluirDocumento(doc.id, index)}>
                                        <i className="fas fa-trash"></i>&nbsp;Excluir
                                    </Button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </>)
        }

        return <></>
    }

    renderTipos = () => {
      if (this.state.tipos.length > 0) {
            return (
                <div className="row d-flex flex-row">
                    <div className="mb-3 col-auto">
                        <Form.Label>Tipo do Arquivo</Form.Label>
                        <select className="form-select" value={this.state.tipoId} onChange={(e) => {
                            this.setState({ tipoId: e.target.value, observacao: e.target.options[e.target.selectedIndex].text })
                        }}>
                            {this.state.tipos.map(r => <option
                                key={r.documentoTipoId.toString()}
                                value={r.documentoTipoId.toString()}>{r.nome}</option>)}
                        </select>
                    </div>
                    <div className="mb-3 col-auto">
                        <Form.Label><strong>Os arquivos devem estar no formato de preservação PDF/A (www.pdfa.org) e NÃO podem passar de 7MB de tamanho</strong></Form.Label>
                        <input type="file" id="imgupload" accept="application/pdf" style={{ display: 'none' }}
                            onChange={(e) => {
                                if (e.target.files != null && e.target.files.length > 0) {
                                    this.setState({
                                        arquivoName: e.target.files[0].name,
                                        arquivo: e.target.files[0]
                                    })
                                } else {
                                    this.limparArquivo();
                                }
                            }} />
                        <br />
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => { document.getElementById("imgupload").click() }}>
                            {this.state.arquivo == null ? <i className="fas fa-upload"></i> : <i className="fas fa-file"></i>}&nbsp;{this.state.arquivoName}
                        </Button>
                    </div>
                </div>
            )
        }

        return <></>
    }

    render() {
        return (
            <div>
                <Breadcrumb className="arr-right mb-3">
                    <li className="breadcrumb-item">
                        <Link to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Enviar Documentação comprobatória
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h3>Documentação comprobatória</h3>
                            <small>
                                Documentação Acadêmica para Emissão e Registro de Diplomas Digitais
                            </small>
                        </Card.Title>
                        <hr />
                        <Row>
                            <Col sm={12} md={6}>
                                <Alert variant="success">
                                    Obrigatórios<br />
                                    •	Ata de Colação de Grau;<br />
                                    •      RG e CPF;<br />
                                    •	Certificado de E. Médio;<br />
                                    •	Histórico Escolar de E. Superior; <br />
                                    •	Termo de Responsabilidade para Expedição e Registro; <br />
                                </Alert>
                            </Col>
                            <Col sm={12} md={6}>
                                <Alert variant="success">
                                    Opcionais:<br />
                                    •	Certidão de Nasc. Ou Casamento;
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Alert variant="success">
                                    Recomendamos que a conversão sejá feita no formato "PDF/A-2b" no programa PDFCreator
                                </Alert>
                            </Col>
                        </Row>
                        <hr />
                        <Form name="gravarDocumentoForm">
                            {this.state.aluno ?
                                <>
                                    <Form.Row>
                                        <Col sm={12} md={4}>
                                            <div class="mb-3">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control plaintext readOnly defaultValue={this.state.aluno.nome} />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <div class="mb-3">
                                                <Form.Label>RGA</Form.Label>
                                                <Form.Control plaintext readOnly defaultValue={this.state.aluno.matricula} />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <div class="mb-3">
                                                <Form.Label>Curso</Form.Label>
                                                <Form.Control plaintext readOnly defaultValue={this.state.aluno.curso} />
                                            </div>
                                        </Col>
                                    </Form.Row>
                                    <hr />
                                    {this.renderTipos()}
                                    {/* <Form.Row>
                                        <Col sm={12} md={6}>
                                            <div class="mb-3">
                                                <Form.Label>Observação</Form.Label>
                                                <input
                                                    type="text"
                                                    title="Observação"
                                                    value={this.state.observacao}
                                                    onChange={(e) => this.setState({ observacao: e.target.value })}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Form.Row> */}
                                    <div class="d-flex gap-3 mb-3">
                                        <Button
                                            variant="success"
                                            type="button"
                                            onClick={this.setArquivo}>
                                            <i className="fas fa-file-import"></i> Enviar Arquivo
                                        </Button>
                                        {this.state.observacao === "Histórico Escolar do curso superior concluído" ? <Button
                                            variant="primary"
                                            type="button"
                                            style={{ marginLeft: 15 }}
                                            onClick={this.EnviarHistoricoApp}>
                                            <i className="fas fa-file-export"></i> Enviar Histórico Gerado no Aplicativo
                                        </Button> : <></>}                                     
                                        <Button
                                            variant="danger"
                                            type="button"
                                            style={{ marginLeft: 15 }}
                                            onClick={this.limparAluno}>
                                            <i className="fas fa-undo"></i> Voltar
                                        </Button>
                                    </div>
                                    <div className="alert alert-info mb-0">
                                        <i className="fa fa-info-circle me-1"></i>Termo de Registro do Diploma  deve ser enviado com o tipo 'Outros'
                                    </div>
                                    {this.state.isLoadingDocumentosEnviado ? <><br /><br /><Spinner animation="border" variant="primary" /></> : <></>}
                                    {this.renderDocumentos()}
                                </> :
                                <>
                                    <div className="d-flex gap-3">
                                        <div className="mb-3" style={{maxWidth: "230px"} }>
                                            <Form.Label>RGA</Form.Label>
                                            <input
                                                type="text"
                                                title="rga"
                                                value={this.state.rga}
                                                onChange={(e) => this.setState({ rga: e.target.value })}
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Form.Label className="w-100">&nbsp;</Form.Label>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={this.getEstudante}>
                                                <i className="fas fa-search"></i> Localizar Dados
                                            </Button>
                                        </div>
                                    </div>
                                </>}
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default CadastrarDocumento;
import * as React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

const TextField = styled.input`
    height: 32px;
    width: 230px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Tipo ou Número"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            <i className="fas fa-search"></i>
        </ClearButton>
    </>
)

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip-publicar" {...props}>
        {props}
    </Tooltip>
)
const ActionButtonsCancelado = (props) => {
    return (
        <>
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip('Ver')}>
            <Link
                className="btn btn-primary btn-list"
                to={`/autorizacaoCurso/detalhe/${props.atoRegulatorioUid}`}>
                <i className="far fa-file-alt"></i>
            </Link>
        </OverlayTrigger></>
        )
    }
    
const ActionButtons = (props) => {
    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Editar')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/autorizacaoCurso/edita/${props.atoRegulatorioUid}`}>
                    <i className="fas fa-edit"></i>
                </Link>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Vincular Cursos')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/autorizacaoCurso/vincula-cursos/${props.atoRegulatorioUid}`}>
                    <i className="fas fa-file-medical"></i>
                </Link>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Ver')}>
                <Link
                    className="btn btn-primary btn-list"
                    to={`/autorizacaoCurso/detalhe/${props.atoRegulatorioUid}`}>
                    <i className="far fa-file-alt"></i>
                </Link>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Cancelar')}>
                <Link
                    className="btn btn-danger btn-list"
                    to={`/autorizacaoCurso/exclui/${props.atoRegulatorioUid}`}>
                    <i className="fas fa-ban"></i>
                </Link>
            </OverlayTrigger>
        </>
    )
}

export const BasicTable = ({ autorizacoes = [] }) => {
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    )
    const filteredItems = autorizacoes
    .filter((item) =>
        item.tipo.toLowerCase().includes(filterText.toLowerCase())
        || item.numero == filterText
    ).sort().reverse()

    function FormatDate(props) {
        if (props.data != null) {
            let dateTimeArray = props.data.split(' ')
            return (
                <span>
                    {dateTimeArray[0]}
                </span>
            )
        } else {
            return <span></span>
        }
    }
    const columns = [
       /* {
            name: 'ID',
            selector: 'atoRegulatorioUid',
            sortable: true,
            maxWidth: '10%',
        },*/
        {
            name: 'Número',
            selector: 'numero',
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Data',
            cell:(props) =>  (props.data != null && props.data != undefined) ?  <FormatDate
                data={props.data}
            /> : '',
            sortable: true,
            maxWidth: '20%',
        },
        {
            name: 'Tipo',
            selector: 'tipo',
            sortable: true,
            maxWidth: '30%',
        },
        {
            name: 'Cursos Vinculados',
            selector: 'totalCursosVinculados',
            sortable: true,
            maxWidth: '15%',
        },
        {
            cell: (props) =>
                props.cancelado === true ? (
                    <ActionButtonsCancelado atoRegulatorioUid={props.atoRegulatorioUid} />
                ) : (
                    <ActionButtons atoRegulatorioUid={props.atoRegulatorioUid} />
                ),
            ignoreRowClick: true,
            allowOverflow: true,
            maxWidth: '20%', 
        }
    ]
    
const conditionalRowStyles = [
    {
      when: row => row.cancelado,
      style: {
        backgroundColor: "#ffcccb",
      }
    }
  ];
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        )
    }, [filterText, resetPaginationToggle])
    return (
        <DataTable
            title="Atos Regulatórios de Autorização de Curso(s)"
            columns={columns}
            keyField="text"
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="Nenhum Ato Regulatório de Autorização de Curso(s) encontrado."
            paginationComponentOptions={{
                rowsPerPageText: 'Atos Regulatórios de Autorização de Curso(s) por página:',
            }}
            persistTableHead
            Clicked
            conditionalRowStyles={conditionalRowStyles}
        />
    )
}

export default BasicTable

import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import RevogaCancelamentoRegistroForm from '../../components/Area/CAE/RevogaCancelamentoRegistroForm'
import { loadRegistro, loadAnotacoes } from '../../store/thunks'
import { connect } from 'react-redux'
import SituacaoRegistro from './../../components/Area/Publica/SituacaoRegistro'
import { IrPortal } from "../../util/funcoes";
import Swal from 'sweetalert2'
import * as api from './../../api/api'
import { Link } from 'react-router-dom'

class VerCancelamentoRegistro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registroId: this.props.match.params.registroId,
        }

        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }
    componentDidMount() {
        this.props.dispatch(loadRegistro(this.state.registroId))
        this.props.dispatch(loadAnotacoes(this.state.registroId))
    }

    handleRedirect() {
        window.location.href = '/digital/registros'
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link
                            to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/registros">
                            Consulta de Registros
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Detalhes do Cancelamento de Registro
                    </Breadcrumb.Item>
                </Breadcrumb>

                <h3>Detalhes do Cancelamento de Registro</h3>

                <SituacaoRegistro
                    isCancelado={true}
                    anotacoes={this.props.anotacoes}
                />
                <Link className="btn btn-primary" to="/registros">
                    Voltar
                </Link>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    anotacoes: state.registros.anotacoes,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(VerCancelamentoRegistro)

import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import TabelaEstudanteFormando from './../../components/Area/CAE/TabelaEstudanteFormando'
import { connect } from 'react-redux'
import { loadEstudantesFormandos, clearEstudantesFormandos } from './../../store/thunks'
import ConsultaIndividualFormandosFiltroForm from './../../components/Area/CAE/ConsultaIndividualFormandosFiltroForm'
import queryString from 'query-string'
import { IrPortal } from "../../util/funcoes";
import { Link } from 'react-router-dom'

class ConsultaIndividualFormandos extends React.Component {
    static displayName = ConsultaIndividualFormandos.name
    constructor(props) {
        super(props)
        this.state = {
            estudantesFormandos: [],
            dadosBuscaEstudanteFormando: {}
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleLimparBusca = this.handleLimparBusca.bind(this)
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }

    handleLimparBusca() {
        this.props.dispatch(clearEstudantesFormandos())
    }

    handleFormSubmit(dadosBuscaEstudanteFormando) {
            
        this.setState({ dadosBuscaEstudanteFormando: dadosBuscaEstudanteFormando});
        const dto = JSON.stringify(dadosBuscaEstudanteFormando);

        this.props.dispatch(loadEstudantesFormandos(dto))
    }

    componentDidMount() {
        if(this.isEmpty(this.state.dadosBuscaEstudanteFormando)){
            this.props.dispatch(clearEstudantesFormandos())
        }
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return JSON.stringify(obj) === JSON.stringify({})
    }

    render() {
        const { estudantesFormandos } = this.props //error, loading,
        return (
            <>
                <Breadcrumb className="arr-right mb-3">
                    <li className="breadcrumb-item">
                        <Link
                            to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/registros">
                            Consulta de Registros
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/consulta-formandos">
                            Consulta de Estudantes Formandos
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Consulta Individual de Formandos
                    </Breadcrumb.Item>
                </Breadcrumb>
                <ConsultaIndividualFormandosFiltroForm
                    handleFormSubmit={this.handleFormSubmit}
                    handleLimparBusca={this.handleLimparBusca}
                />
                <Card className="mt-3">
                    <Card.Body>
                        <TabelaEstudanteFormando estudantesFormandos={estudantesFormandos} /> 
                    </Card.Body>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    estudantes: state.registros.estudantes,
    estudantesFormandos: state.registros.estudantesFormandos,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(ConsultaIndividualFormandos)
import * as React from 'react'
import ConsultaPublicaFiltro from './ConsultaPublicaFiltro'
import * as api from './../../../api/api'
import TabelaRegistro from './TabelaRegistro'
import { Card, Row, Col, Button } from 'react-bootstrap'
import queryString from 'query-string'
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import axios from "axios";
import apiAxios from '../../../api/apiAxios'

class ConsultaPublica extends React.Component {
    static displayName = ConsultaPublica.name

    constructor(props) {
        super(props)
        this.state = {
            redireciona: false,
            registros: [],
            dataInicio: null,
            dataFim: null,
        }
        this.redireciona = this.redireciona.bind(this)
        this.handleInputChangeDate2 = this.handleInputChangeDate2.bind(this)
        this.verTodos = this.verTodos.bind(this)
    }
    handleInputChangeDate2(date, name) {
        if (date === null) {
            date = new Date()
        }
        this.setState({
            [name]: date,
        })
    }

    verTodos() {
        this.props.history.push(`/ConsultaPublica`)
        this.setState({ redireciona: true })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.redireciona === true) {
            let params = queryString.parse(this.props.location.search)
            const dataInicio = params.dataInicio
            const dataFim = params.dataFim

            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Carregando registros.',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            })
            if (dataInicio != null && dataFim != null) {
                api.fetchRegistrosPorPeriodo(dataInicio, dataFim).then(
                    (resp) => {
                        if (resp === null || resp === '') {
                            //Swal.fire('Oops', `Inscrição não encontrada.`, 'error')
                            console.log('erro11')
                        } else {
                            // list of nested object keys
                            this.setState({
                                registros: resp,
                                dataInicio: dataInicio,
                                dataFim: dataFim,
                            })
                        }
                        this.setState({ redireciona: false })
                        Swal.close()
                    }
                )
            } else {
                api.fetchRegistrosDiplomaDigitalEmitido().then((resp) => {
                    if (resp === null || resp === '') {
                        //Swal.fire('Oops', `Inscrição não encontrada.`, 'error')
                        console.log('erro1')
                    } else {
                        // list of nested object keys
                        this.setState({
                            registros: resp,
                            dataInicio: null,
                            dataFim: null,
                        })
                    }
                    this.setState({ redireciona: false })
                    Swal.close()
                })
            }
        }
    }
    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        const dataInicio = params.dataInicio
        const dataFim = params.dataFim

        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Carregando registros.',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        if (dataInicio != null && dataFim != null) {
            api.fetchRegistrosPorPeriodo(dataInicio, dataFim).then((resp) => {
                if (resp === null || resp === '') {
                    //Swal.fire('Oops', `Inscrição não encontrada.`, 'error')
                    console.log('Resposta vazia')
                } else {
                    // list of nested object keys
                    this.setState({
                        registros: resp,
                        dataInicio: dataInicio,
                        dataFim: dataFim,
                    })
                }
                Swal.close()
            })
        } else {
            api.fetchRegistrosDiplomaDigitalEmitido().then((resp) => {
            //api.fetchRegistros().then((resp) => {
                if (resp === null || resp === '') {
                    console.log('Resposta vazia')
                } else {
                    // list of nested object keys
                    this.setState({
                        registros: resp,
                        dataInicio: null,
                        dataFim: null,
                    })
                }
                Swal.close()
            })
        }
    }

    redireciona(dataInicio, dataFim) {
        const dataInicioFormatada = format(dataInicio, 'yyyy-MM-dd')
        const dataFimFormatada = format(dataFim, 'yyyy-MM-dd')
        this.props.history.push(
            `/ConsultaPublica?dataInicio=${dataInicioFormatada}&dataFim=${dataFimFormatada}`
        )
        this.setState({ redireciona: true })
    }

    exportarConsultaExterna = () => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados dos registros...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        try {
            let params = queryString.parse(this.props.location.search)
            const dataInicio = params.dataInicio
            const dataFim = params.dataFim

            let buscaDto = {
                DataInicio: dataInicio,
                DataFim: dataFim
            }
          console.log(buscaDto);
          apiAxios.post("/api/registro/exportar-consulta-publica", JSON.stringify(buscaDto), {
                headers: { 'content-type': 'application/json' },
                responseType: 'blob',
            }).then(response => {
                Swal.close();
                    console.log(response)
                    let fileNameServidor;
                    try {
                        fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
                    } catch (ex) {
                        console.log(ex);
                        fileNameServidor = undefined;
                    }
                    const fileName = (fileNameServidor == undefined ? "consulta-publica.pdf" : fileNameServidor[0]);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    link.click();
            }).catch(ex => {
                Swal.close();
                Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
            });
        } catch (ex) {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        }
    }

    render() {
        let params = queryString.parse(this.props.location.search)
        const dataInicio = params.dataInicio
        const dataFim = params.dataFim
        return (
            <>
                <h2 className="card-title mb-2">
                    Consulta Pública de Registro de Diplomas
                </h2>

                <Card.Title>
                    <h6 className="card-subtitle text-muted">
                        Consulta registros de diploma por período, nome ou
                        CPF.
                    </h6>
                </Card.Title>
                <ConsultaPublicaFiltro
                    dataInicio={this.state.dataInicio}
                    dataFim={this.state.dataFim}
                    redireciona={this.redireciona}
                    verTodos={this.verTodos}
                    handleInputChangeDate2={this.handleInputChangeDate2}
                />

                <Card className="mt-3">
                    <Card.Body>
                        <TabelaRegistro registros={this.state.registros} />
                        <a
                            className="btn btn-primary"
                            href={`https://diplomas.ufmt.br/Registro/Consultar`}
                        >
                            Consulta Pública Diplomas Físicos
                        </a>
                        {
                            (dataInicio != null && dataFim != null)
                                ?
                                <>
                                    <hr></hr>
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                style={{ marginLeft: 15 }}
                                                onClick={() => { this.exportarConsultaExterna() }}>
                                                <i className="fas fa-file-download"></i> Exportar Consulta Detalhada
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                                : null
                        }

                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default ConsultaPublica

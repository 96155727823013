import * as React from 'react'
import { Card, Row, Col, Button, ListGroup } from 'react-bootstrap'

export class AcoesRegistro extends React.Component {
    render() {
        return (
            <Card id="acoesRegistro">
                <Card.Body>
                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Validar XML assinado do diploma digital:</div>
                            <div className='fonte-cinza'>                            
                            <Button
                                className="btn btn-info btn-sm"
                                href="https://validadordiplomadigital.mec.gov.br/diploma"
                            >
                                <i className="fas fa-fingerprint"></i> Validar
                            </Button>
                            </div>
                        </div>
                    </ListGroup.Item>
                    </ListGroup>                     
                </Card.Body>
            </Card>
        )
    }
}

export default AcoesRegistro

import * as React from 'react'
import { Card, Breadcrumb } from 'react-bootstrap'
import RevogaCancelamentoRegistroForm from '../../components/Area/CAE/RevogaCancelamentoRegistroForm'
import { loadRegistro, loadAnotacoes } from '../../store/thunks'
import { connect } from 'react-redux'
import SituacaoRegistro from './../../components/Area/Publica/SituacaoRegistro'
import { IrPortal } from "../../util/funcoes";
import Swal from 'sweetalert2'
import * as api from './../../api/api'
import { Link } from 'react-router-dom'

class RevogaCancelamentoRegistro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registroId: this.props.match.params.registroId,
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount() {
        this.props.dispatch(loadRegistro(this.state.registroId))
        this.props.dispatch(loadAnotacoes(this.state.registroId))
    }

    handleRedirect(){
        window.location.href = '/digital/registros'
    }    
    
    handleSubmit (registroId,
        justificativa,
        responsavel,
        responsavelDocumento) {
        try {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Revogando registro cancelado...',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            const dadosRevogar = JSON.stringify({
                registroId: Number(registroId),
                justificativa,
                responsavel,
                responsavelDocumento,
            })
            api.revogarCancelamentoRegistro(dadosRevogar, registroId).then((resp) => {
                console.log(resp)
                if (resp.status !== 200) {              
                    Swal.close()      
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao revogar registro!',
                        html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
                    })
                } else {
                    Swal.close()            
                    Swal.fire({
                    icon: 'success',
                    title: 'Registro revogado com sucesso! Redirecionando...',
                    showConfirmButton: false,
                    timer: 10000,
                    }).then(this.handleRedirect())             
                }
            })
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao revogar registro!',
                html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Breadcrumb className="arr-right">
                            <Breadcrumb.Item>                            
                                <Link
                                    to="/cae">
                                    Página Inicial - CAE
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>                            
                                <Link
                                    to="/registros">
                                    Consulta de Registros
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Revoga Cancelamento de Registro
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                    <SituacaoRegistro
                        isCancelado={true}
                        anotacoes={this.props.anotacoes}
                    />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Revoga Cancelamento de Registro</h2>
                            <small>
                                Preencha as informações abaixo para continuar
                                com a revogação do cancelamento do registro de
                                diploma.
                            </small>
                        </Card.Title>
                        <br></br>
                        <RevogaCancelamentoRegistroForm
                            registroId={this.state.registroId}
                            estudante={this.props.registro.diplomado.nome}
                            dataRegistro={
                                this.props.registro.livroRegistro
                                    .dataRegistroDiploma
                            }
                            responsavelRegistro={
                                this.props.registro.livroRegistro
                                    .responsavelRegistro
                            }
                            handleSubmit={this.handleSubmit}
                            anotacoes={this.props.anotacoes}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    anotacoes: state.registros.anotacoes,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(RevogaCancelamentoRegistro)

import * as React from 'react'
import { Card, Row, Col,ListGroup } from 'react-bootstrap'

export class DiplomadoDados extends React.Component {
    render() {
        function Naturalidade(props) {
            if (props.naturalidade != null) {
                return (
                    <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start list-group-item-no-border"
                >
                    <div className="ms-2 me-auto" >
                    <div className="fw-bold">Naturalidade:</div>
                        <div className='fonte-cinza'>
                        {props.naturalidade.codigoMunicipio} -
                        {props.naturalidade.nomeMunicipio}/
                        {props.naturalidade.uf}
                        </div>
                    </div>
                </ListGroup.Item>
                )
            } else {
                return null
            }
        }

        function DiplomadoDadosReduzidos(props) {
            return (
                <>
                <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                <div className="fw-bold">Diplomado:</div>
                    <div className='fonte-cinza'>
                    {props.diplomado.nome}
                    </div>
                </div>
            </ListGroup.Item>
                <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start list-group-item-no-border"
            >
                <div className="ms-2 me-auto" >
                <div className="fw-bold">CPF:</div>
                    <div className='fonte-cinza'>
                    ***{props.diplomado.cpf?.substring(3,6)}{props.diplomado.cpf?.substring(6,9)}**
                    </div>
                </div>
            </ListGroup.Item>
                </>
            )
        }
        function DiplomadoDadosCompleto(props) {
            if (!props.mostrarReduzido) {
                return (
                    <>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Matricula (RGA):</div>
                            <div className='fonte-cinza'>
                            {props.diplomado.id}
                            </div>
                        </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Sexo:</div>
                            <div className='fonte-cinza'>
                            {props.diplomado.sexo === 'F' ? (
                                   <div> Feminino </div>
                            ) : (
                                <div> Masculino </div>
                            )}
                            </div>
                        </div>
                    </ListGroup.Item> */}
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Nacionalidade:</div>
                            <div className='fonte-cinza'>
                            {props.diplomado.nacionalidade}
                            </div>
                        </div>
                    </ListGroup.Item>
                    {/* <Naturalidade naturalidade={props.naturalidade} /> */}
                    {/* <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">RG:</div>
                            <div className='fonte-cinza'>
                            {props.rg.numero} - {props.rg.uf}
                            </div>
                        </div>
                    </ListGroup.Item> */}
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start list-group-item-no-border"
                    >
                        <div className="ms-2 me-auto" >
                        <div className="fw-bold">Data de Nascimento:</div>
                            <div className='fonte-cinza'>
                            <FormatDate
                                    data={props.diplomado.dataNascimento}
                                />
                            </div>
                        </div>
                    </ListGroup.Item>
                    </>
                )
            } else {
                return null
            }
        }

        function FormatDate(props) {
            if (props.data != null) {
                let dataArray = props.data.split('-')
                return (
                    <span>
                        {dataArray[2]}/{dataArray[1]}/{dataArray[0]}
                    </span>
                )
            } else {
                return <span></span>
            }
        }
        return (
            <Card id="diplomado">
                <Card.Body>
                    <Card.Title>
                        <h3>Diplomado</h3>
                    </Card.Title>
                    <ListGroup as="ol" style={{fontSize:"16px"}}>
                    <DiplomadoDadosReduzidos
                        diplomado={this.props.diplomado}
                        rg={this.props.rg}
                        naturalidade={this.props.naturalidade}
                    />
                    <DiplomadoDadosCompleto
                        mostrarReduzido={this.props.mostrarReduzido}
                        diplomado={this.props.diplomado}
                        rg={this.props.rg}
                        naturalidade={this.props.naturalidade}
                    />
                    </ListGroup>  
                </Card.Body>  
            </Card>
        )
    }
}

export default DiplomadoDados

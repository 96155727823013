import * as React from 'react'
import { Link } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Button, Row, Col, Form } from 'react-bootstrap'
import ptBR from 'date-fns/locale/pt-BR';
registerLocale("ptBR", ptBR);

class ConsultaPublicaFiltro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataInicio:
                this.props.dataInicio === null ? null : this.props.dataInicio,
            dataFim: null,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeDate = this.handleInputChangeDate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.verTodos = this.verTodos.bind(this)
    }

    /*componentWillReceiveProps(props) {
        if (this.state.dataInicio == null && props.dataInicio != null) {
            this.setState({ dataInicio: props.dataInicio })
        } else {
            if (this.state.dataInicio != null) {
                this.setState({ dataInicio: this.state.dataInicio })
            }
        }
        if (this.state.dataFim == null && props.dataFim != null) {
            this.setState({ dataFim: props.dataFim })
        } else {
            if (this.state.dataFim != null) {
                this.setState({ dataFim: this.state.dataFim })
            }
        }
    }*/

    handleInputChangeDate(date, name) {
        if (date === null) {
            date = new Date()
        }
        this.setState({
            [name]: date,
        })
        this.props.handleInputChangeDate2(date, name)
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleSubmit(event) {
        if (this.state.dataInicio != null && this.state.dataFim != null) {
            this.props.redireciona(this.state.dataInicio, this.state.dataFim)
        }
        event.preventDefault()
    }

    verTodos() {
        this.setState({ dataInicio: null, dataFim: null })
        this.props.verTodos()
    }

    render() {
        return (
            <div className="card filterArea">
                <div className="card-body">
                    <form
                        id="filterForm"
                        name="filterForm"
                        onSubmit={this.handleSubmit}
                    >
                        <Row>
                            <div className="col-auto">
                                <Form.Label>Data Inicial:</Form.Label>
                                <DatePicker
                                    locale="ptBR"
                                    selected={this.state.dataInicio}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => this.handleInputChangeDate(date, 'dataInicio')}
                                    className="form-control" />
                            </div>

                            <div className="col-auto">
                                <Form.Label>Data Final:</Form.Label>
                                <DatePicker
                                    locale="ptBR"
                                    selected={this.state.dataFim}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => this.handleInputChangeDate(date, 'dataFim')}
                                    className="form-control" />
                            </div>

                            <div className="col-auto">
                                <Form.Label className="w-100">&nbsp;</Form.Label>

                                <Button
                                    className="btn btn-primary btn-ver-todos-consulta-publica"
                                    onClick={this.verTodos}
                                >
                                    <i className="far fa-list-alt"></i> Ver Todos
                                </Button>
                            </div>
                            <div className="col-auto">
                                <Form.Label className="w-100">&nbsp;</Form.Label>

                                <Button
                                    type="submit"
                                    className="btn btn-primary btn-consultar-consulta-publica"
                                >
                                    <i className="fas fa-search"></i> Consultar
                                </Button>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        )
    }
}

export default ConsultaPublicaFiltro

import * as React from 'react'
import { Row, Col, Alert, Card, Breadcrumb } from 'react-bootstrap'
import RegistroNovoForm from '../../components/Area/CAE/RegistroForm/RegistroNovoForm'
import { connect } from 'react-redux'
import { IrPortal } from "../../util/funcoes";
import { Link } from 'react-router-dom'

class RegistroNovo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rga: this.props.match.params.rga,
            iesEmissoraInstituicaoID: 1, //TODO: UFMT
            iesRegistradoraInstituicaoID: 1, //TODO: UFMT
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }
    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link
                            to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/registros">
                            Consulta de Registros
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/consulta-formandos">
                            Consulta de Estudantes Formandos
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Novo Registro
                    </Breadcrumb.Item>
                </Breadcrumb>

                <h2>Novo Registro</h2>
                <Alert variant="info" style={{ borderLeft: "4px solid" }} className="bg-white">
                    <i className="fas fa-exclamation-triangle me-2"></i>Não incluir "zeros não significativos" para
                    campos numéricos.
                </Alert>
                <RegistroNovoForm
                    rga={this.state.rga}
                    iesEmissoraId={this.state.iesEmissoraInstituicaoID}
                    iesRegistradoraId={this.state.iesRegistradoraInstituicaoID}
                />
            </>
        )
    }
}

export default connect((state) => ({
    ufs: state.registros.ufs,
    municipios: state.registros.municipios,
    estudante: state.registros.estudante,
    loading: state.registros.loading,
    error: state.registros.error,
    curso: state.cursos.curso,
    graus: state.cursos.graus,
    cursoFormacoes: state.cursos.cursoFormacoes,
    cursoTitulacoes: state.cursos.cursoTitulacoes,
    cursoReconhecimentos: state.cursos.cursoReconhecimentos,
    iesEmissora: state.ies.iesEmissora,
    iesRegistradora: state.ies.iesRegistradora,
    livros: state.livros.livros,
    enade: state.registros.enade,
}))(RegistroNovo)